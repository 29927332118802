.icon1-grid.fort-collis-icon-section {
  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}


.downtimebanner {
  display: inline-block;        /* or block, depending on your layout needs */
  padding: 8px 12px;            /* add some space around the text */
  background-color: #6b0000;    /* example background color */
  color: #a94442;               /* example text color (contrasts the background) */
  font-weight: bold;            /* makes the text stand out */
  text-decoration: none;        /* remove underline if the span is acting like a link */
  border: 1px solid #ebcccc;    /* subtle border, if desired */
  border-radius: 4px;           /* slightly rounded corners */
  text-transform: uppercase;    /* optional: make the text all uppercase */
  text-align: center;           /* centers the text within the span */
}
