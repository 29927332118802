
@font-face {
  font-family: "Source Sans Pro";
  src: url(${PUBLIC_URL}/static/media/SourceSansPro-Regular.c1678b46.ttf) format("opentype");
}
@font-face {
  font-family: "Source Sans 3";
  src: url(${PUBLIC_URL}/static/media/SourceSans3-VariableFont_wght.ede5b94a.ttf) format("opentype");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
  -webkit-scroll-behaviour: smooth;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Source Sans 3";
  font-size: 1rem;
  font-weight: 400;
  line-height: 2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3c3835 !important;
  width: 100%;
  overflow-x: hidden;
}
body.overflowHidden {
  overflow-y: hidden;
}

html,
body {
  height: 100%;
}

.Login--Buttons {
  width: 400px;
  align-content: flex-start;
  justify-content: flex-start;
}
@media screen and (max-width: 750px) {
  .Login--Buttons {
    width: 100%;
  }
}

.Profile--create-account-button {
  width: 100%;
  margin: 20px auto;
}
@media screen and (max-width: 750px) {
  .Profile--create-account-button {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .Login--Buttons button {
    width: 100%;
  }
}

.Login--remember-me-row {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  font-weight: bold;
}
.Login--remember-me-row a {
  text-decoration: none;
  color: #302d2b;
}
.Login--remember-me-row a:hover {
  color: #fab500;
}
.Login--remember-me-row p {
  text-decoration: none;
  color: #302d2b;
  cursor: pointer;
}
.Login--remember-me-row p:hover {
  color: #fab500;
}

.SignUp--top {
  width: 100%;
  padding: 20px;
}
@media screen and (min-width: 1200px) {
  .SignUp--top {
    width: 1100px;
    margin: 0 auto;
  }
}

.Greyout {
  opacity: 0.5;
  pointer-events: none;
  background-color: #f0f0f0;
}

a.button,
button.button,
.button-default button,
*[class*=button] {
  font-family: Source Sans 3 !important;
}
.page-layout-line {
  height: 85px;
}
@media screen and (max-width: 1199px) {
  .page-layout-line {
    height: 96px;
  }
}

.DayPicker_transitionContainer__vertical {
  top: 100px;
}

.DateRangePicker_closeButton {
  top: 100px !important;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 40px 0 20px 0;
  font-family: "Source Sans 3", sans-serif !important;
}

.stores-icons-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
@media screen and (max-width: 750px) {
  .stores-icons-container {
    justify-content: center;
  }
}

.stores-icons-container img:first-child {
  margin-right: 16px;
}

.logo-links {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-around;
  padding-left: 40px;
  padding-right: 40px;
}

.footerLogo {
  width: 180px;
  height: 60px;
  border: 0px solid #fff;
}

.links {
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
}
@media screen and (max-width: 750px) {
  .links {
    align-self: center;
    justify-content: center;
  }
}

.links a {
  font-family: "Source Sans 3", sans-serif;
  color: white;
  padding: 10px 0px 4px 0px;
  margin: 0px 20px;
  text-decoration: none;
  font-size: 16px;
  border-bottom: 6px solid #3c3835;
}
.links a:hover {
  border-bottom: 6px double #fcb417;
}

.footerLogoColumn {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
@media screen and (max-width: 750px) {
  .footerLogoColumn {
    padding-bottom: 20px;
  }
}
.footerLogoColumn img {
  height: 52px;
  max-width: 170px;
}

.footer-line {
  width: 100%;
  height: 10px;
  border-bottom: 1px solid #fab500;
  position: relative;
  margin-bottom: 20px;
}

.phone {
  color: #fafafa;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  border: 0px solid #fff;
}

.phoneplus {
  color: #fafafa;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  border: 0px solid #fff;
}

.copyright {
  color: #fafafa;
  text-align: center;
  padding-top: 20px;
  line-height: 25px;
  padding-bottom: 20px;
}

.privacy {
  color: #fafafa;
  text-align: center;
}
.privacy a {
  color: #fab500;
  text-decoration: none;
}

.footer-links-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 1100px;
}
@media screen and (max-width: 1200px) {
  .footer-links-wrapper {
    width: 100%;
    padding: 0 30px;
  }
}
@media screen and (max-width: 750px) {
  .footer-links-wrapper {
    flex-direction: column;
  }
}

.city-links {
  text-align: center;
  margin: 15px 15px 0 15px;
  color: #fafafa;
}

.footer-city-link {
  text-decoration: none;
  color: #fab500;
}
.top-menu-logo-wrapper {
  height: 24px;
  z-index: 1;
}
.top-menu-logo-wrapper img {
  max-width: 140px;
  height: 24px;
}
.social-container {
  display: inline-block;
  vertical-align: top;
  width: 144px;
  height: 38px;
}

.social-icon {
  display: inline-block;
  height: auto;
  padding: 10px;
}
.tabs-container {
  display: flex;
  background-color: #000;
  height: 64px;
  padding: 0 calc((100% - 1100px) / 2);
}
.tabs-container .tab-wrapper {
  position: relative;
  display: flex;
  flex: 1 1;
  background-color: #000;
  justify-content: center;
  align-items: center;
}
.tabs-container .tab-wrapper.first {
  border-top-left-radius: 6px;
}
.tabs-container .tab-wrapper.last {
  border-top-right-radius: 6px;
}
@media screen and (max-width: 1199px) {
  .tabs-container .tab-wrapper.first {
    border-top-left-radius: 0px;
  }
  .tabs-container .tab-wrapper.last {
    border-top-right-radius: 0px;
  }
}
.tabs-container .tab-wrapper a {
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
  text-decoration: none;
}
.tabs-container .tab-wrapper .link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  font-family: "Source Sans 3", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 23px;
}
.tabs-container .tab-wrapper .link.active {
  color: #fab500;
}
.tabs-container .tab-wrapper .link.active .hoverBox {
  display: block;
}
.tabs-container .tab-wrapper .link .hoverBox {
  display: none;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 5px;
  width: 70%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #fab500;
}
.tabs-container .tab-wrapper:hover a {
  color: #fab500;
}
.tabs-container .tab-wrapper:hover .hoverBox {
  display: block;
}
.tabs-container .separator-wrapper {
  display: flex;
  align-self: center;
  height: 30%;
  width: 2px;
  background-color: #000;
}
.tabs-container .separator-wrapper .separator-line {
  align-self: center;
}
.top-menu-wrapper {
  position: fixed;
  display: flex;
  justify-content: space-between;
  background-color: #000;
  flex-direction: row;
  align-content: center;
  align-items: center;
  z-index: 1001;
  width: 100%;
  height: 85px;
}
.top-menu-wrapper .book-now-button {
  font-family: "Source Sans 3", sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  color: #3c3835;
  border-radius: 100px;
  background-color: #fab500;
  margin-right: 3px;
  cursor: pointer;
  text-decoration: none;
  width: auto;
  padding: 6px 10px;
  height: 30px;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.top-menu-wrapper .book-now-button:hover {
  background-color: #ff9800;
}
@media screen and (max-width: 1199px) {
  .top-menu-wrapper {
    height: 96px;
  }
  .top-menu-wrapper .book-now-button {
    display: none;
  }
}
.top-menu-wrapper .logo-links-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 1063px;
  height: 52px;
}
@media screen and (max-width: 1199px) {
  .top-menu-wrapper .logo-links-wrapper {
    width: 100%;
    margin: 0 27px;
  }
}

.top-menu-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.logo-points-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 0;
}
@media screen and (min-width: 1200px) {
  .logo-points-wrapper {
    flex-direction: column;
  }
}
.logo-points-wrapper .loyalty-points {
  padding: 0;
  padding-left: 10px;
  align-items: center;
}

.loyalty-points {
  display: flex;
  justify-content: center;
}
.loyalty-points .currency-style {
  display: none;
}
.loyalty-points .defaultPrice {
  color: #cdccc8;
  font-size: 14px;
}
@media screen and (max-width: 1999px) {
  .loyalty-points {
    color: #ffffff;
  }
}
@media screen and (min-width: 1200px) {
  .top-menu-hamburger-wrapper {
    display: none;
  }
}
.top-menu-hamburger-wrapper .book-now-button {
  font-weight: 600;
  color: #000 !important;
  padding: 0px 40px !important;
  background-color: #fab500;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  width: 150px;
  height: 32px;
  margin: 30px auto 0px auto !important;
  border-radius: 2px;
  font-size: 16px;
  justify-content: center;
  align-items: center;
}

.top-menu-hamburger-icon {
  width: 18px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1200px) {
  .top-menu-hamburger-icon {
    display: none;
  }
}

.hamburger-links {
  left: 0;
  top: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: #000;
  font-family: "Source Sans 3";
}
.hamburger-links .top-menu-hamburger-link a {
  display: flex;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  margin-left: 30px;
  margin-right: 30px;
  padding: 10px 0px;
  justify-content: flex-start;
  align-content: flex-start;
  align-self: flex-start;
}
.hamburger-links .top-menu-hamburger-link a:hover {
  color: white;
}
.hamburger-links .Separator--menu {
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid #493400;
}
.hamburger-links .close-button {
  cursor: pointer;
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 50px;
  right: 0;
  left: 0;
  padding-top: 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-wrapper {
  margin: 15px 0 15px 0;
  height: 55px;
}
.logo-wrapper img {
  display: block;
  margin: auto;
  height: 52px;
}
.logo-wrapper .logo {
  margin-top: 10px;
}

.hamburger-icon {
  width: 20px !important;
}
.top-menu-links-wrapper {
  font-family: "Source Sans 3";
}
.top-menu-links-wrapper a {
  text-decoration: none;
  font-weight: 600;
  color: #ffffff;
  padding: 4px 0px;
  margin: 0px 15px;
}
.top-menu-links-wrapper a:hover {
  border-bottom: 2px solid #fcb417;
  color: #dcdcdc;
}
@media screen and (max-width: 1199px) {
  .top-menu-links-wrapper {
    display: none;
  }
}
.top-menu-profile-wrapper {
  color: #fff;
  position: relative;
  font-size: 20px;
  line-height: 20px;
}
@media screen and (max-width: 1200px) {
  .top-menu-profile-wrapper {
    position: initial;
  }
}

.top-menu-profile-content {
  z-index: 100;
  padding: 20px;
  background: #3c3835;
  position: absolute;
  right: 0;
  width: 414px;
  top: 62px;
}
.top-menu-profile-content .ticket-price-container .defaultPrice {
  font-size: 16px;
  color: #fff;
}
@media screen and (max-width: 414px) {
  .top-menu-profile-content {
    width: 100vw;
    right: 0;
  }
}

.top-menu-profile-name {
  color: #fab500;
}

.top-menu-profile-navlinks {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 23px;
  height: 50px;
  color: #fab500;
  text-align: left;
  border-top: 1px solid #fab500;
}

.top-menu-profile-navlinks:hover {
  font-weight: bold;
}

.top-menu-profile-navlinks-wrapper .top-menu-profile-navlinks:last-child {
  border-bottom: 1px solid #fab500;
}

.top-menu-profile-name-wrapper {
  height: 40px;
  display: flex;
}

.top-menu-profile-logout button {
  text-align: left;
}
.top-menu-profile-logout button svg {
  fill: #fff;
  top: 22px;
  right: 10px;
}
.top-menu-profile-logout button:hover svg {
  fill: #ff4040;
}

.top-menu-icons {
  cursor: pointer;
}

.top-menu-profile-icon-wrapper {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1200px) {
  .top-menu-profile-icon-wrapper {
    margin-left: 10px;
  }
}
.top-menu-profile-loyalty-wrapper .pagetitle {
  border-color: #fff;
  font-weight: normal;
  font-size: 18px;
}

.top-menu-profile-loyalty-wrapper .secondary button {
  text-align: left;
  padding: 0 20px;
}
.top-menu-profile-loyalty-wrapper .secondary button svg {
  right: 13px;
}
.top-menu-profile-loyalty-data-wrapper {
  display: flex;
  padding: 10px;
}

.top-menu-profile-loyalty-item {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.top-menu-profile-loyalty-title {
  color: #696d6f;
  font-size: 12px;
  line-height: 15px;
}

.top-menu-profile-loyalty-content {
  font-size: 16px;
  line-height: 24px;
}
.cancelTrip-container {
  background-color: #f5f5f5;
}

.cancel-trip-wrapper {
  padding: 20px;
}
.leg-payment-wrapper {
  margin: 0px -20px;
  padding: 0px 20px;
}
.leg-payment-wrapper .connection-time-container {
  justify-content: flex-end !important;
}
.leg-payment-wrapper .title-wrapper {
  justify-content: flex-end !important;
}
.leg-payment-wrapper .title-wrapper .title {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: -25px;
}

.leg-headline {
  padding-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.leg-wrapper-black-car {
  background-color: #e5e5e5;
}

.leg-headline-black-car {
  padding-top: 20px;
}

.leg-wrapper-return-black-car {
  background-color: #e5e5e5;
  margin: 0px -20px;
  padding: 0px 20px;
}
.leg-wrapper-return-black-car .title-wrapper {
  justify-content: flex-end;
}
.leg-wrapper-return-black-car .title-wrapper .title {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: -25px;
}

.trip-summary-container .leg-return {
  margin-top: 20px;
}
.payment-prices-wrapper .pagetitle {
  font-weight: 400;
  font-size: 22px !important;
  line-height: 16px !important;
  border-bottom: 0;
  margin: 0;
}

.payment-prices-row {
  display: flex;
  justify-content: space-between;
}
.payment-prices-row .ticket-price-container .currency-style,
.payment-prices-row .ticket-price-container .defaultPrice {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}

.payment-price-section {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}
.tax-container {
  border-bottom: 1px solid #cccccc !important;
}
.tax-container .tax-container__label {
  font-weight: 400;
}

.tax-container .currency-style,
.tax-container .defaultPrice {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 30px !important;
}

.fee-container {
  margin-top: 10px;
}
.fee-container .fee-container__label,
.fee-container .currency-style,
.fee-container .defaultPrice {
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 16px !important;
}

.total-price-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  border-top: 1px solid #cccccc;
  padding-top: 10px;
}
.total-price-container .total-price-container__label,
.total-price-container .currency-style,
.total-price-container .defaultPrice {
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 16px !important;
}

.totalprice-price-container {
  text-align: right;
}
.totalprice-price-container .loyalty-style {
  display: flex;
  align-items: flex-end;
  font-size: 12px !important;
}

.totalprice-points-balance {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: flex-start;
}
.totalprice-points-balance .loyalty-style {
  display: flex;
  align-items: flex-end;
  padding-top: 5px;
  font-size: 12px !important;
}
.totalprice-points-balance .defaultPrice {
  font-size: 24px;
}

.total-price-balance-points {
  padding: 10px 0;
  font-weight: normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.totalprice-promocode-container {
  text-align: right;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}

.totalprice-total-price-container {
  margin-bottom: 20px;
}

.totalprice-total-container {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalprice-fees-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.totalprice-fees-container .defaultPrice {
  font-size: 24px;
}

.totalprice-taxes-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #3c3835;
}
.totalprice-taxes-container .loyalty-style {
  display: flex;
  align-items: flex-end;
  font-size: 12px !important;
  padding-top: 1px;
  font-weight: 500 !important;
}
.totalprice-taxes-container .defaultPrice {
  font-size: 24px;
}

.totalprice-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0px;
}

.totalprice-voucher-code-container {
  padding: 10px 0px 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalprice-total-name {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}

.totalprice-description {
  font-size: 12px;
  line-height: 18px;
}

.totalprice-total-payment-container {
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.totalprice-total-payment-container .loyalty-style {
  display: flex;
  align-items: flex-end;
  font-size: 12px !important;
}
.totalprice-total-payment-container .defaultPrice {
  font-size: 24px;
}

.totalprice-total-payment {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.payment-price-section .loyalty-style {
  display: flex;
  align-items: flex-end;
  font-size: 12px !important;
  padding-top: 2px;
  padding-left: 1px !important;
  font-weight: 500 !important;
}
.alert-container {
  padding: 10px;
  font-size: 16px;
  border-radius: 6px;
  text-align: center;
  font-weight: 400;
}
.alert-container.red-alert {
  border: 1px solid #ff4040;
  color: #ff4040;
}
.alert-container.orange-alert {
  border: 1px solid #ff9800;
  color: #ff9800;
}
.alert-container.green-alert {
  border: 1px solid #00c08b;
  color: #00c08b;
}

.CheckInPage-wrapper {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
}

.Date-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  padding-top: 10px;
}

.Date-wrapper div:last-child {
  color: #00c08b;
}

.trip-leg {
  padding-top: 10px;
}

.trip-check-button button {
  margin-top: 20px;
  margin-left: 10px;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 414px) {
  .trip-check-button button {
    min-width: 100px;
    text-align: center;
  }
}
.top-info {
  align-content: flex-start;
  position: relative;
}

.checkin-buttons {
  width: 50%;
  margin: 40px auto;
}
@media screen and (max-width: 750px) {
  .checkin-buttons {
    width: 100%;
  }
}

.separator-segment {
  width: 100%;
  height: 10px;
  border-bottom: 1px solid #999;
  opacity: 50%;
  position: relative;
  margin-bottom: 20px;
}

.legTitle {
  font-weight: 600;
  font-size: 18px;
  padding-top: 10px;
}
.Home--hero {
  transition: all 0.5ms ease-in-out;
  max-height: 100vw;
}
.Home--hero.no-carousel {
  background-color: #fab500;
  background-image: url(${PUBLIC_URL}/static/media/heroNew.034637f1.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: calc(100vh - 240px);
  padding: 20px;
}
@media screen and (max-width: 1180px) {
  .Home--hero {
    position: absolute;
    top: 240px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1180px) {
  .Home--hero {
    top: 146px;
  }
}
@media screen and (min-width: 700px) and (max-width: 768px) {
  .Home--hero {
    top: 100px;
  }
}
@media screen and (min-width: 520px) and (max-width: 699px) {
  .Home--hero {
    top: 200px;
  }
}
@media screen and (min-width: 414px) and (max-width: 520px) {
  .Home--hero {
    top: 250px;
  }
}
@media screen and (min-width: 300px) and (max-width: 414px) {
  .Home--hero {
    top: 260px;
  }
}
@media screen and (max-width: 1100px) {
  .Home--hero {
    margin-top: 0;
  }
}
.Home--hero .Home--hero-caption {
  font-size: 5vmin;
  margin-top: 70px;
  font-weight: bold;
  line-height: 1.2em;
  padding: 20px;
  color: #fff;
}
@media screen and (min-width: 1200px) {
  .Home--hero .Home--hero-caption {
    padding: 20px calc((100% - 1100px) / 2);
  }
}
@media screen and (max-width: 1100px) {
  .Home--hero .Home--hero-caption {
    margin-top: 0;
  }
}

.Home--first-trip {
  padding: 20px;
}
@media screen and (min-width: 1200px) {
  .Home--first-trip {
    width: 1100px;
    margin: 0 auto;
  }
}

.Home-booking-form {
  background-color: #fafafa;
  border-radius: 6px;
  display: grid;
  grid-template-areas: "departure-arrival-wrapper" "departure-return-dates-wrapper" "passenger" "button";
  padding: 20px;
}

.TabletDesktop--Home-booking-form {
  background-color: #fafafa;
  border-radius: 6px;
  display: grid;
  padding: 20px;
}
@media screen and (min-width: 670px) {
  .TabletDesktop--Home-booking-form {
    grid-template-areas: "departure-arrival-wrapper" "departure-return-dates-wrapper" "passenger" "button";
  }
}
@media screen and (min-width: 1200px) {
  .TabletDesktop--Home-booking-form {
    grid-template-areas: "departure-arrival-wrapper passenger" "departure-return-dates-wrapper button";
  }
}

@media screen and (min-width: 670px) {
  .TabletDesktop--Home-booking-form-absolute-position {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    margin: -180px;
    margin-left: 20px;
    margin-right: 20px;
    transition: 0.7s;
  }
}
@media screen and (min-width: 1200px) {
  .TabletDesktop--Home-booking-form-absolute-position {
    bottom: -150px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 670px) {
  .TabletDesktop--Home-booking-form-no-banner {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    margin: 20px;
    transition: 0.7s;
  }
}
@media screen and (min-width: 1200px) {
  .TabletDesktop--Home-booking-form-no-banner {
    bottom: -150px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }
}

.departure-arrival-wrapper {
  grid-area: departure-arrival-wrapper;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2px;
  margin-right: 2px;
}

.departure-return-dates-wrapper {
  grid-area: departure-return-dates-wrapper;
  margin-right: 2px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  box-sizing: border-box;
}
.departure-return-dates-wrapper .departure-return-dates-datepicker-wrapper {
  position: relative;
  top: -10px;
  height: auto;
  width: auto;
  z-index: 1000;
  display: grid;
}
.departure-return-dates-wrapper .departure-return-dates-datepicker-wrapper .departure-return-dates-datepicker {
  position: absolute;
  top: 4px;
  border: 2px solid #f0f0f0;
  background-color: white;
  border-radius: 5px;
}

.passenger {
  grid-area: passenger;
}

.button {
  width: 100%;
  grid-area: button;
  align-self: end;
  justify-self: end;
}
@media screen and (min-width: 670px) {
  .button {
    padding-bottom: 5px;
    padding-left: 5px;
  }
}

.Search__container {
  position: relative;
  background-color: #F5F5F5;
}

.edit-search-container .Search__container {
  background-color: #F5F5F5;
}

.edit-search-container .mobile-search-button button {
  background: #3c3835 !important;
  color: #fff !important;
}

@media screen and (max-width: 414px) {
  .home-page .searchform-wrapper {
    box-shadow: none !important;
  }
}

.edit-search-container .search-container {
  background-color: #FFFFFF !important;
}
.edit-search-container .search-container .Search__container {
  background-color: #FFFFFF !important;
}

.edit-search-container .searchform-wrapper {
  background-color: #fff !important;
  border: none !important;
  box-shadow: none !important;
}

.edit-search-container .searchform-wrapper .fieldlabel-wrapper {
  background-color: white !important;
}
.edit-search-container .searchform-wrapper .labelwrapper {
  background-color: white !important;
}

.Search--Form {
  z-index: 1;
  position: relative;
  top: 0;
  width: 1100px;
  left: calc((100% - 1100px) / 2);
  box-shadow: none !important;
}
.Search--Form .search-heading {
  font-size: 2rem;
  font-weight: bold;
  background: #fff;
  margin: 20px 0;
  padding: 1rem;
}
@media screen and (max-width: 1180px) {
  .Search--Form .search-heading {
    font-size: 1.8rem;
    padding: 2rem;
  }
}
@media screen and (max-width: 1180px) {
  .Search--Form {
    width: 100vw;
    left: 0;
  }
}
@media screen and (max-width: 766px) {
  .Search--Form .mobile-search-button {
    width: 100%;
    display: block; /* Changed from flex to block */
  }
  .Search--Form .mobile-search-button button {
    width: 100%;
    margin: 25px 0 0 0;
    padding: 1rem 1rem;
    font-size: 1.2rem !important;
    border-radius: 100px !important;
    background-color: #FAb500;
    color: #000;
    font-family: "Source Sans 3", sans-serif !important;
    font-weight: 600;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  }
}
.Search--Form .mobile-search-button button:hover {
  background-color: #D99A00 !important; /* a slightly darker shade on hover */
}
.Search--Form .points-button button {
  background-color: #FAb500 !important;
  border-radius: 50px !important;
  color: #000;
  font-family: "Source Sans 3", sans-serif !important;
  font-weight: 600;
}
.Search--Form .points-button button:hover {
  background-color: #D99A00 !important; /* a slightly darker shade on hover */
}

.carousel-images {
  width: 100%;
  max-width: 1600px;
  display: block;
}
@media screen and (max-width: 1180px) {
  .carousel-images {
    vertical-align: middle;
  }
}
@media screen and (min-width: 768px) and (max-width: 1180px) {
  .carousel-images {
    -webkit-transform: scale(1.75) translate(-50px, -40px);
            transform: scale(1.75) translate(-50px, -40px);
  }
}
@media screen and (min-width: 500px) and (max-width: 767px) {
  .carousel-images {
    -webkit-transform: scale(1.7) translate(-50px, -10px);
            transform: scale(1.7) translate(-50px, -10px);
  }
}

.carousel-wrapper {
  width: 100%;
  overflow: hidden;
}
.carousel-wrapper.carousel-yellow {
  background: rgb(253, 180, 26);
}
.carousel-wrapper.carousel-black {
  background: #101010;
}

.BrainhubCarousel__container {
  width: 100vw !important;
  max-width: 1600px;
  margin: 0 auto;
}

.BrainhubCarousel__dots {
  display: none !important;
}

.SectionWrapper {
  background-color: #fff;
  height: 100%;
}

.locationRow-container .icon-wrapper {
  display: flex;
}

.home-page {
  background-color: #000;
}

.custom-header-banner {
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px; /* Reduced gap for closer spacing between title and subtitle */
  background-color: #000;
  min-height: 110px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 40px 20px 10px 20px;
  position: relative;
}

.custom-header-text {
  /* This is the title styling */
  font-family: "Source Sans 3", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  margin: 0;
}

.custom-header-subtite {
  /* This is the caption styling */
  font-family: "Source Sans 3", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: rgba(231, 231, 231, 0.9607843137) !important;
  text-align: center;
  margin: 0;
}

.custom-header-subtite span {
  white-space: nowrap;
}

@media (max-width: 768px) {
  .custom-header-banner {
    min-height: 170px;
  }
  .custom-header-text {
    font-size: 1.8rem;
  }
}
@media (min-width: 1200px) {
  .custom-header-banner {
    margin: 0 auto;
  }
}
.searchform-wrapper {
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 4px 6px rgba(0, 0, 0, 0.06) !important;
}

.cities-and-routes-container {
  background-color: #f5f5f5;
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  gap: 32px;
  /* Responsive Design */
}
.cities-and-routes-container .section-title {
  font-family: "Source Sans 3", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 16px;
}
.cities-and-routes-container .city-list {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  gap: 24px;
  justify-content: center;
}
.cities-and-routes-container .city-list .state {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  max-width: 360px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}
.cities-and-routes-container .city-list .state .state-name {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #111;
  margin-bottom: 8px;
}
.cities-and-routes-container .city-list .state .airport-name {
  font-size: 16px;
  font-weight: 500;
  color: #555;
  margin-bottom: 4px;
}
.cities-and-routes-container .city-list .state .sub-destination {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  padding-left: 16px;
  line-height: 1.5;
}
@media screen and (max-width: 768px) {
  .cities-and-routes-container .city-list {
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
  }
}

.sale-header {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 1rem;
  color: #a00505; /* deeper pink hue */
}

/* Heart styling (kept compact) */
.heart {
  margin: 0 2px;
  color: #c60000;
  font-size: 16px;
}

/* Flashsale bubble styling (compact with deeper bubblegum pink) */
.flashsale {
  position: relative;
  display: inline-flex;
  align-items: center;
  background: linear-gradient(45deg, #a00505, #a00505); /* deeper bubblegum pink gradient */
  color: #FFE4E1;
  padding: 0px 16px; /* same as before */
  border-radius: 50px;
  font-size: 16px; /* unchanged font size */
  line-height: 20px;
  box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

/* Shiny highlight effect */
.flashsale::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 70%);
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation: shine 3s infinite linear;
          animation: shine 3s infinite linear;
}

@-webkit-keyframes shine {
  0% {
    -webkit-transform: translate(-100%, -100%) rotate(30deg);
            transform: translate(-100%, -100%) rotate(30deg);
  }
  50% {
    -webkit-transform: translate(100%, 100%) rotate(30deg);
            transform: translate(100%, 100%) rotate(30deg);
  }
  100% {
    -webkit-transform: translate(-100%, -100%) rotate(30deg);
            transform: translate(-100%, -100%) rotate(30deg);
  }
}

@keyframes shine {
  0% {
    -webkit-transform: translate(-100%, -100%) rotate(30deg);
            transform: translate(-100%, -100%) rotate(30deg);
  }
  50% {
    -webkit-transform: translate(100%, 100%) rotate(30deg);
            transform: translate(100%, 100%) rotate(30deg);
  }
  100% {
    -webkit-transform: translate(-100%, -100%) rotate(30deg);
            transform: translate(-100%, -100%) rotate(30deg);
  }
}
/* Code element styling */
.flashsale .code {
  font-family: inherit;
  font-weight: 800;
  background: rgba(0, 0, 0, 0.1);
  padding: 0px 6px;
  border-radius: 4px;
  margin: 4px 2px;
  letter-spacing: 0.05em;
  position: relative;
  z-index: 1; /* keep above the shine effect */
}
.peopleAreTalkingSection .comments-carousel {
  width: 560px;
  max-height: 382px;
}
@media screen and (max-width: 560px) {
  .peopleAreTalkingSection .comments-carousel {
    width: 320px;
  }
}
.peopleAreTalkingSection .peopleAreTalkingContainer {
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.peopleAreTalkingSection .peopleAreTalkingContainer .peopleAreTalkingTextContainer {
  padding: 136px 0px;
  background-color: #fab500;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
@media screen and (max-width: 550px) {
  .peopleAreTalkingSection .peopleAreTalkingContainer .peopleAreTalkingTextContainer {
    display: block;
  }
}
.peopleAreTalkingSection .peopleAreTalkingContainer .peopleAreTalkingTextContainer h3 {
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  color: #28221e;
  margin-bottom: 30px;
}
@media screen and (max-width: 550px) {
  .peopleAreTalkingSection .peopleAreTalkingContainer .peopleAreTalkingTextContainer h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    padding-top: 64px;
    margin-bottom: 25px;
  }
}
.peopleAreTalkingSection .peopleAreTalkingContainer .carouselWrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
}
.peopleAreTalkingSection .peopleAreTalkingContainer .slick-prev::before {
  content: url(${PUBLIC_URL}/static/media/arrow_back_ios.7e9d5ab2.svg) !important;
  color: black;
  font-size: 20px;
  left: 300px;
}
.peopleAreTalkingSection .peopleAreTalkingContainer .slick-next::before {
  content: url(${PUBLIC_URL}/static/media/arrow_forward_ios.09a713c8.svg) !important;
  color: black;
  font-size: 20px;
}
.carouselRatingsContainer {
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 550px) {
  .carouselRatingsContainer {
    padding: 0px;
  }
}
.carouselRatingsContainer .starsContainer .dividerTop {
  height: 1px;
  width: 32px;
  margin-top: 48px;
  margin-bottom: 24px;
  background-color: #28221e;
}
.carouselRatingsContainer .dividerBottom {
  height: 1px;
  width: 32px;
  margin-top: 24px;
  background-color: #28221e;
}
.carouselRatingsContainer p {
  font-size: 18px;
  line-height: 28px;
  color: #28221e;
  width: 560px;
  font-weight: 400;
}
@media screen and (max-width: 650px) {
  .carouselRatingsContainer p {
    width: 86.67%;
    font-size: 16px;
    line-height: 25px;
  }
}
.carouselRatingsContainer .name {
  margin: 30px 0px 8px 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
}
.carouselRatingsContainer .role {
  font-size: 14px;
  line-height: 20px;
  color: #4d4d4d;
  text-transform: uppercase;
}
@media screen and (max-width: 650px) {
  .carouselRatingsContainer .role {
    width: 243px;
    font-size: 12px;
    line-height: 16px;
  }
}
.image-text-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.image-text-wrapper .header {
  font-size: 36px;
}
.image-text-wrapper .mobile-header {
  display: none;
}
@media screen and (max-width: 1180px) {
  .image-text-wrapper {
    width: 100vw;
  }
  .image-text-wrapper .ImageWrapper {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 500px) {
  .image-text-wrapper .header {
    display: none;
  }
  .image-text-wrapper .mobile-header {
    display: block;
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    width: 300px;
    justify-self: flex-end;
    text-align: right;
    padding-right: 10px;
    margin-top: 60px;
  }
}
.image-text-wrapper .ImageWrapper {
  font-family: "Source Sans Pro", sans-serif;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  background-image: url(${PUBLIC_URL}/static/media/home-man-traveling.9d3bf59b.png);
  background-size: cover;
  background-position: 0 14%;
  height: 430px;
  width: 100%;
}
@media screen and (max-width: 1180px) {
  .image-text-wrapper .ImageWrapper {
    background-image: url(${PUBLIC_URL}/static/media/home-man-traveling-mobile.3f6240c8.jpeg);
    width: 100%;
    height: 500px;
  }
}
@media screen and (max-width: 1000px) {
  .image-text-wrapper .ImageWrapper {
    background-image: url(${PUBLIC_URL}/static/media/home-man-traveling-mobile.3f6240c8.jpeg);
    width: 100%;
    height: 700px;
  }
}
@media screen and (max-width: 850px) {
  .image-text-wrapper .ImageWrapper {
    background-image: url(${PUBLIC_URL}/static/media/home-man-traveling-mobile.3f6240c8.jpeg);
    height: 513px;
  }
}
@media screen and (max-width: 750px) {
  .image-text-wrapper .ImageWrapper {
    background-image: url(${PUBLIC_URL}/static/media/home-man-traveling-mobile.3f6240c8.jpeg);
    height: 600px;
  }
}
@media screen and (max-width: 450px) {
  .image-text-wrapper .ImageWrapper {
    background-image: url(${PUBLIC_URL}/static/media/home-man-traveling-mobile.3f6240c8.jpeg);
    height: 413px;
  }
}
@media screen and (min-width: 1200px) {
  .image-text-wrapper .ImageWrapper {
    display: flex;
  }
}
@media screen and (max-width: 900px) {
  .image-text-wrapper .text-container {
    width: 100%;
    padding: 0px 24px;
    background-color: #f5f5f5;
  }
}
.image-text-wrapper .text-container .TextWrapper {
  width: 450px;
  height: auto;
  padding: 30px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 110px;
}
@media screen and (min-width: 1200px) {
  .image-text-wrapper .text-container .TextWrapper {
    width: 35%;
    margin-left: calc((100% - 1100px) / 2);
  }
}
@media screen and (max-width: 900px) {
  .image-text-wrapper .text-container .TextWrapper {
    position: relative;
    top: 0px;
    right: 0px;
    -webkit-transform: unset;
            transform: unset;
    box-shadow: 0px 2px 4px rgba(40, 34, 30, 0.12);
    width: 100%;
    max-width: 395px;
    margin: 40px auto;
    margin-top: -80px;
  }
}
@media screen and (max-width: 1180px) {
  .image-text-wrapper .text-container .TextWrapper {
    padding: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
.image-text-wrapper .text-container .TextWrapper h2 {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.image-text-wrapper .text-container .TextWrapper h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}
.image-text-wrapper .text-container .TextWrapper ul {
  line-height: 150%;
}
.image-text-wrapper .text-container .TextWrapper ul li {
  margin-left: 8px;
}
.image-text-wrapper .text-container .TextWrapper div {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 670px) {
  .image-text-wrapper .text-container .TextWrapper div {
    flex-direction: row;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1180px) {
  .image-text-wrapper .text-container ul.bus-container {
    font-size: 16px;
    line-height: 26px;
  }
  .image-text-wrapper .text-container ul.bus-container li:before {
    /* the custom styled bullets */
    background-color: #ff9800;
    border-radius: 50%;
    content: "";
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 2px;
    height: 10px;
    width: 10px;
  }
}
.image-text-wrapper .text-container ul {
  list-style: none;
}
.image-text-wrapper .text-container .book-now-button {
  display: none;
}
@media screen and (max-width: 1180px) {
  .image-text-wrapper .text-container .book-now-button {
    align-self: center;
    justify-self: center;
    display: block;
    background: #fab500;
    padding: 7px 0;
    color: #000000;
    font-size: 16px;
    line-height: 20px;
    width: 235px;
    text-align: center;
    text-decoration: none;
    margin: 30px auto 20px;
  }
}
.image-text-wrapper .filler {
  display: none;
}
@media screen and (max-width: 1180px) {
  .image-text-wrapper .filler {
    display: block;
    background: #fab500;
    width: 100%;
    height: 50px;
    margin-top: -25px;
  }
}
.Wrapper--Promotion {
  background: #F5F5F5;
  padding: 20px 0;
  font-family: "Source Sans 3", sans-serif !important;
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .Wrapper--Promotion {
    display: flex;
    padding: 20px calc((100% - 1100px) / 2);
    padding-bottom: 50px;
  }
}

.Row--Promotion {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  gap: 24px;
}
@media screen and (min-width: 600px) {
  .Row--Promotion {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 850px) {
  .Row--Promotion {
    grid-template-columns: repeat(4, 1fr);
  }
}

.Quater--Promotion {
  flex: 1 1 100%;
  padding: 2px 10px;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}
@media screen and (min-width: 1200px) {
  .Quater--Promotion {
    align-items: left !important;
    padding-left: 0px !important;
  }
}
@media screen and (min-width: 600px) {
  .Quater--Promotion {
    padding: 20px;
    border-bottom: none;
    text-align: center;
    flex-direction: column;
  }
}
.Quater--Promotion .iconWrapper {
  padding-left: 20px;
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
}
.Quater--Promotion .iconWrapper img {
  width: 120px;
  height: 120px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 600px) {
  .Quater--Promotion .iconWrapper img {
    width: 60px;
    height: 60px;
  }
}
.Quater--Promotion .contentWrapper {
  flex-grow: 1;
  text-align: left;
  width: 100%;
}
.Quater--Promotion .contentWrapper h3 {
  font-size: 24px;
  margin: 0 0 8px;
  font-weight: 600;
  color: #000;
}
.Quater--Promotion .contentWrapper p {
  line-height: 1.5;
  font-size: 14px;
  color: #555;
}
.subscribe-email {
  margin-top: 20px;
}
.subscribe-email .subscribe-email-input {
  margin-right: 20px;
  flex: 5 1;
}
@media screen and (max-width: 850px) {
  .subscribe-email .subscribe-email-input {
    margin-right: 0px;
    padding-bottom: 24px;
  }
}

.subscribe-container {
  display: flex;
  width: 100%;
  background-color: white;
  font-family: "Source Sans 3", sans-serif;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  grid-gap: 22px;
  gap: 22px;
  padding: 40px 0;
}
.subscribe-container h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
}
.subscribe-container div {
  display: flex;
  flex-flow: column wrap;
}
@media screen and (min-width: 850px) {
  .subscribe-container div {
    flex-flow: row wrap;
  }
}
@media screen and (max-width: 768px) {
  .subscribe-container {
    width: -webkit-fill-available;
    margin: 1rem;
    text-align: -webkit-center;
  }
}
@media screen and (max-width: 1180px) {
  .subscribe-container {
    margin-top: 50px;
    padding-top: 0;
  }
  .subscribe-container h2 {
    text-align: center;
  }
  .subscribe-container #mc_embed_signup_scroll {
    flex-direction: column !important;
    flex-grow: unset !important;
    width: 80vw;
  }
  .subscribe-container .mc-field-group {
    flex-grow: unset !important;
    margin: 0 !important;
    width: 100%;
  }
  .subscribe-container #mc-embedded-subscribe {
    align-self: center;
    margin: 0 !important;
    margin-top: 10px !important;
    background: #3c3835 !important;
    color: #fab500 !important;
  }
  .subscribe-container img {
    display: none;
  }
  .subscribe-container img + div {
    width: 100vw !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .subscribe-container form {
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .subscribe-container {
    display: flex;
    width: 1100px;
    padding: 40px calc((100% - 1100px) / 2);
    box-sizing: content-box;
  }
}

.subscribe-inner-container p {
  line-height: 1.25;
  margin-top: 0.5rem;
}
@media screen and (min-width: 800px) {
  .subscribe-inner-container {
    width: 500px;
  }
}
#_form_1_ {
  font-size: 14px;
  line-height: 1.6;
  font-family: arial, helvetica, sans-serif;
  margin: 0;
}

#_form_1_ * {
  outline: 0;
}

._form_hide {
  display: none;
  visibility: hidden;
}

._form_show {
  display: block;
  visibility: visible;
}

#_form_1_._form-top {
  top: 0;
}

#_form_1_._form-bottom {
  bottom: 0;
}

#_form_1_._form-left {
  left: 0;
}

#_form_1_._form-right {
  right: 0;
}

#_form_1_ input[type=text],
#_form_1_ input[type=tel],
#_form_1_ input[type=date],
#_form_1_ textarea {
  padding: 6px;
  height: auto;
  border: #979797 1px solid;
  border-radius: 4px;
  color: #000 !important;
  font-size: 14px;
  box-sizing: border-box;
}

#_form_1_ textarea {
  resize: none;
}

#_form_1_ ._submit {
  -webkit-appearance: none;
  cursor: pointer;
  font-family: arial, sans-serif;
  font-size: 14px;
  text-align: center;
  background: #fab500 !important;
  border: 0 !important;
  border-radius: 8px !important;
  color: #3c3835 !important;
  padding: 10px !important;
}

#_form_1_ ._close-icon {
  cursor: pointer;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/esfkyjh1u_forms-close-dark.png");
  background-repeat: no-repeat;
  background-size: 14.2px 14.2px;
  position: absolute;
  display: block;
  top: 11px;
  right: 9px;
  overflow: hidden;
  width: 16.2px;
  height: 16.2px;
}

#_form_1_ ._close-icon:before {
  position: relative;
}

#_form_1_ ._form-body {
  margin-bottom: 30px;
}

#_form_1_ ._form-image-left {
  width: 150px;
  float: left;
}

#_form_1_ ._form-content-right {
  margin-left: 164px;
}

#_form_1_ ._form-branding {
  color: #fff;
  font-size: 10px;
  clear: both;
  text-align: left;
  margin-top: 30px;
  font-weight: 100;
}

#_form_1_ ._form-branding ._logo {
  display: block;
  width: 130px;
  height: 14px;
  margin-top: 6px;
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png");
  background-size: 130px auto;
  background-repeat: no-repeat;
}

#_form_1_ .form-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

#_form_1_ ._form-label,
#_form_1_ ._form_element ._form-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

#_form_1_._dark ._form-branding {
  color: #333;
}

#_form_1_._dark ._form-branding ._logo {
  background-image: url("https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png");
}

#_form_1_ ._form_element {
  position: relative;
  font-size: 0;
  max-width: 100%;
}

#_form_1_ ._form_element * {
  font-size: 14px;
}

#_form_1_ ._form_element._clear {
  clear: both;
  width: 100%;
  float: none;
}

#_form_1_ ._form_element._clear:after {
  clear: left;
}

#_form_1_ ._form_element input[type=text],
#_form_1_ ._form_element input[type=date],
#_form_1_ ._form_element select,
#_form_1_ ._form_element textarea:not(.g-recaptcha-response) {
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit;
}

#_form_1_ ._field-wrapper {
  position: relative;
}

#_form_1_ ._inline-style {
  float: left;
}

#_form_1_ ._inline-style input[type=text] {
  width: 150px;
}

#_form_1_ ._inline-style:not(._clear) + ._inline-style:not(._clear) {
  margin-left: 20px;
}

#_form_1_ ._form_element img._form-image {
  max-width: 100%;
}

#_form_1_ ._form_element ._form-fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}

#_form_1_ ._clear-element {
  clear: left;
}

#_form_1_ ._full_width {
  width: 100%;
}

#_form_1_ ._form_full_field {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

#_form_1_ input[type=text]._has_error,
#_form_1_ textarea._has_error {
  border: #f37c7b 1px solid;
}

#_form_1_ input[type=checkbox]._has_error {
  outline: #f37c7b 1px solid;
}

#_form_1_ ._error {
  display: block;
  position: absolute;
  font-size: 14px;
  z-index: 10000001;
}

#_form_1_ ._error._above {
  padding-bottom: 4px;
  bottom: 39px;
  right: 0;
}

#_form_1_ ._error._below {
  padding-top: 4px;
  top: 100%;
  right: 0;
}

#_form_1_ ._error._above ._error-arrow {
  bottom: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f37c7b;
}

#_form_1_ ._error._below ._error-arrow {
  top: 0;
  right: 15px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #f37c7b;
}

#_form_1_ ._error-inner {
  padding: 8px 12px;
  background-color: #f37c7b;
  font-size: 14px;
  font-family: arial, sans-serif;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
}

#_form_1_ ._error-inner._form_error {
  margin-bottom: 5px;
  text-align: left;
}

#_form_1_ ._button-wrapper ._error-inner._form_error {
  position: static;
}

#_form_1_ ._error-inner._no_arrow {
  margin-bottom: 10px;
}

#_form_1_ ._error-arrow {
  position: absolute;
  width: 0;
  height: 0;
}

#_form_1_ ._error-html {
  margin-bottom: 10px;
}

.pika-single {
  z-index: 10000001 !important;
}

#_form_1_ input[type=text].datetime_date {
  width: 69%;
  display: inline;
}

#_form_1_ select.datetime_time {
  width: 29%;
  display: inline;
  height: 32px;
}

#_form_1_ input[type=date].datetime_date {
  width: 69%;
  display: inline-flex;
}

#_form_1_ input[type=time].datetime_time {
  width: 29%;
  display: inline-flex;
}

@media all and (min-width: 320px) and (max-width: 667px) {
  ::-webkit-scrollbar {
    display: none;
  }
  #_form_1_ {
    margin: 0;
    box-sizing: border-box;
  }
  #_form_1_ * {
    box-sizing: border-box;
    font-size: 1em;
  }
  #_form_1_ ._form-content {
    margin: 0;
    width: 100%;
  }
  #_form_1_ ._form-inner {
    display: block;
    min-width: 100%;
  }
  #_form_1_ ._form-title,
  #_form_1_ ._inline-style {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }
  #_form_1_ ._form-title {
    font-size: 1.2em;
  }
  #_form_1_ ._row._checkbox-radio label {
    display: inline;
  }
  #_form_1_ ._row,
  #_form_1_ p,
  #_form_1_ label {
    margin-bottom: 0.7em;
    width: 100%;
  }
  #_form_1_ ._row input[type=checkbox],
  #_form_1_ ._row input[type=radio] {
    margin: 0 !important;
    vertical-align: middle !important;
  }
  #_form_1_ ._row input[type=checkbox] + span label {
    display: inline;
  }
  #_form_1_ ._row span label {
    margin: 0 !important;
    width: initial !important;
    vertical-align: middle !important;
  }
  #_form_1_ ._form-image {
    max-width: 100%;
    height: auto !important;
  }
  #_form_1_ input[type=text] {
    padding-left: 10px;
    padding-right: 10px;
    line-height: 1.3em;
    -webkit-appearance: none;
  }
  #_form_1_ input[type=radio],
  #_form_1_ input[type=checkbox] {
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    font-size: 1em;
    margin: 0 0.3em 0 0;
    vertical-align: baseline;
  }
  #_form_1_ button[type=submit] {
    padding: 20px;
  }
  #_form_1_ ._inline-style {
    margin: 20px 0 0 !important;
  }
}
#_form_1_ {
  position: relative;
  text-align: left;
  margin: 25px auto 0;
  padding: 20px;
  box-sizing: border-box;
  *zoom: 1;
  background: transparent !important;
  border: 0px solid #b0b0b0 !important;
  border-radius: 0px !important;
  color: #000 !important;
}

#_form_1_._inline-form,
#_form_1_._inline-form ._form-content,
#_form_1_._inline-form input,
#_form_1_._inline-form ._submit {
  font-family: "Source Sans 3", sans-serif;
}

#_form_1_ ._form-title {
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
}

#_form_1_:before,
#_form_1_:after {
  content: " ";
  display: table;
}

#_form_1_:after {
  clear: both;
}

#_form_1_._inline-style {
  width: auto;
  display: inline-block;
}

#_form_1_._inline-style input[type=text],
#_form_1_._inline-style input[type=date] {
  padding: 10px 12px;
}

#_form_1_._inline-style button._inline-style {
  position: relative;
  top: 27px;
}

#_form_1_._inline-style p {
  margin: 0;
}

#_form_1_._inline-style ._button-wrapper {
  position: relative;
}

#_form_1_ ._form-thank-you {
  position: relative;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
}

@media all and (min-width: 320px) and (max-width: 667px) {
  #_form_1_._inline-form._inline-style ._inline-style._button-wrapper {
    margin-top: 10px !important;
    margin-left: 0 !important;
  }
}
#_form_1_ .iti.iti--allow-dropdown.iti--separate-dial-code {
  width: 100%;
}

#_form_1_ .iti input {
  width: 100%;
  height: 32px;
  border: #979797 1px solid;
  border-radius: 4px;
}

#_form_1_ .iti--separate-dial-code .iti__selected-flag {
  background-color: #fff;
  border-radius: 4px;
}

#_form_1_ .iti--separate-dial-code .iti__selected-flag:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

#_form_1_ .iti__country-list {
  border-radius: 4px;
  margin-top: 4px;
  min-width: 460px;
}

#_form_1_ .iti__country-list--dropup {
  margin-bottom: 4px;
}

#_form_1_ .phone-error-hidden {
  display: none;
}

#_form_1_ .phone-error {
  color: #e40e49;
}

#_form_1_ .phone-input-error {
  border: 1px solid #e40e49 !important;
}

#_form_1_._form_1 {
  padding: 0;
}

#_form_1_ ._submit {
  padding: 12px 40px !important;
}

#_form_1_ ._button-wrapper {
  margin-left: 10px !important;
}

#_form_1_ ._x53373480 input {
  width: 270px !important;
  border: 1px solid #dadad0;
  border-radius: 8px;
  padding: 12px !important;
}

.kajamm #_form_1_._form_1 ._button-wrapper {
  margin-top: 0px !important;
}

.kajamm #_form_1_._form_1 ._button-wrapper {
  margin-bottom: 12px !important;
}

@media screen and (max-width: 850px) {
  .kajamm #_form_1_._form_1 ._submit {
    color: #fff !important;
    background: #3c3835 !important;
  }
  #_form_1_ ._button-wrapper {
    margin-left: 0px !important;
    margin-top: 10px !important;
  }
}
.MotorCoach {
  background-image: url(${PUBLIC_URL}/static/media/winterslice.d97e1dcf.jpg); /* Background image */
  background-size: cover; /* Ensures the image covers the full width */
  background-position: center; /* Centers the background */
  background-repeat: no-repeat;
  width: 100%;
  padding: 40px 0;
  box-sizing: border-box;
  font-family: "Source Sans 3";
  position: relative;
  /* Mobile view (768px and below) */
}
.MotorCoach::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none; /* Default: no gradient overlay on desktop */
}
.MotorCoach .content-wrapper {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  position: relative; /* Keeps the content above the background */
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  gap: 32px;
}
.MotorCoach .content-wrapper .left-column {
  max-width: 600px;
  width: 100%;
  padding: 0; /* No borders, no background box */
  color: #333;
}
.MotorCoach .content-wrapper .left-column .text-content .description {
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 16px;
}
.MotorCoach .content-wrapper .left-column .text-content .learn-more-link {
  display: inline-flex;
  align-items: center;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: #000;
}
.MotorCoach .content-wrapper .left-column .text-content .learn-more-link .arrow-icon {
  margin-left: 8px;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.MotorCoach .content-wrapper .left-column .text-content .learn-more-link:hover .arrow-icon {
  -webkit-transform: translateX(4px);
          transform: translateX(4px);
}
.MotorCoach .content-wrapper .left-column .timeline-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;
}
.MotorCoach .content-wrapper .left-column .timeline-wrapper .step-column {
  text-align: center;
}
.MotorCoach .content-wrapper .left-column .timeline-wrapper .step-column .caption {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}
.MotorCoach .content-wrapper .left-column .timeline-wrapper .lineSeparator {
  height: 4px;
  background-color: #fab500;
  flex-grow: 1;
  margin: 0 16px;
}
@media screen and (max-width: 768px) {
  .MotorCoach {
    background-image: none; /* Hide the background image in the main section */
    /* Add the background below the content */
  }
  .MotorCoach .content-wrapper {
    padding: 0 16px;
    max-width: 100%; /* Allow the content to fill the full width */
  }
  .MotorCoach .content-wrapper .left-column {
    max-width: none; /* Remove width constraints */
    padding: 0;
  }
  .MotorCoach::after {
    content: "";
    display: block;
    width: 100%;
    min-height: 250px; /* Adjust as needed for the background height */
    background-image: url(${PUBLIC_URL}/static/media/wintercenter.6c3c1773.jpg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.mclink {
  font-weight: 600; /* Slightly increased weight */
  color: #000; /* Force black text to prevent blue links on iPhone */
  text-decoration: none; /* Removes underline */
}
.mclink:hover {
  color: #fab500; /* Change color to #fab500 on hover */
}
.Cities {
  background-color: #F5F5F5;
  padding: 60px 24px;
  display: flex;
  justify-content: center;
  /* Cities We Serve Section */
  /* Routes List Section */
}
.Cities .content-wrapper {
  max-width: 1100px;
  width: 100%;
}
.Cities .cities-we-serve-wrapper {
  margin-bottom: 40px;
}
.Cities .cities-we-serve-wrapper .section-title {
  font-family: "Source Sans 3", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
}
.Cities .cities-we-serve-wrapper .cities-grid {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  grid-gap: 16px;
  gap: 16px;
}
.Cities .cities-we-serve-wrapper .city-card {
  background-color: white;
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  text-align: left;
  font-family: "Source Sans 3", sans-serif;
  height: 120px;
  text-decoration: none;
  color: #000;
}
.Cities .cities-we-serve-wrapper .city-card h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}
.Cities .cities-we-serve-wrapper .city-card p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #555;
}
.Cities .cities-we-serve-wrapper .city-card .city-card:hover {
  background-color: #e9ecef;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
.Cities .routes-list-wrapper-1 {
  margin-top: 2rem;
}
.Cities .routes-list-wrapper-1 .routes-columns {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}
@media (max-width: 768px) {
  .Cities .routes-list-wrapper-1 .routes-columns {
    flex-direction: column;
  }
}
.Cities .routes-list-wrapper-1 .routes-column {
  flex: 1 1;
  min-width: 300px;
}
.Cities .routes-list-wrapper-1 .routes-title {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.Cities .routes-list-wrapper-1 .routes-list {
  list-style: none;
  padding: 0;
}
.Cities .routes-list-wrapper-1 .routes-list .route-item {
  margin: 0rem 0;
}

/* Responsive Design - Moved outside the main class */
@media screen and (max-width: 1024px) {
  .Cities .cities-we-serve-wrapper .cities-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .Cities .cities-we-serve-wrapper .cities-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .Cities .routes-list-wrapper .routes-columns {
    flex-direction: column;
  }
}
@media screen and (max-width: 480px) {
  .Cities .cities-we-serve-wrapper .cities-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
/* Tabs Wrapper */
.tabs-wrapper {
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 20px;
}
.tabs-wrapper .tab-button {
  border: none;
  background-color: transparent;
  color: #000;
  text-decoration: none;
  border-bottom: 4px solid #ddd;
  padding: 10px 20px;
  font-family: "Source Sans 3", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 23px;
  cursor: pointer;
}
.tabs-wrapper .tab-button.active {
  border-bottom: 4px solid #FAb500;
  color: #FAB500;
  font-weight: 600;
}
.tabs-wrapper .tab-button:hover {
  background-color: #e0e0e0;
}

/* Existing classes remain unchanged */
.route-item {
  text-decoration: none;
  color: #000;
}

.route-item:hover {
  color: #fab500;
}
.BookEarlyAndSave {
  background: #fab500;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 24px;
  justify-content: center;
  font-family: "Source Sans 3";
}
.BookEarlyAndSave .content-wrapper {
  max-width: 1100px;
  width: 100%;
}
.BookEarlyAndSave h2 {
  font-family: "Source Sans 3", sans-serif;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 32px;
  color: #000000;
  text-align: center;
}
.BookEarlyAndSave .price-list {
  display: grid;
  grid-gap: 12px;
  gap: 12px;
  width: 100%;
  grid-template-columns: 1fr;
}
.BookEarlyAndSave .price-row {
  text-decoration: none;
  color: inherit;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: flex-start;
  height: auto;
  transition: background-color 0.2s ease-in-out;
}
.BookEarlyAndSave .price-row:hover {
  background-color: #f7f7f7;
}
.BookEarlyAndSave .price-row .location-connector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 20px;
  gap: 20px;
}
.BookEarlyAndSave .price-row .location-connector .location {
  display: flex;
  align-items: top;
  width: 100%;
}
.BookEarlyAndSave .price-row .location-connector .location .bullet {
  margin-top: 4px;
  position: absolute;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  background-color: #000;
  border-radius: 100%;
  margin-right: 8px;
  margin-left: 4px;
}
.BookEarlyAndSave .price-row .location-connector .location .location-name {
  position: absolute;
  left: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  line-height: 1.2;
  max-width: calc(100% - 24px);
  overflow-wrap: break-word;
}
.BookEarlyAndSave .price-row .location-connector .location .endbullet {
  background-color: transparent;
  width: auto;
  height: auto;
  font-size: 24px;
  color: #000;
  margin-right: 4px;
  margin-top: -4px;
  flex-shrink: 0;
}
.BookEarlyAndSave .price-row .location-connector .connector-line {
  position: relative;
  top: 8px;
  width: 4px;
  background-color: #FAB500;
  height: 48px;
  margin-left: 8px;
  align-self: left;
}
.BookEarlyAndSave .price-row .price-section {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 16px;
}
.BookEarlyAndSave .price-row .price-section .from-text {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  margin-right: 8px;
}
.BookEarlyAndSave .price-row .price-section .price {
  font-size: 28px;
  font-weight: 700;
  color: #000;
}
.BookEarlyAndSave .price-row .pill {
  background-color: #FAB500;
  color: #000;
  border-radius: 100px;
  padding: 0px 14px;
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
  margin-top: 16px;
  font-family: "Source Sans 3";
}
@media screen and (min-width: 768px) {
  .BookEarlyAndSave .price-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1200px) {
  .BookEarlyAndSave .price-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

.BookEarlyAndSave .price-row {
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}
.BookEarlyAndSave .price-row .icon-arrow {
  position: absolute;
  bottom: 24px;
  right: 24px;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  color: #fab500;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.BookEarlyAndSave .price-row:hover .icon-arrow {
  -webkit-transform: translateX(4px);
          transform: translateX(4px);
}
.Amenities {
  background: #FAB500;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 24px;
  justify-content: center;
  font-family: "Source Sans 3" !important;
}
.Amenities .content-wrapper {
  max-width: 1100px;
  width: 100%;
}
.Amenities h2 {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 40px;
  color: #000000;
  text-align: center;
}
.Amenities .amenities-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 16px;
  gap: 16px;
  padding: 0;
  margin: 0;
}
.Amenities .amenity-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  text-align: center;
}
.Amenities .amenity-item .amenity-icon {
  font-size: 40px;
  margin-bottom: 8px;
}
.Amenities .amenity-item .amenity-name {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .Amenities .amenities-list {
    justify-content: center;
  }
  .Amenities .amenity-item .amenity-icon {
    font-size: 32px;
  }
  .Amenities .amenity-item .amenity-name {
    font-size: 14px;
  }
}
.loginPage__wrapper {
  padding: 20px;
}
.loginPage__wrapper .loginPage--legalSection {
  font-size: 14px;
  text-align: flex-start;
  padding-top: 10px;
  color: #302d2b;
  margin-bottom: 40px;
}
.loginPage__wrapper .loginPage--legalSection a {
  font-weight: bold;
  color: #302d2b;
}
.loginPage__wrapper .loginPage--action--question {
  font-size: 14px;
  padding-bottom: 5px;
  color: #302d2b;
  font-weight: bold;
}

.loginPage__wrapper.login--dialog {
  width: auto;
  border-radius: 5px;
}
.loyalty-buy-points-confirmation-page-wrapper {
  height: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.loyalty-buy-points-confirmation-page-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  padding: 20px 0;
}

.loyalty-buy-points-confirmation-page-content {
  padding: 0 20px;
  height: 100%;
}

.loyalty-buy-points-confirmation-page-LoyaltyPointsBalance-container {
  padding-bottom: 80px;
}
.loyalty-buy-points-page-content {
  background-color: #fff;
  padding: 20px;
}
.loyalty-buy-points-page-content .currency-style,
.loyalty-buy-points-page-content .defaultPrice {
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 16px !important;
}

@media screen and (max-width: 1200px) {
  .loyalty-buy-points-page-content {
    padding: 20px 20px 0px 20px;
  }
}
.loyalty-buy-points-page-LoyaltyPointsBalance-container {
  padding-bottom: 40px;
}
.credit-card-form__wrapper {
  border-radius: 6px;
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  padding: 40px 45px;
}
.credit-card-form__wrapper .credit-card-form__header {
  border-bottom: 0;
  font-weight: 400;
  font-size: 22px;
  line-height: 16px;
}
.credit-card-form__wrapper .credit-card-form__fieldset-wrapper {
  padding-left: 10px;
}
.credit-card-form__wrapper .credit-card-form__fieldset-wrapper label {
  background: #f5f5f5 !important;
  font-weight: 400;
  font-size: 8.5px;
  line-height: 10px;
  letter-spacing: 0.05em;
  left: 10px;
  top: -5px;
  padding: 0 5px;
}

.payment-buttons button {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  background: #fab500;
  color: #000;
}
.TripManageForm--button {
  background-color: #3C3835;
  border-radius: 6px;
  margin-top: 10px;
}
.TripManageForm--button:hover {
  background-color: #FAB500;
  color: #3C3835;
}
.mile-marker-accrual-log-page-container .currency-style,
.mile-marker-accrual-log-page-container .defaultPrice {
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 16px !important;
}
.mile-marker-accrual-log-page-container .SectionWrapper {
  background-color: #fff;
  padding: 40px 20px 20px 20px;
}
.mile-marker-accrual-log-page-container .title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
}
.mile-marker-accrual-log-page-container .title-container .title {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}
.mile-marker-accrual-log-page-container .title-container .id {
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
}
.myTrips-container {
  background-color: #f5f5f5;
  height: 100%;
}
.myTrips-container .SectionWrapper {
  background-color: #f5f5f5;
}
.myTrips-container .tabs-navigation {
  padding-bottom: 24px;
}

.my-trip-manage-link {
  display: block;
  margin: 20px 8px;
  text-decoration: none;
  color: #3c3835;
  font-size: 18px;
}

.my-trip-manage-link:hover {
  color: rgb(250, 181, 0);
}
.tab-layout-container {
  width: 100%;
}
.tab-layout-container .tab-content {
  padding-top: 24px;
}
.tab-layout-container .tabs-navigation {
  width: 100%;
  display: flex;
  background-color: #fff;
  padding: 0 calc((100% - 1100px) / 2);
}
@media screen and (max-width: 500px) {
  .tab-layout-container .tabs-navigation .tab-container {
    flex: 1 1;
  }
}
.tab-layout-container .tabs-navigation .tab-container {
  width: 25%;
  position: relative;
  color: #999999;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  font-size: 18px;
  line-height: 150%;
  cursor: pointer;
}
.tab-layout-container .tabs-navigation .tab-container:hover {
  color: #fab500;
}
.tab-layout-container .tabs-navigation .activeTab {
  font-weight: 600;
  color: #fab500;
  opacity: 1;
  font-size: 18px;
}

.trip-container {
  background-color: #fff;
  border-radius: 6px 6px 0 0;
  margin-bottom: 20px;
}
.trip-container .flights-manage-booking-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 50%;
  margin: 20px auto 0px auto;
}
.trip-container .flights-manage-booking-container .text {
  padding: 20px;
  text-align: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e5e5e5;
}
.trip-container .return-segment-container,
.trip-container .departure-segment-container {
  padding: 20px 0;
}
.trip-container .collapsiblebutton-toogle-wrapper {
  border-bottom: none;
}
@media screen and (max-width: 700px) {
  .trip-container .flights-manage-booking-container {
    max-width: 100%;
  }
  .trip-container .change-seat {
    flex-direction: column;
  }
}
.trip-container:nth-child(1) {
  border-radius: 0px 6px 0 0;
}
.trip-container .leg-wrapper {
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.trip-container .leg-wrapper .legconnection-container {
  padding-bottom: 0px;
}
.trip-container .leg-wrapper .leg-title {
  display: flex;
}
.trip-container .leg-wrapper .leg-title span:nth-child(1) {
  position: absolute;
}
.trip-container .leg-wrapper .leg-title span:nth-child(2) {
  display: block;
  margin: 0 auto;
  font-weight: 600;
  font-size: 16px;
}
.trip-container .trip-additional-info {
  display: block;
  margin-top: 5px;
  font-style: italic;
  font-size: 12px;
  line-height: 12px;
  color: #696d6f;
}
.trip-container .trip-leg-container {
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
}
@media screen and (max-width: 700px) {
  .trip-container .trip-leg-container {
    flex-direction: column;
    display: flex;
  }
}
@media screen and (max-width: 414px) {
  .trip-container .trip-check-in-button-icon {
    display: none;
  }
}
.trip-container .checkin-button-wrapper {
  margin-right: 16px;
}
.trip-container .checkin-button-wrapper button,
.trip-container .boarding-button-wrapper button {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trip-container .checkin-button-wrapper svg,
.trip-container .boarding-button-wrapper svg {
  margin-left: 8px;
}
.trip-container .trip-depart-container .pnr-cancel-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.trip-container .trip-depart-container .pnr-cancel-container span {
  padding-right: 15px;
}
.trip-container .trip-depart-container .pnr-cancel-container.canceled {
  color: #ff4040;
}
.trip-container .trip-depart-container .trip-depart-title {
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  padding-top: 10px;
  border-bottom: 1px solid #3c3835;
  padding: 20px 20px 0 20px;
  padding-bottom: 10px;
}
.trip-container .trip-depart-container .trip-depart-title .title {
  font-weight: 600;
  font-size: 18px;
}
.trip-container .trip-depart-container .trip-depart-title-past-trip {
  color: #ff4040;
}
.trip-container .trip-depart-container .trip-depart-title-current-trip {
  color: #00c08b;
}
.trip-container .trip-depart-container .trip-depart-title span {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: space-around;
  font-size: 20px;
  line-height: 40px;
  color: #3c3835;
}
.trip-container .trip-depart-container .additional-info-wrapper {
  display: flex;
  flex-direction: column;
}
.trip-container .change-seat {
  display: flex;
}
.trip-container .change-seat .change-seat-container {
  border-top: 1px solid rgba(60, 56, 53, 0.22);
  border-bottom: 1px solid rgba(60, 56, 53, 0.22);
  margin-top: 36px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1 1;
}
.trip-container .change-seat .change-seat-container .passengers-container .titles {
  display: flex;
  padding-bottom: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
}
.trip-container .change-seat .change-seat-container .passengers-container .titles .name-title {
  flex: 1 1;
}
.trip-container .change-seat .change-seat-container .passengers-container .titles .seat-title {
  flex: 1 1;
}
.trip-container .change-seat .change-seat-container .passenger {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
.trip-container .change-seat .change-seat-container .name {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.trip-container .change-seat .change-seat-container .ticket-number {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.trip-container .change-seat .change-seat-container .seats-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.trip-container .change-seat .change-seat-container .seats-container .seat-wrapper {
  display: flex;
}
.trip-container .change-seat .change-seat-container .seats-container .seat-wrapper .seatplace {
  width: 35px;
  height: 35px;
  font-size: 16px;
}
.trip-container .change-seat .change-seat-container .seats-container .seat-wrapper .seatplace .border {
  width: 25px;
}
.trip-container .change-seat .change-seat-container .seats-container .seat-wrapper .seatplace span {
  font-size: 16px;
}
.trip-container .change-seat .leg-connection-vertical-wrapper {
  flex: 1 1;
}
@media screen and (max-width: 700px) {
  .trip-container .return-segment {
    flex-direction: column;
  }
}
.trip-container .return-segment {
  display: flex;
  padding: 0 20px;
}
.trip-container .collapsiblebutton-wrapper {
  padding: 0 20px;
}

@media screen and (max-width: 340px) {
  .edit-trip-modal .modalButton button {
    padding: 5px 0px;
  }
}
.edit-trip-modal .modalButton {
  margin-bottom: 10px !important;
}
.edit-trip-modal .modalButton:last-child {
  margin-bottom: 0px !important;
}

.edit-trip-button {
  display: flex;
  padding: 0 20px;
}
.edit-trip-button .edit-trip-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 480px) {
  .edit-trip-button .edit-trip-container {
    padding: 0px 20px;
  }
}
@media screen and (max-width: 380px) {
  .edit-trip-button .edit-trip-container .text-container {
    padding: 0px 20px;
  }
}
.edit-trip-button .edit-trip-container .edit-trip-button-wrapper {
  margin: 24px 0px;
  background-color: #f5f5f5;
  display: flex;
  flex-basis: 484px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.edit-trip-button .edit-trip-container .edit-trip-button-wrapper .text-container {
  text-align: center;
  max-width: 312px;
  font-size: 14px;
  line-height: 20px;
  margin: 24px 0px;
}
.edit-trip-button .edit-trip-container .edit-trip-button-wrapper button {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
.edit-trip-button .edit-trip-container .edit-trip-button-wrapper button:hover svg {
  fill: #3c3835;
}
.edit-trip-button .edit-trip-container .edit-trip-button-wrapper svg {
  fill: #fab500;
  margin-left: 8px;
}

.trip-leg-container-black-car {
  background-color: #dcdcdb;
}
.trip-leg-container-black-car .collapsiblebutton-wrapper {
  background-color: #dcdcdb;
}

@media only screen and (max-width: 365px) {
  .trip-container .checkin-button-wrapper {
    margin: 0 0 4px 0;
  }
  .trip-container .checkin-button-wrapper,
  .trip-container .boarding-button-wrapper {
    width: 160px;
  }
  .trip-container .checkin-button-wrapper button,
  .trip-container .boarding-button-wrapper button {
    font-size: 14px;
  }
  .trip-container .leg-wrapper .leg-title span:nth-child(2) {
    font-size: 14px;
    margin: 0 0 0 70px;
  }
}
@media only screen and (max-width: 400px) {
  .trip-container .checkin-button-wrapper {
    margin: 0 0 4px 0;
  }
  .trip-container .checkin-button-wrapper,
  .trip-container .boarding-button-wrapper {
    width: 160px;
  }
  .trip-container .checkin-button-wrapper button,
  .trip-container .boarding-button-wrapper button {
    font-size: 14px;
  }
  .trip-container .leg-wrapper .leg-title span:nth-child(2) {
    font-size: 14px;
    margin: 0 0 0 70px;
  }
}
@media only screen and (max-width: 540px) {
  .trip-container .checkin-button-wrapper {
    margin: 0 0 4px 0;
  }
  .trip-container .checkin-button-wrapper,
  .trip-container .boarding-button-wrapper {
    width: 200px;
  }
  .trip-container .checkin-button-wrapper button,
  .trip-container .boarding-button-wrapper button {
    font-size: 14px;
  }
  .trip-container .leg-wrapper .leg-title span:nth-child(2) {
    font-size: 14px;
    margin: 0 0 0 70px;
  }
}
.modal-footer-text {
  margin: 56px 32px 32px 32px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
.modal-footer-text span {
  color: #ff4040;
}
.edit-trip-column-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  margin-bottom: 8px;
  cursor: pointer;
}
.edit-trip-column-container:hover {
  background-color: #f5f5f5;
}
.edit-trip-column-container .action-button-container {
  line-height: 0px;
  padding-right: 12px;
}
.edit-trip-column-container .action-button-container svg {
  transition: all 0.3s ease;
  background-color: #f5f5f5;
  border-radius: 100%;
  fill: #3c3835;
}
.edit-trip-column-container .action-button-container svg:hover {
  background-color: #3c3835;
  fill: #fab500;
}
.edit-trip-column-container .left-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.edit-trip-column-container .left-container .icon-container {
  line-height: 0px;
  padding: 16px 0px 16px 16px;
}
.edit-trip-column-container .left-container .column-text-container {
  max-width: 310px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
}
.edit-trip-column-container .left-container .column-text-container .middle-column {
  margin-bottom: 4px;
  font-size: 10px;
  line-height: 12px;
}
.edit-trip-column-container .left-container .column-text-container .column-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.edit-trip-column-container .left-container .column-text-container .column-description {
  font-size: 10px;
  line-height: 12px;
  color: #999999;
}
.canceled-container {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.canceled-container .big-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 56px;
  max-width: 250px;
}
.canceled-container .small-text {
  max-width: 300px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 56px;
}
.canceled-container .small-text .orange-text {
  color: #fab500;
}

.cancel-confirmation-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cancel-confirmation-container .big-title {
  max-width: 300px;
  margin-top: 48px;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  padding: 0px 32px;
}
.cancel-confirmation-container .big-title .red-text {
  color: #ff4040;
}
.cancel-confirmation-container .cancel-confirmation-buttons-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel-confirmation-container .cancel-confirmation-buttons-container button {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.cancel-confirmation-container .bottom-content-container {
  margin-top: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cancel-confirmation-container .bottom-content-container .important-info {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
}
.cancel-confirmation-container .bottom-content-container .refund-container {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 32px;
}
.cancel-confirmation-container .bottom-content-container .refund-container span {
  color: #fab500;
}
.change-of-plans-modal-body-container .upper-container {
  margin: 0px 32px;
}
.change-of-plans-modal-body-container .upper-container .gray-circle-luggage-icon {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0px 8px 0px;
}
.change-of-plans-modal-body-container .upper-container .change-of-plans-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}
.change-of-plans-modal-body-container .upper-container .change-of-plans-text {
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}
.change-of-plans-modal-body-container .change-of-plans-button-container {
  flex: 1 1;
  display: flex;
  margin: 48px 0px 24px 0px;
  justify-content: center;
}
.change-of-plans-modal-body-container .change-of-plans-button-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.change-of-plans-modal-body-container .change-of-plans-button-container .destructive button {
  border: 1px solid #ff4040;
  padding: 0px 24px;
  margin-left: 16px;
  transition: all 0.3s ease;
}
.change-of-plans-modal-body-container .change-of-plans-button-container .destructive button svg {
  fill: #fff;
}
.change-of-plans-modal-body-container .change-of-plans-button-container .destructive:hover svg {
  fill: #ff4040;
}
.change-of-plans-modal-body-container .change-of-plans-button-container svg {
  margin-left: 8px;
  position: static;
  top: unset;
  right: unset;
  -webkit-transform: unset;
          transform: unset;
}
@media screen and (max-width: 413px) {
  .change-of-plans-modal-body-container .change-of-plans-button-container {
    flex-direction: column;
    align-items: center;
  }
  .change-of-plans-modal-body-container .change-of-plans-button-container .primary button {
    margin-bottom: 24px;
  }
  .change-of-plans-modal-body-container .change-of-plans-button-container .destructive button {
    margin-left: 0px;
  }
}
.checkin-modal-container {
  padding: 48px 40px;
}
.checkin-modal-container h4 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}
.checkin-modal-container .tab-modal-group-container {
  margin-top: 48px;
}
.checkin-modal-container .title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkin-modal-container .title-container svg {
  margin-left: 12px;
}
.checkin-modal-container .checkin-button-container {
  display: flex;
  justify-content: center;
  margin-top: 104px;
}
.checkin-modal-container .checkin-button-container button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkin-modal-container .checkin-button-container button svg {
  margin-left: 8px;
  fill: #fab500;
}
.checkin-modal-container .checkin-button-container button:hover svg {
  fill: #3c3835;
}
.checkin-modal-info-container .depart {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.checkin-modal-info-container .return {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  flex: 1 1;
}
.checkin-modal-info-container .legconnection-container {
  margin-top: 56px;
}
.checkin-modal-info-container .city-name-container {
  margin-top: 48px;
  width: 100%;
  display: flex;
}
.checkin-modal-info-container .city-name-container svg {
  fill: #cdccc8;
  margin-top: 10px;
}
.checkin-modal-info-container .datetime-container {
  display: flex;
}
.checkin-modal-info-container .text-below-action {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkin-modal-info-container .text-below-action.clickable {
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  white-space: nowrap;
}
.checkin-modal-info-container .primary svg {
  fill: #fab500 !important;
}
.checkin-modal-info-container .primary:hover svg {
  fill: #3c3835 !important;
}
.checkin-modal-info-container .regular svg {
  fill: #3c3835 !important;
}
.checkin-modal-info-container .regular:hover svg {
  fill: #fab500 !important;
}
.edit-trip-modal-layout-container {
  padding-bottom: 10px;
}
.edit-trip-modal-layout-container .go-back-button-container {
  display: flex;
  align-items: center;
  position: absolute;
  left: 18px;
  top: 16px;
  cursor: pointer;
}
.edit-trip-modal-layout-container .go-back-button-container span {
  margin-left: 8px;
}
.edit-trip-modal-layout-container .gray-circle-editing-icon {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0px 24px 0px;
}
.edit-trip-modal-layout-container .title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 48px;
}
.edit-trip-modal-layout-container .text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  margin-bottom: 40px;
}
.edit-trip-modal-layout-container .buttons-container {
  margin: 0px 40px 0px 40px;
  display: flex;
}
.edit-trip-modal-layout-container .horizontal-break-line {
  flex: 1 1;
  margin: 24px;
}
.select-segment-to-edit-container {
  margin: 0px 16px;
}
.passenger-page-breadcrumbs .breadcrumbs-container {
  max-height: 45px !important;
}
@media screen and (max-width: 520px) {
  .passenger-page-breadcrumbs .breadcrumbs-container {
    display: none !important;
  }
}

.passenger-page-container {
  background-color: #fff;
  padding-top: 50px;
}
.passenger-page-container .pagetitle {
  border-bottom: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
}
.passenger-page-container .use-legal-name {
  /* Used only to contact you in case of delays or itinerary changes */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #999999;
  display: block;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) and (max-width: 820px) {
  .passenger-page-container {
    padding-top: 0px;
  }
}
.passenger-page-container .passenger-page-form-container {
  padding: 0 20px;
  max-width: 50%;
  margin: 0 auto;
}
@media screen and (max-width: 500px) {
  .passenger-page-container .passenger-page-form-container {
    max-width: 90%;
  }
}
.passenger-page-container form {
  padding: 0 20px;
}
.passenger-page-container form .title {
  width: 50%;
}
@media screen and (max-width: 413px) {
  .passenger-page-container form .first-name,
  .passenger-page-container form .last-name,
  .passenger-page-container form .email,
  .passenger-page-container form .phone {
    width: 300px;
  }
}
.passenger-page-container form .inline-fields {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}
@media screen and (max-width: 413px) {
  .passenger-page-container form .inline-fields {
    width: 300px;
  }
}
.passenger-page-container form .gender {
  height: 60px;
}
.passenger-page-container form .date-of-birth,
.passenger-page-container form .gender,
.passenger-page-container form .airline,
.passenger-page-container form .flight-number {
  flex: 1 1;
}
.passenger-page-container form .optional-fields {
  margin-top: 20px;
}
.passenger-page-container form .mobile-usage-label {
  /* Used only to contact you in case of delays or itinerary changes */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  /* or 100% */
  text-align: right;
  color: #999999;
  display: block;
  margin-bottom: 20px;
}
@media screen and (max-width: 414px) {
  .passenger-page-container form .mobile-usage-label {
    width: auto;
  }
}
.passenger-page-container h3 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  margin-left: -10px;
  margin-bottom: 10px;
}
.passenger-page-container .special-assistance {
  display: flex;
  margin-bottom: 4dvw;
  flex-direction: column-reverse;
}
.passenger-page-container .special-notes {
  background-color: #f4f4f4;
  border-bottom: 1px solid #626262;
  font-family: Arial, Helvetica, sans-serif;
  padding: 1vw;
  font-size: 0.8rem;
  line-height: 1.2rem;
}
.passenger-page-container .special-assistance .special-notes .special-notes-title {
  color: #2b2b2b;
}
.passenger-page-container .special-assistance .special-notes .special-notes-description {
  color: red;
}
.passenger-page-container .special-assistance .collapsiblebutton-toogle-wrapper {
  border-bottom: 0;
  justify-content: flex-start;
}
.passenger-page-container .special-assistance .collapsiblebutton-toogle-wrapper .collapsiblebutton-name strong {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 23px;
  color: #000000;
  margin-left: -10px;
  margin-right: 10px;
}
.passenger-page-container .special-assistance .collapsiblebutton-content-container {
  margin-top: 10px;
}
.passenger-page-container .special-assistance .collapsiblebutton-content-container > div > span {
  display: none;
}
.passenger-page-container .date-of-birth {
  display: flex;
  flex-direction: column;
}
.passenger-page-container .date-of-birth .explainer {
  align-self: flex-end;
  font-size: 12px;
  text-decoration: underline;
  cursor: help;
}
.passenger-page-container .gender .dropdowndatepicker-wrapper,
.passenger-page-container .date-of-birth .dropdowndatepicker-wrapper {
  margin: 0 !important;
}
.passenger-page-container .gender {
  margin-top: -12px;
}

.SectionWrapper {
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .SectionWrapper {
    width: 1100px;
    margin: 0 auto;
  }
}

.Passenger--seat {
  padding-top: 40px;
  background-color: #f0f0f0;
}

.PassengerButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}

.Passenger--button {
  border-radius: 6px;
}
.Passenger--button:hover {
  color: #3c3835;
  background-color: #fab500;
}

.passenger-buttons {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
.passenger-buttons .primary.button-default {
  width: auto !important;
}
@media screen and (max-width: 414px) {
  .passenger-buttons {
    width: 100% !important;
  }
  .passenger-buttons .primary.button-default {
    width: 100% !important;
  }
  .passenger-buttons .primary.button-default button {
    width: 100% !important;
  }
}

.passenger-buttons button {
  width: 110px !important;
  height: 20px;
  background: #fab500;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
@media screen and (max-width: 750px) {
  .passenger-buttons button {
    padding: 0px 20px;
    width: 100%;
  }
}

.Separator {
  width: 100%;
  height: 10px;
  border-bottom: 1px solid #999;
  position: relative;
}

.Input--Fields {
  display: flex;
  flex-direction: column;
  width: 400px;
}
@media screen and (max-width: 750px) {
  .Input--Fields {
    width: 100%;
  }
}

.passenger-small-text {
  text-align: center;
  display: block;
  font-family: "Source Sans Pro", sans-serif;
  color: #696d6f;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}
.breadcrumbs-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 85px;
  z-index: 999;
  background-color: #28221e;
}
@media screen and (max-width: 1199px) {
  .breadcrumbs-wrapper {
    top: 96px;
  }
}
.breadcrumbs-wrapper .breadcrumbs-container {
  display: flex;
  flex-direction: row;
  background: #4d4d4d;
  height: 66px;
  max-height: 80px;
  position: relative;
  padding: 12px 18px;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: flex-start;
  min-width: 320px;
}
.breadcrumbs-wrapper .breadcrumbs-container .icon-wrapper {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: 2px;
}
.breadcrumbs-wrapper .breadcrumbs-container .breadcrumbs-item {
  box-sizing: border-box;
  padding: 0;
  display: inline-flex;
  flex-direction: row;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  flex-grow: 0;
  width: fit-content;
  margin-right: 5px;
}

.MobileBreadcrumbs-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 85px;
  z-index: 999;
  background: #ebebeb;
  display: none;
}
@media screen and (max-width: 520px) {
  .MobileBreadcrumbs-wrapper {
    display: flex;
  }
}
@media screen and (max-width: 1199px) {
  .MobileBreadcrumbs-wrapper {
    top: 96px;
  }
}
.MobileBreadcrumbs-wrapper .MobileBreadcrumbs-container {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.MobileBreadcrumbs-wrapper .MobileBreadcrumbs-item {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
}
.MobileBreadcrumbs-wrapper .MobileBreadcrumbs-item__active {
  color: #000;
}
.MobileBreadcrumbs-wrapper .MobileBreadcrumbs-spacer {
  border-top: 1px solid #999999;
  width: 17px;
}
.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100dvw;
  height: 100dvh;
  z-index: 1002;
  align-items: center;
  justify-content: center;
}

.modal {
  z-index: 20;
  border-radius: 20px;
  box-shadow: 0px 1px 23px 5px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  position: relative;
}

.login-dialog-btns-container {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border: 1px solid #fab500;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  background-color: rgba(250, 181, 0, 0.168627451);
  line-height: 1.25;
  margin-bottom: 1rem;
}
.login-dialog-btns-container button {
  background-color: transparent;
  border: none;
  color: #fab500;
}

.login-dialog-close-btn {
  top: 21px;
  right: 21px;
  position: absolute;
  z-index: 20;
  cursor: pointer;
  padding: 0.25rem;
  width: 24px;
  height: 24px;
}
.passwordAssistance {
  padding: 20px;
}
.payment-page-breadcrumbs .breadcrumbs-container {
  max-height: 45px !important;
}
@media screen and (max-width: 520px) {
  .payment-page-breadcrumbs .breadcrumbs-container {
    display: none !important;
  }
}

@media screen and (max-width: 520px) {
  .payment-summary-container {
    margin-top: 20px;
  }
}

.info-text-wrapper {
  padding-top: 10px;
}

.payment-buttons {
  width: 50%;
  margin: 20px auto;
}
@media screen and (max-width: 750px) {
  .payment-buttons {
    width: 100%;
  }
}

.leg-payment-wrapper .itinerarySingleRow-container {
  margin: 0;
  width: 100%;
}

.inline-payment-info {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  grid-gap: 65px;
  gap: 65px;
  margin-top: 60px;
}
@media screen and (max-width: 520px) {
  .inline-payment-info {
    margin-top: 0 !important;
  }
  .inline-payment-info .loyalty-reward-wrapper {
    display: none;
  }
}
.inline-payment-info .payment-prices-wrapper,
.inline-payment-info .loyalty-reward-wrapper {
  width: 100%;
}
.inline-payment-info .payment-prices-wrapper .pagetitle,
.inline-payment-info .loyalty-reward-wrapper .pagetitle {
  margin: 0 !important;
}
.inline-payment-info .loyalty-reward-wrapper .pagetitle {
  text-transform: uppercase;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 23px !important;
  border: 0;
}
.inline-payment-info .loyalty-reward-wrapper .loyalty-reward-container {
  background: #4d4d4d;
  border-radius: 8px;
  padding: 20px 35px;
  color: #fff;
}

.confirm-button {
  display: none;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 7px 86px;
  border-style: none;
  background: #fab500;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  color: #000000;
}
@media screen and (max-width: 520px) {
  .confirm-button {
    display: block;
  }
}

.payment-capture-container .mobile-payment-capture-header {
  display: none;
}

@media screen and (max-width: 520px) {
  .payment-capture-container {
    display: none;
  }
  .payment-capture-container .mobile-payment-capture-header {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 16px;
    /* identical to box height, or 73% */
    color: #000000;
    margin-top: 20px;
  }
  .payment-capture-container .credit-card-form-container {
    padding-top: 0;
  }
}
@media screen and (max-width: 520px) and (max-width: 766px) {
  .payment-capture-container .credit-card-form-container {
    width: 100% !important;
  }
}
@media screen and (max-width: 520px) {
  .payment-capture-container .credit-card-form__wrapper {
    padding: 10px;
  }
  .payment-capture-container .credit-card-form__wrapper .credit-card-form__header {
    display: none;
  }
  .payment-capture-container .credit-card-form__wrapper .credit-card-form__fieldset-wrapper {
    padding-left: 0;
  }
}
.loyalty-reward-wrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 50% !important;
}

.loyalty-reward-wrapper-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: flex-start;
}

.loyalty-reward-wrapper-item,
.loyalty-reward-wrapper-item .ticket-price-container .loyaltyPrice,
.loyalty-reward-wrapper-item .ticket-price-container .currency-style {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #fff;
}

.loyalty-reward-new-balance .ticket-price-container .loyaltyPrice {
  font-weight: 600;
}

.loyalty-info {
  font-size: 12px;
  align-items: flex-start;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.loyalty-reward-value {
  font-size: 18px;
  line-height: 18px;
}
.loyalty-reward-value .loyalty-style {
  color: white !important;
  font-size: 10pt !important;
  padding-top: 2px;
  padding-top: 7px;
}

.loyalty-reward-new-balance, .loyalty-reward-balance {
  font-weight: 600;
}
.loyalty-reward-new-balance .loyalty-style, .loyalty-reward-balance .loyalty-style {
  color: white !important;
  font-size: 10pt !important;
  padding-top: 2px;
}

.loyalty-reward-wrapper-item-price {
  display: flex;
  align-items: center;
}
.connection-garantie-text {
  font-size: 16px;
  line-height: 27px;
  margin-top: 20px;
}
.door2-door-text {
  font-size: 16px;
  line-height: 27px;
  margin-top: 20px;
}

.door2-door-address {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  border-bottom: 1px solid #cdccc8;
  display: flex;
  padding: 10px 0;
  margin: 15px 0;
}
.payment-response-page {
  background-color: #f5f5f5;
}

.faux-breadcrumbs {
  background: #4d4d4d;
  padding-left: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  height: 45px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 414px) {
  .faux-breadcrumbs {
    background: #ebebeb;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000;
  }
}

.door2doorCointainer {
  width: 100%;
  padding: 20px 0;
}

.door2DoorInfo {
  position: relative;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  border: 1px solid #3c3835;
  border-radius: 6px;
  padding: 4px 80px;
  align-items: center;
}
@media (max-width: 600px) {
  .door2DoorInfo {
    padding: 0 3%;
  }
}

.thankYouTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  margin-top: 55px;
  justify-content: center;
}
@media screen and (max-width: 414px) {
  .thankYouTitle {
    margin-top: 20px;
    text-align: center;
  }
}

.reference,
.information {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}

.information {
  margin-top: 10px;
  margin-bottom: 20px;
}
@media screen and (max-width: 414px) {
  .information {
    display: block;
    text-align: center;
  }
}

.close-button-container {
  width: 50%;
  margin: 20px auto;
}
@media screen and (max-width: 750px) {
  .close-button-container {
    width: 100%;
  }
}

.close-button-container-loylaty {
  text-align: center;
  margin: 20px 0;
}
.close-button-container-loylaty a {
  text-decoration: none;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 6px;
  background: #fab500;
  color: #3c3835;
  width: 80%;
  transition: all 0.3s ease;
}
.close-button-container-loylaty a:hover {
  background: #3c3835;
  color: #fab500;
}

.payment-prices-wrapper {
  margin-top: 20px;
}

.inline-payment-info {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  grid-gap: 65px;
  gap: 65px;
  margin-top: 60px;
}
.inline-payment-info .payment-prices-wrapper,
.inline-payment-info .loyalty-reward-wrapper {
  width: 100%;
}
.inline-payment-info .payment-prices-wrapper .pagetitle,
.inline-payment-info .loyalty-reward-wrapper .pagetitle {
  margin: 0 !important;
}
.inline-payment-info .loyalty-reward-wrapper .pagetitle {
  text-transform: uppercase;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 23px !important;
  border: 0;
}
.inline-payment-info .loyalty-reward-wrapper .loyalty-reward-container {
  background: #4d4d4d;
  border-radius: 8px;
  padding: 20px 35px;
  color: #fff;
}

@media screen and (max-width: 414px) {
  .leg-headline {
    display: none;
  }
}

.leg-departure .segment-date::before {
  content: "Departure: ";
}

.leg-return .segment-date::before {
  content: "Return: ";
}
.pickup-details-page {
  background-color: #fff;
}

@media screen and (max-width: 1200px) {
  .pickup-details-page-container {
    padding: 0px 20px;
  }
}
.pickup-details-page-container {
  padding: 0px 20px;
}
.pickup-details-page-container .dropdown-container {
  display: flex;
}
.pickup-details-page-container .street-container {
  display: flex;
}
.pickup-details-page-container .street-container .street-number-wrapper {
  margin-right: 15px;
  flex: 1 1;
}
.pickup-details-page-container .street-container .street-address-wrapper {
  flex: 6 1;
}
.pickup-details-page-container .zip-state-container .zip-code-wrapper {
  margin-right: 15px;
  flex: 1 1;
}
.pickup-details-page-container .zip-state-container .state-wrapper {
  flex: 6 1;
}
.pickup-details-page-container .pickup-information-container {
  display: flex;
}
.pickup-details-page-container .pickup-information-container .first-wrapper {
  margin-right: 14px;
  flex: 3 1;
}
.pickup-details-page-container .pickup-information-container .second-wrapper {
  flex: 2 1;
}
.pickup-details-page-container .pickup-information-container .second-wrapper .rdt input {
  min-width: 140px;
  min-height: 40px;
  border: 1px solid #cdccc8;
  color: #3c3835;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 15px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  position: relative;
}
.pickup-details-page-container .button-wrapper {
  margin-bottom: 20px;
  width: 100%;
}
.pickup-details-page-container .datetime-container {
  border: none;
  position: relative;
  display: inline-flex;
  width: 100%;
  margin: 0 0 20px 0;
}
.pickup-details-page-container .datetime-container .datetime-label {
  position: absolute;
  top: -8px;
  text-transform: uppercase;
  left: 25px;
  padding: 0 10px;
  background-color: white;
  color: #3c3835;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
}
.pickup-details-page-container .datetime-container .rdt {
  width: 100%;
}
.pickup-details-page-container .datetime-container .rdt input {
  width: 100%;
}

@media only screen and (max-width: 420px) {
  .pickup-details-page-container {
    flex-direction: column;
  }
  .pickup-details-page-container .pickup-information-container {
    display: block;
  }
  .pickup-details-page-container .pickup-information-container .first-wrapper {
    margin-right: 0px;
  }
}
.pick-up-details-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.loyalty-program-wrapper {
  margin-bottom: 60px;
}

.loyalty-program-buttons {
  width: 50%;
  margin: 20px auto;
}
@media screen and (max-width: 750px) {
  .loyalty-program-buttons {
    width: 100%;
  }
}

.loyalty-program-wrapper .loyalty-program-buttons button svg {
  right: 10px;
}

.miles-warning {
  padding: 5px;
  border: 1px solid red;
}
.referal-page-wrapper {
  padding: 10px 20px 0 20px;
  background-color: #fff;
  height: 100%;
}
.referal-page-wrapper .collapsiblebutton-toogle-wrapper {
  padding: 0;
  align-items: center;
  height: 50px;
  padding-bottom: 10px;
}
.referal-page-wrapper .referal-page-button {
  width: 50%;
  margin: 20px auto;
}
@media screen and (max-width: 750px) {
  .referal-page-wrapper .referal-page-button {
    width: 100%;
  }
}
.referal-page-wrapper .referal-page-succesfull-referals {
  margin-top: 20px;
}
.reservation-depart-page-container {
  background-color: #f5f5f5;
  height: 100%;
}
.reservation-depart-page-container .edit-search-container {
  flex-direction: column;
}
@media screen and (max-width: 1112px) {
  .reservation-depart-page-container .edit-search-container {
    padding: 10px;
  }
}
.reservation-depart-page-container .edit-search-container .search-header {
  flex-grow: 1;
  width: 100%;
}
.reservation-depart-page-container .edit-search-container .search-container {
  width: 100%;
  flex: 1 1;
}
.reservation-depart-page-container .edit-search-container .search-container .Search__container {
  width: 100%;
}
.reservation-depart-page-container .edit-search-container .search-container .searchform-wrapper {
  background: #fff;
  padding: 20px 0;
  max-width: 800px;
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;
}
@media screen and (max-width: 1112px) {
  .reservation-depart-page-container .edit-search-container .search-container .searchform-wrapper {
    background: transparent;
  }
  .reservation-depart-page-container .edit-search-container .search-container .searchform-wrapper .fieldlabel-wrapper,
  .reservation-depart-page-container .edit-search-container .search-container .searchform-wrapper .labelwrapper {
    background-color: #f5f5f5;
  }
}
.reservation-depart-page-container .edit-search-container .search-container .Search--Form {
  position: static;
}
.reservation-depart-page-container .edit-search-container .mobile-search-button button {
  bottom: -36px;
}
.reservation-depart-page-container .suncountry-banner-container.sticky-edit-search-position {
  display: none;
}
.reservation-depart-page-container .reservation-section-wrapper {
  background-color: #f5f5f5;
  position: relative;
  top: -50px;
  height: auto;
}
.reservation-depart-page-container .itinerarySingleRow-collapsible-button-container {
  margin-top: 10px;
}
.reservation-depart-page-container .Section-container {
  position: -webkit-sticky;
  position: sticky;
  top: 85px;
  z-index: 999;
}
.reservation-depart-page-container .reservation-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 126px;
  z-index: 1000;
}
@media (max-width: 700px) {
  .reservation-depart-page-container .reservation-header-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 1199px) {
  .reservation-depart-page-container .reservation-header-container {
    top: 136px;
  }
}
@media screen and (max-width: 520px) {
  .reservation-depart-page-container .reservation-header-container {
    top: 96px;
    margin-bottom: 50px;
  }
}
.reservation-depart-page-container .search-filters-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 180px;
  z-index: 1;
}
.reservation-depart-page-container .suncountry-banner-container {
  display: none !important;
}
.reservation-depart-page-container .reservation-depart-wrapper {
  display: flex;
}
.reservation-depart-page-container .reservation-depart-wrapper .reservation-body {
  display: flex;
  flex-direction: column;
}
.reservation-depart-page-container .reservation-depart-wrapper .reservation-body .departure-date {
  margin-top: 40px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 400;
}
@media screen and (max-width: 1199px) {
  .reservation-depart-page-container .reservation-depart-wrapper .reservation-body {
    margin-top: 30px;
  }
}
.reservation-depart-page-container .reservation-depart-wrapper .itinerary-list-container {
  flex: 3 1;
  padding-top: 10px;
  background-color: #f5f5f5;
}
.reservation-depart-page-container .reservation-depart-wrapper .itinerary-list-container * {
  outline: none !important;
}
@media screen and (max-width: 1199px) {
  .reservation-depart-page-container .reservation-depart-wrapper .itinerary-list-container {
    padding: 0 10px;
    padding-top: 40px;
  }
}
.reservation-depart-page-container .reservation-depart-wrapper .filters-container {
  flex: 1 1;
  margin-left: 20px;
}
.reservation-depart-page-container .reservation-depart-wrapper .reservation-container {
  flex: 1 1;
}
.reservation-depart-page-container .reservation-depart-wrapper .filters-mobile-container {
  display: none;
  align-items: center;
  margin: 0px 20px;
  padding: 10px 0px;
  border-bottom: 1px solid #cdccc8;
}
.reservation-depart-page-container .reservation-depart-wrapper .filters-mobile-container .text {
  padding-left: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}
@media screen and (max-width: 800px) {
  .reservation-depart-page-container .reservation-depart-wrapper .filters-container {
    display: none;
  }
  .reservation-depart-page-container .reservation-depart-wrapper .filters-mobile-container {
    display: flex;
    justify-content: flex-end;
  }
  .reservation-depart-page-container .reservation-depart-wrapper .filters-mobile-container .filters-mobile-wrapper {
    cursor: pointer;
  }
}
.reservation-depart-page-container .segment-date {
  display: none;
}

@media screen and (max-width: 330px) {
  .itinerary-list-container {
    padding-top: 40px;
  }
}
@media screen and (max-width: 414px) {
  .reservation-depart-page-breadcrumbs {
    display: none;
  }
}
.reservation-return-page-container {
  background-color: #f5f5f5;
  height: 100%;
}
.reservation-return-page-container .edit-search-container {
  flex-direction: column;
}
.reservation-return-page-container .edit-search-container .search-header {
  flex-grow: 1;
  width: 100%;
}
.reservation-return-page-container .edit-search-container .search-container {
  width: 100%;
  flex: 1 1;
}
.reservation-return-page-container .edit-search-container .search-container .Search__container {
  width: 100%;
}
.reservation-return-page-container .edit-search-container .search-container .searchform-wrapper {
  background: #fff;
  padding: 20px 0;
  max-width: 800px;
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;
}
.reservation-return-page-container .edit-search-container .search-container .Search--Form {
  position: static;
}
.reservation-return-page-container .edit-search-container .mobile-search-button button {
  bottom: -36px;
}
.reservation-return-page-container .suncountry-banner-container.sticky-edit-search-position {
  display: none;
}
.reservation-return-page-container .ReactVirtualized__List {
  overflow: unset !important;
}
.reservation-return-page-container .ReactVirtualized__List .ReactVirtualized__Grid__innerScrollContainer {
  overflow: unset !important;
}
.reservation-return-page-container .reservation-section-wrapper {
  background-color: #f5f5f5;
  position: relative;
  top: -50px;
  height: auto;
}
.reservation-return-page-container .Section-container {
  position: -webkit-sticky;
  position: sticky;
  top: 85px;
  z-index: 999;
}
.reservation-return-page-container .reservation-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 126px;
  z-index: 1000;
}
@media (max-width: 700px) {
  .reservation-return-page-container .reservation-header-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 1199px) {
  .reservation-return-page-container .reservation-header-container {
    top: 136px;
  }
}
@media screen and (max-width: 520px) {
  .reservation-return-page-container .reservation-header-container {
    top: 96px;
    margin-bottom: 50px;
  }
}
.reservation-return-page-container .search-filters-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 180px;
  z-index: 1;
}
.reservation-return-page-container .suncountry-banner-container {
  top: 180px;
}
.reservation-return-page-container .reservation-return-wrapper {
  display: flex;
}
.reservation-return-page-container .reservation-return-wrapper .reservation-body {
  display: flex;
  flex-direction: column;
}
.reservation-return-page-container .reservation-return-wrapper .reservation-body .departure-date {
  margin-top: 40px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 400;
}
.reservation-return-page-container .reservation-return-wrapper .itinerary-list-container {
  background-color: #f5f5f5;
  flex: 3 1;
  padding-top: 10px;
}
.reservation-return-page-container .reservation-return-wrapper .itinerary-list-container * {
  outline: none !important;
}
@media screen and (max-width: 1199px) {
  .reservation-return-page-container .reservation-return-wrapper .itinerary-list-container {
    padding: 0 10px;
    padding-top: 10px;
  }
}
.reservation-return-page-container .reservation-return-wrapper .filters-container {
  flex: 1 1;
  margin-left: 20px;
}
.reservation-return-page-container .reservation-return-wrapper .reservation-container {
  flex: 1 1;
}
.reservation-return-page-container .reservation-return-wrapper .filters-mobile-container {
  display: none;
  align-items: center;
  margin: 0px 20px;
  padding: 10px 0px;
  border-bottom: 1px solid #cdccc8;
  cursor: pointer;
}
.reservation-return-page-container .reservation-return-wrapper .filters-mobile-container .text {
  padding-left: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}
@media screen and (max-width: 800px) {
  .reservation-return-page-container .filters-container {
    display: none;
  }
  .reservation-return-page-container .filters-mobile-container {
    display: flex !important;
    justify-content: flex-end;
  }
  .reservation-return-page-container .filters-mobile-container .filters-mobile-wrapper {
    cursor: pointer;
  }
}
.reservation-return-page-container .segment-date {
  display: none;
}

@media screen and (max-width: 330px) {
  .itinerary-list-container {
    padding-top: 40px;
  }
}
@media screen and (max-width: 414px) {
  .reservation-return-page-breadcrumbs {
    display: none;
  }
}
.reservation-summary-page-wrapper {
  background-color: #fff;
  height: 100%;
}
.reservation-summary-page-wrapper .edit-search-container {
  flex-direction: column;
}
.reservation-summary-page-wrapper .edit-search-container .search-header {
  flex-grow: 1;
  width: 100%;
}
.reservation-summary-page-wrapper .edit-search-container .search-container {
  width: 100%;
  flex: 1 1;
}
.reservation-summary-page-wrapper .edit-search-container .search-container .Search__container {
  width: 100%;
}
.reservation-summary-page-wrapper .edit-search-container .search-container .searchform-wrapper {
  background: #fff;
  padding: 20px 0;
  max-width: 800px;
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;
}
.reservation-summary-page-wrapper .edit-search-container .search-container .Search--Form {
  position: static;
}
.reservation-summary-page-wrapper .edit-search-container .mobile-search-button button {
  bottom: -36px;
}
.reservation-summary-page-wrapper .suncountry-banner-container.sticky-edit-search-position {
  display: none;
}
.reservation-summary-page-wrapper .reservation-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 126px;
  z-index: 1000;
}
@media (max-width: 700px) {
  .reservation-summary-page-wrapper .reservation-header-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 1199px) {
  .reservation-summary-page-wrapper .reservation-header-container {
    top: 136px;
  }
}
@media screen and (max-width: 520px) {
  .reservation-summary-page-wrapper .reservation-header-container {
    top: 96px;
    margin-bottom: 50px;
  }
}
.reservation-summary-page-wrapper .SectionWrapper {
  background-color: transparent;
}
.reservation-summary-page-wrapper .pagetitle {
  margin-top: 0;
  border-bottom: 0;
}
@media screen and (max-width: 414px) {
  .reservation-summary-page-wrapper .pagetitle {
    display: none;
  }
}
.reservation-summary-page-wrapper .reservation-section-wrapper {
  position: relative;
  top: -50px;
  height: auto;
}
.reservation-summary-page-wrapper .suncountry-banner-container {
  display: none !important;
}
.reservation-summary-page-wrapper .summary-page-total-price {
  background-color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 0px 0px 16px 16px;
  margin-bottom: 40px;
}
.reservation-summary-page-wrapper .summary-page-total-price .summary-price-total-text {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}
.reservation-summary-page-wrapper .summary-page-total-price .summary-price-total-passenger-count {
  color: #999;
}
.reservation-summary-page-wrapper .summary-page-total-price .defaultPrice {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 20px;
  text-align: right;
}
.reservation-summary-page-wrapper .summary-page-total-price .summary-page-total-price-right {
  min-width: 200px;
  margin-top: 20px;
}
.reservation-summary-page-wrapper .summary-page-total-price .summary-page-total-price-right .summary-page__continue button {
  background: #fab500;
  color: #000;
}
.reservation-summary-page-wrapper .summary-page-total-price .summary-page-total-price-right .ticket-price-container {
  margin-bottom: 25px;
  justify-content: flex-end;
}
.reservation-summary-page-wrapper .summary-page-total-price .summary-page-total-price-right .currency-style {
  font-weight: 400;
  font-size: 30px;
  line-height: 16px;
}
.reservation-summary-page-wrapper .summary-page-total-price .summary-page-total-price-right .defaultPrice {
  font-weight: 400;
  font-size: 30px;
  line-height: 16px;
  margin-bottom: 0;
}
.reservation-summary-page-wrapper .summary-page-total-price .summary-page-total-price-right .loyalty-style {
  display: flex;
  font-size: 16px;
  line-height: 8px;
  align-items: flex-end;
}
.reservation-summary-page-wrapper .legconnection-container .date-wrapper {
  display: none;
}

@media screen and (max-width: 414px) {
  .reservation-summary-page-breadcrumbs {
    display: none;
  }
}
.departure-ticket .segment-date::before {
  content: "Departure: ";
}

.return-ticket .segment-date::before {
  content: "Return: ";
}

.summary-page-total-price-mobile {
  display: none;
}

@media screen and (max-width: 414px) {
  .summary-page-total-price {
    display: none !important;
  }
  .summary-page-total-price-mobile {
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    flex-direction: column;
  }
  .summary-page-total-price-mobile .summary-page__continue {
    max-width: 220px;
    align-self: center;
  }
  .summary-page-total-price-mobile button {
    background: #fab500;
    color: #000;
  }
  .summary-page-total-price-mobile-container {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    align-self: flex-end;
  }
  .summary-page-total-price-mobile-container .ticket-price-container .currency-style,
  .summary-page-total-price-mobile-container .ticket-price-container .defaultPrice {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 24px;
  }
}
.seat-page-breadcrumbs .breadcrumbs-container {
  max-height: 45px !important;
}
@media screen and (max-width: 520px) {
  .seat-page-breadcrumbs .breadcrumbs-container {
    display: none !important;
  }
}

@media screen and (max-width: 520px) {
  .breadcrumbs-wrapper {
    display: none !important;
  }
}
.seat-page-container {
  background-color: #fff;
}
.seat-page-container .pagetitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 22px !important;
  line-height: 16px;
  border-bottom: 0;
  color: #000000;
}
@media screen and (max-width: 414px) {
  .seat-page-container .pagetitle {
    display: none !important;
  }
}
@media screen and (max-width: 414px) {
  .seat-page-container {
    padding-top: 20px;
  }
}

.passenger-seats-depart-container .segment-date::before {
  content: "Departure: ";
}

.Seat--navigation-button {
  border-radius: 6px;
}
.Seat--navigation-button:hover {
  color: #3c3835;
  background-color: #fab500;
}

.seats-buttons {
  max-width: 290px;
  width: 50%;
  margin: 20px auto;
}
.seats-buttons button {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}
@media screen and (max-width: 750px) {
  .seats-buttons {
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 414px) {
  .ItinerarySingleRow_price-per-pax-container {
    display: none;
  }
}
.SignUp--top {
  background-color: #fff;
}

.singup-inputfield-error, .singup-inputfield-pwrequirements {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  color: #ff4040;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

.singup-inputfield-pwrequirements {
  color: #696d6f;
}

.subscription-checkbox-wrapper {
  margin: 6px 0px 16px 0px;
}
@media (min-width: 1200px) {
  .schedule-page {
    margin-top: 50px;
  }
  .charters-page-quote {
    background-color: #FFB404;
    border-radius: 10px;
    font-weight: 600;
    font-size: 22px;
    margin: 0% 40%;
    border: none;
    padding: 10px;
  }
  .penguin-perks-action {
    width: 100%;
    text-align: center;
  }
  .penguin-perks-signup {
    background-color: #FFB404;
    border-radius: 10px;
    font-weight: 600;
    font-size: 22px;
    border: none;
    padding: 10px;
  }
  .penguin-perks-panel {
    display: flex;
    padding: 20px 20px;
  }
  .how-it-works-element h4 {
    text-align: center;
    font-size: 20px;
  }
  .how-it-works-element p {
    margin: 0% 10%;
    line-height: 20px;
    text-align: center;
  }
  .how-it-works-element img {
    width: 100px;
  }
  .how-it-works-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
  }
  .rider-tiers-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
  }
  .rider-tiers-element img {
    width: 200px;
  }
  .rider-tiers-element h4 {
    padding-bottom: 20px;
    font-size: 22px;
    width: 65%;
    margin: auto;
    text-align: center;
  }
  .rider-tiers-element ul {
    margin-left: 20px;
    width: 65%;
    margin: auto;
  }
  .rider-tiers-element li {
    margin-top: 0px;
    line-height: 1.25rem;
  }
  .penguin-faq-panel {
    padding: 20px;
    font-size: 1.1em;
  }
}
@media (max-width: 1200px) {
  .schedule-page {
    margin-top: 20px;
  }
  .charters-page-quote {
    background-color: #FFB404;
    border-radius: 10px;
    font-weight: 600;
    font-size: 22px;
    margin-left: 27.5%;
    border: none;
    padding: 10px;
  }
  .penguin-perks-action {
    width: 100%;
    text-align: center;
  }
  .penguin-perks-signup {
    background-color: #FFB404;
    border-radius: 10px;
    font-weight: 600;
    font-size: 22px;
    border: none;
    padding: 10px;
  }
  .penguin-perks-panel {
    display: flex;
    padding: 20px 20px;
    flex-direction: column;
  }
  .how-it-works-element h4 {
    text-align: center;
    font-size: 20px;
  }
  .how-it-works-element p {
    margin: 0% 10%;
    line-height: 20px;
    text-align: center;
  }
  .how-it-works-element img {
    width: 100px;
  }
  .how-it-works-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .rider-tiers-element {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .rider-tiers-element img {
    width: 180px;
  }
  .rider-tiers-element h4 {
    padding-bottom: 20px;
    font-size: 22px;
    width: 65%;
    margin: auto;
    text-align: center;
  }
  .rider-tiers-element ul {
    margin-left: 20px;
    width: 65%;
    margin: auto;
  }
  .rider-tiers-element li {
    margin-top: 0px;
    line-height: 1.25rem;
  }
  .penguin-faq-panel {
    padding: 20px;
    font-size: 1.1em;
  }
}
@media (max-width: 1199px) {
  .schedule-page {
    width: 100%;
    margin: auto;
  }
  .schedule-page-title {
    border: none;
    text-align: center;
    margin: auto;
    font-weight: 600;
    font-size: 2em;
    line-height: 2em;
    margin: 10px;
  }
  .schedule-page-subtitle {
    text-align: center;
    font-size: 1.3em;
    margin: 10px;
  }
  .schedule-page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  .schedule-page-header-information {
    text-align: center;
    font-size: 1.2em;
    margin: 10px;
  }
  .special-notice {
    margin: auto;
    font-size: 1.2em;
    text-align: center;
    color: red;
    width: 90%;
    border: 1px dotted red;
    padding: 5px;
  }
  .schedule-search-form {
    width: 100%;
  }
  .schedule-module-title-container {
    display: flex;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 75px;
  }
  .schedule-module-title {
    padding: 0px 20px;
    font-size: 1.5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .schedule-module-title-ruler {
    background-color: orange;
    height: 4px;
    display: flex;
    flex: 1 1;
    margin-right: 20px;
  }
  .schedule-pickup-location {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .schedule-pickup-location-image {
    width: 100%;
    border-radius: 40px;
    padding: 20px;
  }
  .schedule-pickup-location-panel {
    padding: 20px;
  }
  .schedule-pickup-location-panel h5 {
    font-size: 1.8em;
    margin-bottom: 10px;
  }
  .schedule-pickup-location-panel a {
    font-size: 1.15em;
    color: #006fbf;
    font-weight: bold;
  }
  .schedule-pickup-location-panel p {
    line-height: 1.4em;
    font-size: 1.3em;
    margin-top: 10px;
    margin-right: 10px;
  }
  .schedule-location {
    display: flex;
    align-items: center;
    margin: 0 auto 30px auto;
    flex-direction: column;
    padding: 20px 40px;
    max-width: 500px;
  }
  .schedule-location picture,
  .schedule-location img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  .schedule-location img {
    border-radius: 10px;
    max-width: 440px;
  }
  .schedule-location-panel {
    text-align: center;
    margin-top: 10px;
  }
  .schedule-location-panel p {
    text-align: left;
    font-size: 1.2em;
    line-height: 1.4em;
  }
  .schedule-location-panel button {
    margin-top: 30px;
    background-color: #fab500;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-size: 1.1em;
    color: black;
  }
  .schedule-book-now {
    margin-top: 20px;
    background-color: #fab500;
    width: 80%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-size: 1.1em;
    color: black;
  }
  .schedule-page-schedules-container {
    background-color: #ebebeb;
    border-radius: 10px;
    width: 100%;
    padding: 30px 30px 15px 30px;
    margin-bottom: 30px;
    text-align: center;
  }
  .schedule-page-schedules-inner-container {
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 30px;
    row-gap: 30px;
    width: 100%;
  }
  .schedules-page-schedules-disclaimer {
    margin-top: 15px;
    margin-bottom: 0px;
    font-size: 0.85em;
    line-height: 1.3em;
    text-align: center;
  }
  .schedule-table {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-collapse: collapse;
  }
  .schedule-table-title {
    font-size: 1.4em;
    padding-bottom: 10px;
  }
  .schedule-table-header {
    background-color: #333;
    border: 1px thin #ebebeb;
    color: white;
    width: auto;
  }
  .stop-type {
    margin-bottom: -5px;
    margin-top: 5px;
  }
  .stop-name {
    font-size: 1.4em;
    margin-bottom: 5px;
    margin-top: 0px;
  }
  .schedule-itinerary-item {
    background-color: #fff;
  }
  .schedule-itinerary-item td {
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    border: 1px solid #fff;
  }
  .gray-background {
    background-color: #ebebeb;
  }
  .schedule-service-container {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 30px;
    padding: 0px 20px;
  }
  .schedule-service-text {
    padding: 0px;
    margin-top: 20px;
  }
  .schedule-service-text p {
    margin-bottom: 10px;
    line-height: 1.4em;
    font-size: 1.3em;
  }
  .schedule-service-container img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  .visiting-fort-collins {
    padding: 0px 20px;
  }
  .visiting-fort-collins img {
    width: 100%;
  }
  .visiting-fort-collins h4 {
    font-size: 1.6em;
    margin-bottom: 10px;
  }
  .visiting-fort-collins b {
    display: block;
    line-height: 1.4em;
    font-size: 1.3em;
    margin-top: 10px;
  }
  .visiting-fort-collins p {
    margin-right: 10%;
    line-height: 1.4em;
    font-size: 1.3em;
  }
  .schedule-faq {
    width: 100%;
    margin: auto;
    padding: 40px 20px 40px 20px;
  }
  .schedule-faq-box {
    background-color: #fab500;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
  }
  .schedule-faq-box h4 {
    color: white;
    font-size: 1.5em;
  }
  .schedule-faq-box a {
    color: white;
    text-decoration: none;
  }
  .schedule-faq-box p {
    font-weight: 500;
    margin-top: 5px;
    font-size: 1.2em;
  }
  .schedule-page-location-service {
    padding: 20px;
    margin: auto;
  }
  .schedule-page-location-service img {
    width: 100%;
  }
  .schedule-page-location-service-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .schedule-page-location-service-panel div {
    width: 100%;
  }
  .schedule-page-location-service-panel h4 {
    font-size: 1.5em;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .schedule-page-location-service-panel p {
    font-size: 1.3em;
    margin-bottom: 10px;
    line-height: 1.5em;
  }
  .schedule-page-parking {
    padding: 20px;
  }
  .schedule-page-parking img {
    width: 100%;
    border-radius: 10px;
    margin-right: 3%;
  }
  .schedule-page-parking-panel {
    width: 100%;
    margin-top: 20px;
  }
  .schedule-page-parking h4 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  .schedule-page-parking p {
    font-size: 1.3em;
    margin-bottom: 10px;
    line-height: 1.5em;
  }
  .full-width-heading.schedule-page-banner {
    margin-bottom: 0;
    padding: 1rem;
    min-height: 170px;
  }
  .full-width-heading.schedule-page-banner h1 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
  .full-width-heading.schedule-page-banner span {
    color: white;
    font-family: "Source Sans 3", sans-serif !important;
    font-size: 1rem;
    z-index: 2;
    position: relative;
  }
  .schedule-page-search-bg {
    background-color: white;
  }
}
@media (min-width: 1200px) {
  .schedule-page {
    width: 1100px;
    margin: auto;
  }
  .schedule-page-title {
    border: none;
    text-align: center;
    margin: auto;
    font-weight: 600;
    font-size: 2.5em;
    margin-bottom: 40px;
  }
  .schedule-page-subtitle {
    text-align: center;
    font-size: 1.6em;
    margin-bottom: 20px;
  }
  .schedule-page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  .schedule-page-header-information {
    text-align: center;
    font-size: 1.2em;
    margin: 10px;
    white-space: pre;
  }
  .special-notice {
    margin: auto;
    font-size: 1.2em;
    text-align: center;
    color: red;
  }
  .schedule-search-form {
    width: 100%;
  }
  .schedule-module-title-container {
    display: flex;
    align-items: center;
    margin: auto;
    width: 1100px;
    height: 75px;
  }
  .schedule-module-title {
    padding: 0px 20px;
    font-size: 2em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .schedule-module-title-ruler {
    background-color: #fab500;
    height: 4px;
    display: flex;
    flex: 1 1;
    margin-right: 20px;
  }
  .schedule-pickup-location {
    display: flex;
    margin-top: 20px;
  }
  .schedule-pickup-location-image {
    width: 450px;
    border-radius: 40px;
    padding: 20px;
  }
  .schedule-pickup-location-panel {
    padding-top: 30px;
    padding-left: 10px;
  }
  .schedule-pickup-location-panel h5 {
    font-size: 1.8em;
    margin-bottom: 10px;
  }
  .schedule-pickup-location-panel a {
    font-size: 1.15em;
    color: #006fbf;
    font-weight: bold;
  }
  .schedule-pickup-location-panel p {
    font-size: 1.05em;
    line-height: 1.4em;
    margin-top: 10px;
    margin-right: 50px;
  }
  .schedule-location {
    display: flex;
    align-items: center;
    margin: 0 auto 30px auto;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 40px;
    max-width: 1200px;
  }
  .schedule-location picture,
  .schedule-location img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    max-width: 370px;
  }
  .schedule-location img {
    border-radius: 10px;
    width: 100%;
  }
  .schedule-location-panel {
    padding: 30px 30px;
    text-align: center;
  }
  .schedule-location-panel p {
    text-align: left;
    font-size: 1.1em;
    line-height: 1.5em;
    margin-bottom: 10px;
  }
  .schedule-location-panel button {
    margin-top: 30px;
    background-color: #fab500;
    width: 70%;
    border: none;
    border-radius: 10px;
    height: 45px;
    font-size: 1.1em;
  }
  .schedule-book-now {
    background-color: #fab500;
    width: 30%;
    border: none;
    border-radius: 10px;
    height: 45px;
    font-size: 1.1em;
    margin-top: 10px;
  }
  .schedule-page-schedules-container {
    background-color: #ebebeb;
    border-radius: 10px;
    width: 100%;
    padding: 30px 30px 15px 30px;
    margin-bottom: 30px;
    text-align: center;
  }
  .schedule-page-schedules-inner-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
    width: 100%;
  }
  .schedules-page-schedules-disclaimer {
    margin-top: 15px;
    margin-bottom: 0px;
    font-size: 0.8em;
    text-align: center;
  }
  .schedule-table {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-collapse: collapse;
  }
  .schedule-table-title {
    font-size: 1.4em;
    padding-bottom: 10px;
  }
  .schedule-table-header {
    background-color: #333;
    border: 1px thin #ebebeb;
    color: white;
    width: auto;
  }
  .stop-type {
    margin-bottom: -5px;
    margin-top: 5px;
  }
  .stop-name {
    font-size: 1.4em;
    margin-bottom: 5px;
    margin-top: 0px;
  }
  .schedule-itinerary-item {
    background-color: #fff;
  }
  .schedule-itinerary-item td {
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    border: 1px solid #fff;
    padding: 3px;
  }
  .gray-background {
    background-color: #ebebeb;
  }
  .schedule-service-container {
    display: flex;
    margin-bottom: 30px;
    padding: 0px 20px;
  }
  .schedule-service-text {
    width: 60%;
    padding-right: 20px;
  }
  .schedule-service-text p {
    margin-bottom: 10px;
    line-height: 1.5em;
    font-size: 1.1em;
  }
  .schedule-service-container img {
    width: 40%;
    height: auto;
    border-radius: 10px;
    max-height: 300px;
  }
  .visiting-fort-collins {
    padding: 0px 20px;
  }
  .visiting-fort-collins img {
    width: 65%;
  }
  .visiting-fort-collins h4 {
    font-size: 1.4em;
    margin-bottom: 10px;
  }
  .visiting-fort-collins b {
    display: block;
  }
  .visiting-fort-collins p {
    margin-right: 10%;
  }
  .schedule-faq {
    width: 1100px;
    margin: auto;
    padding: 40px 20px 40px 20px;
  }
  .schedule-faq-box {
    background-color: #fab500;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
  }
  .schedule-faq-box h4 {
    color: white;
    font-size: 1.5em;
  }
  .schedule-faq-box a {
    color: white;
    text-decoration: none;
  }
  .schedule-faq-box p {
    font-weight: 500;
    margin-top: 5px;
    font-size: 1.2em;
  }
  .schedule-page-location-service {
    padding: 20px;
    display: flex;
    margin: auto;
    width: 100%;
  }
  .schedule-page-location-service img {
    width: 40%;
    margin-right: 5%;
    height: auto;
    border-radius: 20px;
    max-height: 300px;
  }
  .schedule-page-location-service-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .schedule-page-location-service-panel div {
    width: 90%;
  }
  .schedule-page-location-service-panel h4 {
    font-size: 1.3em;
    margin-bottom: 10px;
  }
  .schedule-page-location-service-panel p {
    font-size: 1.1em;
    margin-bottom: 10px;
    line-height: 1.5em;
  }
  .schedule-page-parking {
    display: flex;
    padding: 20px;
  }
  .schedule-page-parking img {
    width: 45%;
    border-radius: 10px;
    margin-right: 3%;
  }
  .schedule-page-parking-panel {
    width: 45%;
  }
  .schedule-page-parking h4 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  .schedule-page-parking p {
    font-size: 1.15em;
    margin-bottom: 10px;
    line-height: 1.5em;
  }
  .full-width-heading.schedule-page-banner {
    margin-bottom: 0;
  }
  .full-width-heading.schedule-page-banner span {
    color: white;
    font-family: "Source Sans 3", sans-serif !important;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    z-index: 2;
    position: relative;
  }
  .schedule-page-search-bg {
    background-color: white;
  }
}

.icon1-grid.fort-collis-icon-section {
  grid-template-columns: repeat(5, 1fr);
}
@media (max-width: 768px) {
  .icon1-grid.fort-collis-icon-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

.downtimebanner {
  display: inline-block; /* or block, depending on your layout needs */
  padding: 8px 12px; /* add some space around the text */
  background-color: #6b0000; /* example background color */
  color: #a94442; /* example text color (contrasts the background) */
  font-weight: bold; /* makes the text stand out */
  text-decoration: none; /* remove underline if the span is acting like a link */
  border: 1px solid #ebcccc; /* subtle border, if desired */
  border-radius: 4px; /* slightly rounded corners */
  text-transform: uppercase; /* optional: make the text all uppercase */
  text-align: center; /* centers the text within the span */
}
.icon1-grid.csu-icon-section {
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 768px) {
  .icon1-grid.csu-icon-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

.otherlocations-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.otherlocations-container .otherlocations {
  display: inline-block;
  padding: 8px 16px;
  background-color: #f8f8f8;
  color: #333;
  text-decoration: none;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.otherlocations-container .otherlocations:hover {
  background-color: #f0f0f0;
}
.week-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  margin: 20px 0;
}
.week-selector .nav-button {
  padding: 8px;
  border: none;
  background: none;
  border-radius: 50%;
  cursor: pointer;
  color: #333;
  transition: background-color 0.2s;
}
.week-selector .nav-button:hover:not(:disabled) {
  background-color: rgba(0, 0, 0, 0.05);
}
.week-selector .nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.week-selector .week-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  margin: 0 16px;
}
.week-selector .week-display .week-date {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}
.week-selector .week-display .week-count {
  font-size: 14px;
  color: #666;
  margin-top: 4px;
}
.locations-container {
  width: 1100px;
  margin: auto;
}

.locations-container-title {
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 28px;
}

.locations-container .schedule-location {
  background-color: #f0f0f0;
  padding: 30px;
  border-radius: 10px;
}

.schedule-location-panel {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 275px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.schedule-location-panel h4 {
  text-align: left;
  font-size: 24px;
  padding-bottom: 20px;
}

.schedule-location-panel button {
  height: 50px !important;
  padding: 5px;
}
@media (min-width: 600px) {
  .schedule-location-panel button {
    height: 40px !important;
  }
}

@media (max-width: 1199px) {
  .locations-container {
    width: 100%;
  }
  .locations-container-title {
    padding: 20px;
    text-align: center;
    font-size: 24px;
  }
  .schedule-location-panel {
    height: auto;
  }
}
.schedule-reserve-faq {
  padding: 0 20px;
}

.schedule-location-panel p {
  font-size: 0.95em !important;
}
@media (min-width: 1200px) {
  .schedule-page {
    margin-top: 50px;
  }
  .charters-page-module {
    display: flex;
    padding: 20px 40px;
    margin-bottom: 30px;
  }
  .charters-page-module img {
    width: 400px;
    min-width: 400px;
    border-radius: 10px;
    max-height: 275px;
  }
  .charters-page-module-panel {
    padding: 10px 20px;
  }
  .charters-page-module-panel h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .charters-page-module-panel p {
    text-align: left;
    font-size: 1.05em;
    line-height: 1.3em;
    margin-bottom: 10px;
  }
  .charters-page-main-bullets {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    padding-left: 20px;
  }
  .charters-page-main-bullets li {
    line-height: 1em;
  }
  .charter-page-testimonials {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
  }
  .charter-page-testimonials img {
    width: 30%;
    border-radius: 10px;
  }
  .charter-service-main-headline {
    padding: 0px 40px;
    line-height: 1.2em;
  }
  .charters-page-quote {
    background-color: #FFB404;
    border-radius: 10px;
    font-weight: 600;
    font-size: 22px;
    margin: 0% 40%;
    border: none;
    padding: 10px;
  }
}
@media (max-width: 1200px) {
  .schedule-page {
    margin-top: 20px;
  }
  .charters-page-module {
    padding: 20px;
    margin-bottom: 30px;
  }
  .charters-page-module img {
    width: 100%;
    border-radius: 10px;
    max-height: 275px;
  }
  .charters-page-module-panel {
    padding: 10px 20px;
  }
  .charters-page-module-panel h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .charters-page-module-panel p {
    text-align: left;
    font-size: 1.05em;
    line-height: 1.3em;
    margin-bottom: 10px;
  }
  .charters-page-main-bullets {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    margin-left: 20px;
  }
  .charters-page-main-bullets li {
    line-height: 1em;
  }
  .charter-page-testimonials {
    padding: 0px 20px;
  }
  .charter-page-testimonials img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .charter-service-main-headline {
    padding: 0px 20px;
    line-height: 1.2em;
  }
  .charters-page-quote {
    background-color: #FFB404;
    border-radius: 10px;
    font-weight: 600;
    font-size: 22px;
    margin-left: 27.5%;
    border: none;
    padding: 10px;
  }
}
.faq-page {
  margin: auto;
  font-family: Source Sans Pro, sans-serif;
}

.faq-question {
  background-color: #f2f2f2;
}

@media (min-width: 1200px) {
  .faq-page {
    width: 1000px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .faq-header h2 {
    font-size: 35px;
    line-height: 1.2;
  }
  .faq-header h5 {
    color: #ff9800;
    font-size: 22px;
  }
  .faq-section {
    margin-top: 50px;
  }
  .faq-section h3 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .faq-back {
    color: #fab500;
    text-decoration: none;
    font-size: 20px;
  }
  .faq-question {
    padding: 20px;
    margin-top: 30px;
  }
  .faq-question h4 {
    font-size: 27px;
  }
  .faq-question > div {
    margin-top: 20px;
  }
  .faq-question > p {
    margin-top: 20px;
  }
  .faq-question > div > p {
    margin-top: 20px;
  }
  .faq-question p {
    line-height: 1.45;
    font-weight: 400;
    font-size: 20px;
    color: #505050;
  }
  .faq-question a {
    color: #fab500;
  }
  .faq-question > div > ul {
    margin-left: 40px;
  }
  .faq-question > div > ul > li {
    font-size: 18px;
    line-height: 18px;
    height: 25px;
    color: #505050;
  }
}
@media (max-width: 1200px) {
  .faq-page {
    padding: 20px;
  }
  .faq-header h2 {
    font-size: 35px;
    line-height: 1.2;
  }
  .faq-header h5 {
    color: #ff9800;
    font-size: 22px;
  }
  .faq-section {
    margin-top: 50px;
  }
  .faq-section h3 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .faq-back {
    color: #fab500;
    text-decoration: none;
    font-size: 20px;
  }
  .faq-question {
    padding: 20px;
    margin-top: 30px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .faq-question h4 {
    font-size: 27px;
  }
  .faq-question > div {
    margin-top: 20px;
  }
  .faq-question > p {
    margin-top: 20px;
  }
  .faq-question > div > p {
    margin-top: 20px;
  }
  .faq-question p {
    line-height: 1.45;
    font-weight: 400;
    font-size: 20px;
    color: #505050;
  }
  .faq-question a {
    color: #fab500;
  }
  .faq-question > div > ul {
    margin-left: 40px;
  }
  .faq-question > div > ul > li {
    font-size: 18px;
    line-height: 18px;
    height: 25px;
    color: #505050;
  }
}
.about-us-container {
  margin: auto;
}

.about-us-headline h3 {
  color: #333;
  font-weight: 700;
}

.about-us-headline h4 {
  font-weight: 600;
  color: #333;
}

.about-us-benefits {
  display: flex;
  margin-bottom: 40px;
}

.benefit-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.benefit-item h4 {
  border-bottom: 1px solid #CCC;
  width: 80%;
  text-align: center;
  margin-bottom: 10px;
}

.about-us-carriers {
  margin: auto;
}

.carrier-item {
  margin: auto;
}

@media (min-width: 1200px) {
  .about-us-container {
    width: 1000px;
    padding: 30px 0px;
  }
  .about-us-headline h3 {
    font-size: 48px;
  }
  .about-us-headline h4 {
    font-size: 26px;
    line-height: 38px;
    margin-top: 30px;
  }
  .benefit-item {
    width: 25%;
    min-width: 25%;
  }
  .benefit-item h4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }
  .benefit-item p {
    text-align: center;
    font-size: 16px;
    padding: 14px 20px;
    color: #3c3835;
    line-height: 22px;
  }
  .carrier-item h4 {
    color: #3C3835;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .carrier-item ol {
    margin-left: 50px;
  }
  .carrier-item li {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
  .carrier-action {
    margin: auto;
    padding: 30px;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .carrier-action a {
    border-radius: 10px;
    padding: 15px 40px;
    color: #3C3835;
    background-color: #fab500;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }
}
@media (max-width: 1200px) {
  .about-us-container {
    width: 90%;
  }
  .about-us-benefits {
    flex-direction: column;
  }
  .about-us-headline {
    margin-top: 30px;
  }
  .about-us-headline h3 {
    font-size: 30px;
  }
  .about-us-headline h4 {
    font-size: 20px;
    line-height: 24px;
    margin: 16px 0 40px;
  }
  .benefit-item {
    margin: auto;
  }
  .benefit-item h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }
  .benefit-item p {
    font-size: 16px;
    line-height: 24px;
    padding: 14px 20px;
    text-align: center;
  }
  .about-us-carriers {
    width: 90dvw;
    margin: auto;
    padding-bottom: 10dvw;
  }
  .about-us-carriers h4 {
    color: #3C3835;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .about-us-carriers ol {
    margin-left: 20px;
  }
  .about-us-carriers ol li {
    font-size: 16px;
    color: #3C3835;
    font-weight: 400;
    line-height: 28px;
    margin-left: 10px;
  }
  .carrier-action {
    margin: auto;
    padding: 30px;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .carrier-action a {
    border-radius: 10px;
    padding: 10px 0px;
    width: 100%;
    color: #3C3835;
    background-color: #fab500;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }
}
.ACHeader_header__15lcg .ACHeader_content__1ubWl {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  width: 88.88%;
  margin: 0 auto;
}
@media screen and (max-width: 650px) {
  .ACHeader_header__15lcg .ACHeader_content__1ubWl {
    width: 86.667%;
    padding: 24px 0;
  }
}
.ACHeader_header__15lcg .ACHeader_content__1ubWl .ACHeader_logoWrapper__FCEs0 {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 650px) {
  .ACHeader_header__15lcg .ACHeader_content__1ubWl .ACHeader_logoWrapper__FCEs0 img {
    width: 120px;
  }
}
@media (orientation: landscape) {
  .ACHeader_header__15lcg .ACHeader_content__1ubWl .ACHeader_logoWrapper__FCEs0 .ACHeader_AirCanadaLogo__2Y_Fw {
    height: 30px;
    margin-right: 40px;
  }
}
@media (orientation: portrait) {
  .ACHeader_header__15lcg .ACHeader_content__1ubWl .ACHeader_logoWrapper__FCEs0 .ACHeader_AirCanadaLogo__2Y_Fw {
    height: 22px;
    width: 150px;
    margin-right: 30px;
  }
}
.ACHeader_header__15lcg .ACHeader_content__1ubWl .ACHeader_rightContent__WFknB {
  display: flex;
  align-items: center;
}
.ACHeader_header__15lcg .ACHeader_content__1ubWl .ACHeader_rightContent__WFknB .ACHeader_callUs__mIe-g {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 0;
}
.ACHeader_header__15lcg .ACHeader_content__1ubWl .ACHeader_rightContent__WFknB .ACHeader_callUs__mIe-g:hover {
  cursor: pointer;
}
@media screen and (max-width: 650px) {
  .ACHeader_header__15lcg .ACHeader_content__1ubWl .ACHeader_rightContent__WFknB .ACHeader_callUs__mIe-g {
    font-size: 16px;
    line-height: 24px;
  }
}
.ACHeader_header__15lcg .ACHeader_content__1ubWl .ACHeader_rightContent__WFknB .ACHeader_phoneNumber__zu4eU {
  display: flex;
  align-items: center;
}
.ACHeader_header__15lcg .ACHeader_content__1ubWl .ACHeader_rightContent__WFknB .ACHeader_phoneNumber__zu4eU a {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #999999;
  margin-left: 24px;
}
@media screen and (max-width: 650px) {
  .ACHeader_header__15lcg .ACHeader_content__1ubWl .ACHeader_rightContent__WFknB .ACHeader_phoneNumber__zu4eU a {
    font-size: 16px;
    line-height: 24px;
  }
}
.Footer_footer__2hxfe {
  background: #f5f5f5;
  padding: 65px 0 64px 0;
  border-top: 1px solid #cdccc8;
}
@media screen and (max-width: 726px) {
  .Footer_footer__2hxfe {
    padding: 0;
  }
}
.Footer_footer__content__2A8WY {
  width: 88.8889%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 726px) {
  .Footer_footer__content__2A8WY {
    flex-direction: column;
    margin-top: 64px;
    padding-bottom: 64px;
  }
}
@media screen and (max-width: 726px) {
  .Footer_footer__content__2A8WY .Footer_leftSide__2gNGT .Footer_logo__3Y77Y img {
    max-width: 110px;
  }
}
.Footer_footer__content__2A8WY .Footer_rowOne__1_Yam {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 726px) {
  .Footer_footer__content__2A8WY .Footer_rowOne__1_Yam {
    justify-content: center;
    margin-top: 44.02px;
  }
}
.Footer_footer__content__2A8WY .Footer_rowOne__1_Yam .Footer_socialMediaLinks__28-nA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
}
.Footer_footer__content__2A8WY .Footer_rowOne__1_Yam .Footer_socialMediaLinks__28-nA .Footer_mediaLink__3e1U3 {
  cursor: pointer;
}
@media screen and (max-width: 726px) {
  .Footer_footer__content__2A8WY .Footer_rowOne__1_Yam .Footer_socialMediaLinks__28-nA .Footer_mediaLink__3e1U3 svg {
    width: 18px;
    height: 18px;
  }
}
.Footer_footer__content__2A8WY .Footer_rowTwo__6AptY {
  margin-top: 9px;
}
@media screen and (max-width: 726px) {
  .Footer_footer__content__2A8WY .Footer_rowTwo__6AptY {
    text-align: center;
    margin: 41px auto 0 auto;
    width: 206px;
  }
}
.Footer_footer__content__2A8WY .Footer_rowTwo__6AptY .Footer_legal__2eUDF p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #3c3835;
}
@media screen and (max-width: 726px) {
  .Footer_footer__content__2A8WY .Footer_rowTwo__6AptY .Footer_legal__2eUDF p {
    text-align: center;
  }
}
.Footer_footer__content__2A8WY .Footer_linksWrapper__3ouac {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 726px) {
  .Footer_footer__content__2A8WY .Footer_linksWrapper__3ouac {
    width: 256px;
    margin-top: 40px;
  }
}
.Footer_footer__content__2A8WY .Footer_linksWrapper__3ouac .Footer_linkContent__2PiGQ {
  color: #fab500;
}
@media screen and (max-width: 726px) {
  .Footer_footer__content__2A8WY .Footer_linksWrapper__3ouac .Footer_linkContent__2PiGQ {
    text-align: center;
  }
}
.Footer_footer__content__2A8WY .Footer_linksWrapper__3ouac .Footer_linkContent__2PiGQ a {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fab500;
  cursor: pointer;
}
.ACNavigation_AirCanadaNavigation__2f7Sv {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid black;
  border-left: 1px solid black;
}
@media only screen and (orientation: landscape) {
  .ACNavigation_AirCanadaNavigation__2f7Sv {
    grid-template-columns: 25% 25% 25% 25%;
    height: 8dvh;
  }
}
@media only screen and (orientation: portrait) {
  .ACNavigation_AirCanadaNavigation__2f7Sv {
    grid-template-columns: 50% 50%;
    height: 10dvh;
  }
}

.ACNavigation_AirCanadaNavigationItem__3WFL6 {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  background-color: #f01428;
  color: white;
}
.ACNavigation_AirCanadaNavigationItem__3WFL6 div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ACNavigation_AirCanadaNavigationItem__3WFL6 div:hover {
  background-color: #fd5765;
}
@media only screen and (orientation: landscape) {
  .ACNavigation_AirCanadaNavigationItem__3WFL6 {
    width: 25dvw;
    flex: 25% 1;
    font-size: 24px;
  }
}
@media only screen and (orientation: portrait) {
  .ACNavigation_AirCanadaNavigationItem__3WFL6 {
    width: 50dvw;
    flex: 50% 1;
  }
}
.ACNavigation_AirCanadaNavigationItem__3WFL6 a {
  color: white;
}
.ACNavigation_AirCanadaNavigationItem__3WFL6 a:hover {
  color: #e2e2e2;
  transition: all 0.5s;
}
.ACContent_AirCanadaContent__2FVln {
  margin: 6.945% 3.334% 6.945% 5.556%;
}
@media screen and (max-width: 650px) {
  .ACContent_AirCanadaContent__2FVln {
    margin: 13.334% 0 13.334% 6.667%;
  }
}
.ACContent_AirCanadaContent__2FVln h2 {
  margin-top: 50px;
}
@media screen and (max-width: 1100px) {
  .ACContent_AirCanadaContent__2FVln h2 {
    margin-top: 20px;
  }
}
@media screen and (max-width: 650px) {
  .ACContent_AirCanadaContent__2FVln h2 {
    margin-top: 20px;
  }
}
.ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentTitle__Jm4xH {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 40px;
  color: black;
}
@media screen and (max-width: 1100px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentTitle__Jm4xH {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 650px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentTitle__Jm4xH {
    font-size: 30px;
    line-height: 24px;
    margin-bottom: 24px;
    margin-top: 40px;
  }
}
.ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentOverview__1qt42 {
  margin-bottom: 60px;
}
@media screen and (max-width: 1100px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentOverview__1qt42 {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 650px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentOverview__1qt42 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;
    width: 90%;
  }
}
@media screen and (max-width: 650px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentOverview__1qt42 h3 {
    display: none;
  }
}
.ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentOverview__1qt42 p {
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  width: 80%;
  margin: auto;
}
@media screen and (max-width: 1100px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentOverview__1qt42 p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 650px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentOverview__1qt42 p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
    text-align: center;
    width: 100%;
  }
}
.ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentContent__1bRoB {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 39px;
  row-gap: 39px;
}
@media screen and (max-width: 650px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentContent__1bRoB {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    gap: 20px;
  }
}
.ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentContent__1bRoB .ACContent_AirCanadaContentDesc__xhk53 h3 {
  padding: 10px;
  color: black;
  margin-bottom: 50px;
  font-size: 26px;
  border-bottom: 2px solid #f01428;
  margin-left: 20px;
}
@media screen and (max-width: 1100px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentContent__1bRoB .ACContent_AirCanadaContentDesc__xhk53 h3 {
    margin: 0;
    margin-bottom: 20px;
    font-size: 20px;
    width: 70%;
    margin-left: 10px;
  }
}
@media screen and (max-width: 650px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentContent__1bRoB .ACContent_AirCanadaContentDesc__xhk53 h3 {
    margin: 0;
    margin-bottom: 0px;
    width: 92%;
    text-align: center;
  }
}
.ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentContent__1bRoB .ACContent_AirCanadaContentDesc__xhk53 p {
  margin-left: 30px;
  font-weight: 600;
  font-size: 20px;
  width: 70%;
}
@media screen and (max-width: 1100px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentContent__1bRoB .ACContent_AirCanadaContentDesc__xhk53 p {
    margin: auto;
    margin-left: 10px;
  }
}
@media screen and (max-width: 650px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentContent__1bRoB .ACContent_AirCanadaContentDesc__xhk53 p {
    margin: 0;
    width: 92%;
    padding: 20px;
    font-size: 14px;
  }
}
.ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentContent__1bRoB .ACContent_AirCanadaContentDesc__xhk53 ul {
  margin-left: 10px;
}
@media screen and (max-width: 1100px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentContent__1bRoB .ACContent_AirCanadaContentDesc__xhk53 ul {
    margin: auto;
    font-size: 14px;
  }
}
@media screen and (max-width: 650px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentContent__1bRoB .ACContent_AirCanadaContentDesc__xhk53 ul {
    margin: 0;
    width: 92%;
    margin-top: 20px;
  }
}
.ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentContent__1bRoB .ACContent_AirCanadaContentDesc__xhk53 li {
  list-style: disc;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  margin-right: 100px;
  margin-left: 0px;
}
@media screen and (max-width: 1100px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentContent__1bRoB .ACContent_AirCanadaContentDesc__xhk53 li {
    margin-bottom: 5px;
    font-size: 14px;
  }
}
@media screen and (max-width: 650px) {
  .ACContent_AirCanadaContent__2FVln .ACContent_AirCanadaContentContent__1bRoB .ACContent_AirCanadaContentDesc__xhk53 li {
    margin-bottom: 5px;
    margin-right: 20px;
  }
}
.QuestionAndAnswer_qna__3vOed:last-of-type .QuestionAndAnswer_question__2w8Jf {
  margin-bottom: 0;
}
.QuestionAndAnswer_qna__3vOed:last-of-type .QuestionAndAnswer_answerWrapper__2lsKG {
  margin-top: 32px;
  margin-bottom: 0;
}
@media screen and (max-width: 650px) {
  .QuestionAndAnswer_qna__3vOed:last-of-type .QuestionAndAnswer_answerWrapper__2lsKG {
    margin-top: 16px;
  }
}
.QuestionAndAnswer_qna__3vOed .QuestionAndAnswer_question__2w8Jf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 12px 27px 12px 30px;
  cursor: pointer;
  margin-bottom: 2rem;
}
@media screen and (max-width: 650px) {
  .QuestionAndAnswer_qna__3vOed .QuestionAndAnswer_question__2w8Jf {
    display: grid;
    grid-template-columns: auto auto;
    align-items: start;
    margin-bottom: 16px;
    padding: 14px 21.22px 14px 16px;
  }
}
.QuestionAndAnswer_qna__3vOed .QuestionAndAnswer_question__2w8Jf .QuestionAndAnswer_questionTitle__k0PkJ {
  font-style: italic;
  font-weight: 400;
  font-size: 26px;
  line-height: 40px;
  margin-bottom: 0px;
  color: #fff;
}
@media screen and (max-width: 650px) {
  .QuestionAndAnswer_qna__3vOed .QuestionAndAnswer_question__2w8Jf .QuestionAndAnswer_questionTitle__k0PkJ {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
  }
}
.QuestionAndAnswer_qna__3vOed .QuestionAndAnswer_question__2w8Jf .QuestionAndAnswer_plusAndMinusIcons__1fK7A {
  min-width: 15px;
  stroke: #fff;
  stroke-width: 2;
}
@media screen and (max-width: 650px) {
  .QuestionAndAnswer_qna__3vOed .QuestionAndAnswer_question__2w8Jf .QuestionAndAnswer_plusAndMinusIcons__1fK7A {
    margin-top: 7px;
    width: 12px;
    height: 12px;
    justify-self: center;
  }
}
.QuestionAndAnswer_qna__3vOed .QuestionAndAnswer_blue__2-_0r {
  background-color: #0078d2;
}
.QuestionAndAnswer_qna__3vOed .QuestionAndAnswer_darkBlue__14VBq {
  background-color: #044377;
}
.QuestionAndAnswer_qna__3vOed .QuestionAndAnswer_airCanadaRed__28Unz {
  background-color: #000000;
}
.QuestionAndAnswer_qna__3vOed .QuestionAndAnswer_answerWrapper__2lsKG {
  margin-bottom: 32px;
}
@media screen and (max-width: 650px) {
  .QuestionAndAnswer_qna__3vOed .QuestionAndAnswer_answerWrapper__2lsKG {
    margin-bottom: 16px;
  }
}
.QuestionAndAnswer_qna__3vOed .QuestionAndAnswer_answerWrapper__2lsKG p {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #3c3835;
}
@media screen and (max-width: 650px) {
  .QuestionAndAnswer_qna__3vOed .QuestionAndAnswer_answerWrapper__2lsKG p {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
}
.QuestionAndAnswer_qna__3vOed .QuestionAndAnswer_answerWrapper__2lsKG a {
  color: #0078d2;
  text-decoration: underline;
}
.QuestionAndAnswer_qna__3vOed video {
  width: 100%;
  margin-bottom: 24px;
}
@media screen and (max-width: 650px) {
  .QuestionAndAnswer_qna__3vOed video {
    margin-bottom: 16px;
  }
}
a {
  text-decoration: none;
}

.AirCanada_hero__100N- {
  display: flex;
  grid-template-columns: auto 46.667%;
  background-color: black;
  height: 30dvw;
  background-position: right center;
  background-image: url("https://content.landline.com/wp-content/uploads/2025/01/aclandingdesktop.jpg");
  background-repeat: no-repeat;
  background-size: 90dvw;
}
@media screen and (max-width: 650px) {
  .AirCanada_hero__100N- {
    grid-template-columns: 1fr;
    background-image: url("https://content.landline.com/wp-content/uploads/2025/01/acbannermobile.png");
    background-size: contain;
    background-position: bottom center;
    height: 85dvw;
  }
}
.AirCanada_hero__100N- .AirCanada_heroContent__31kMP {
  display: flex;
  width: 51dvw;
  flex-direction: column;
  justify-content: center;
  padding-right: 3.125%;
  padding-left: 5%;
}
@media screen and (max-width: 650px) {
  .AirCanada_hero__100N- .AirCanada_heroContent__31kMP {
    justify-content: flex-start;
    padding: 6.667%;
    width: 90dvw;
  }
}
.AirCanada_hero__100N- .AirCanada_heroTitle__1vKe2 {
  font-weight: 900;
  font-size: 2.5dvw;
  line-height: 3.25dvw;
  color: #fff;
  margin-bottom: 24px;
}
@media screen and (max-width: 650px) {
  .AirCanada_hero__100N- .AirCanada_heroTitle__1vKe2 {
    font-size: 4.5dvw;
    line-height: 6dvw;
    margin-bottom: 3dvw;
  }
}
.AirCanada_hero__100N- .AirCanada_heroText__1CUo1 {
  font-weight: 200;
  font-size: 1.65dvw;
  width: 30dvw;
  line-height: 2.5dvw;
  color: #fff;
  margin-bottom: 24px;
}
@media screen and (max-width: 650px) {
  .AirCanada_hero__100N- .AirCanada_heroText__1CUo1 {
    font-weight: 400;
    font-size: 3.25dvw;
    line-height: 4dvw;
    width: 100%;
    margin-bottom: 3dvw;
  }
}
.AirCanada_hero__100N- .AirCanada_emphasizedText__2yPEI {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
}
@media screen and (max-width: 1100px) {
  .AirCanada_hero__100N- .AirCanada_emphasizedText__2yPEI {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 650px) {
  .AirCanada_hero__100N- .AirCanada_emphasizedText__2yPEI {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 24px;
  }
}
.AirCanada_hero__100N- .AirCanada_bookNowBtn__3HWmR {
  display: flex;
  justify-content: center;
  margin-top: 2dvw;
  width: 13dvw;
  font-weight: 600;
  font-size: 1.35dvw;
  line-height: 1.5dvw;
  border-radius: 0.5dvw;
  background-color: #f01428;
}
@media screen and (max-width: 650px) {
  .AirCanada_hero__100N- .AirCanada_bookNowBtn__3HWmR {
    margin-top: 10px;
    font-size: 3dvw;
    width: 25dvw;
    padding: 2dvw;
    border-radius: 2dvw;
  }
}
.AirCanada_hero__100N- .AirCanada_bookNowBtn__3HWmR .AirCanada_bookNowLink__51qPw {
  color: white;
  display: inline-block;
  width: 100%;
  padding: 1dvw 0dvw;
  text-align: center;
}
.AirCanada_hero__100N- .AirCanada_bookNowBtn__3HWmR .AirCanada_bookNowLink__51qPw:hover {
  color: white;
}
.AirCanada_hero__100N- .AirCanada_heroImageWrapper__1i25M {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 650px) {
  .AirCanada_hero__100N- .AirCanada_heroImageWrapper__1i25M {
    display: none;
  }
}
.AirCanada_hero__100N- .AirCanada_heroImageWrapper__1i25M .AirCanada_heroImage__2zE-Y {
  max-width: 100%;
}
@media screen and (min-width: 2000px) {
  .AirCanada_hero__100N- .AirCanada_heroImageWrapper__1i25M .AirCanada_heroImage__2zE-Y {
    justify-self: end;
  }
}

.AirCanada_faqSection__2tZSE {
  padding-top: 0px;
  padding-bottom: 32px;
  background-color: white;
}
@media screen and (max-width: 650px) {
  .AirCanada_faqSection__2tZSE {
    padding-top: 2%;
    padding-bottom: 4.445%;
  }
}
.AirCanada_faqSection__2tZSE .AirCanada_faqContent__1-BeH {
  width: 80.556%;
  margin: 0 auto;
}
@media screen and (min-width: 2000px) {
  .AirCanada_faqSection__2tZSE .AirCanada_faqContent__1-BeH {
    width: 50%;
  }
}
@media screen and (max-width: 650px) {
  .AirCanada_faqSection__2tZSE .AirCanada_faqContent__1-BeH {
    width: 86.667%;
  }
}
.AirCanada_faqSection__2tZSE .AirCanada_faqTitle__1p376 {
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 32px;
}
@media screen and (max-width: 1100px) {
  .AirCanada_faqSection__2tZSE .AirCanada_faqTitle__1p376 {
    font-size: 30px;
    line-height: 36px;
  }
}
@media screen and (max-width: 650px) {
  .AirCanada_faqSection__2tZSE .AirCanada_faqTitle__1p376 {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 24px;
  }
}
@media (min-width: 1200px) {
  .aircanada-terms-container {
    width: 1000px;
    margin: auto;
    padding-top: 100px !important;
  }
}
.aircanada-terms-container a {
  color: #fab500;
}

.aircanada-terms-container h4 {
  margin-top: 30px;
  margin-bottom: 15px;
}

.wpWrapper {
  color: #000;
  padding: 40px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .wpWrapper {
    padding: 20px;
  }
}
.wpWrapper .wpContent {
  margin: 0 auto;
  max-width: 1060px;
}

.wpWrapper .wpContent h1 {
  color: #3c3835;
  font-size: 35px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 20px;
}

.wpWrapper .wpContent h2 {
  color: #3c3835;
  display: inline-block;
  font-size: 31px;
  font-weight: 600;
  line-height: 1.4;
  margin: 20px 0 10px;
}

@media screen and (max-width: 768px) {
  .wpWrapper .wpContent h2 {
    font-size: 30px;
  }
}
.wpWrapper .wpContent h2:first-child {
  margin-top: 0;
}

.wpWrapper .wpContent h3 {
  color: #3c3835;
  display: inline-block;
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .wpWrapper .wpContent h3 {
    font-size: 24px !important;
  }
}
.wpWrapper .wpContent h3 b {
  font-weight: 600;
}

.wpWrapper .wpContent h4 {
  font-size: 22px;
  line-height: 1.3;
}

@media screen and (max-width: 475px) {
  .wpWrapper .wpContent h4 {
    font-size: 18px !important;
  }
}
.wpWrapper .wpContent h6 {
  font-weight: 600;
  margin: 10px 0;
}

.wpWrapper .wpContent p {
  color: #000;
  font-weight: 400;
  line-height: 1.45;
  margin-bottom: 10px;
}

.wpWrapper .wpContent p b {
  font-weight: 900;
}

.wpWrapper .wpContent li {
  color: #000;
  font-size: 18px;
  list-style-image: none;
  margin-left: 40px;
}

@media screen and (max-width: 768px) {
  .wpWrapper .wpContent li {
    font-size: 14px !important;
    margin-left: 20px;
  }
}
.wpWrapper .wpContent a:hover {
  text-decoration: underline;
}

.wpWrapper .wpContent .main-title {
  margin-top: 0;
}

.wpWrapper .wpContent .subtitle {
  margin-bottom: 10px;
}

.wpWrapper .wpContent .wp-block-buttons {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

@media screen and (max-width: 475px) {
  .wpWrapper .wpContent .wp-block-buttons {
    margin: 0;
  }
}
.wpWrapper .wpContent .wp-block-buttons .wp-block-button {
  border-radius: 4px;
  display: flex;
}

.wpWrapper .wpContent .wp-block-buttons .wp-block-button a {
  background-color: #fab500;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  padding: 12px 24px;
}

.wpWrapper .wpContent .wp-block-buttons .wp-block-button a:hover {
  background-color: #faab00 !important;
  text-decoration: none;
  transition: 0.1s;
}

.wpWrapper .wpContent .wp-block-separator {
  border: none;
  border-top: 1px solid #ccc;
}
.B2BHeader_header__KoxtS .B2BHeader_content__2hjza {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  width: 88.88%;
  margin: 0 auto;
}
@media screen and (max-width: 650px) {
  .B2BHeader_header__KoxtS .B2BHeader_content__2hjza {
    width: 86.667%;
    padding: 24px 0;
  }
}
.B2BHeader_header__KoxtS .B2BHeader_content__2hjza .B2BHeader_logoWrapper__3IsFu {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 650px) {
  .B2BHeader_header__KoxtS .B2BHeader_content__2hjza .B2BHeader_logoWrapper__3IsFu img {
    width: 120px;
  }
}
.B2BHeader_header__KoxtS .B2BHeader_content__2hjza .B2BHeader_rightContent__1MgNf {
  display: flex;
  align-items: center;
}
.B2BHeader_header__KoxtS .B2BHeader_content__2hjza .B2BHeader_rightContent__1MgNf .B2BHeader_callUs__1fSOn {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 0;
}
.B2BHeader_header__KoxtS .B2BHeader_content__2hjza .B2BHeader_rightContent__1MgNf .B2BHeader_callUs__1fSOn:hover {
  cursor: pointer;
}
@media screen and (max-width: 650px) {
  .B2BHeader_header__KoxtS .B2BHeader_content__2hjza .B2BHeader_rightContent__1MgNf .B2BHeader_callUs__1fSOn {
    font-size: 16px;
    line-height: 24px;
  }
}
.B2BHeader_header__KoxtS .B2BHeader_content__2hjza .B2BHeader_rightContent__1MgNf .B2BHeader_phoneNumber__1scJI {
  display: flex;
  align-items: center;
}
.B2BHeader_header__KoxtS .B2BHeader_content__2hjza .B2BHeader_rightContent__1MgNf .B2BHeader_phoneNumber__1scJI a {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #999999;
  margin-left: 24px;
}
@media screen and (max-width: 650px) {
  .B2BHeader_header__KoxtS .B2BHeader_content__2hjza .B2BHeader_rightContent__1MgNf .B2BHeader_phoneNumber__1scJI a {
    font-size: 16px;
    line-height: 24px;
  }
}
.Faq_faq__2geTk .Faq_content__3eUiU {
  width: 80.556%;
  margin: 0 auto;
}
@media screen and (min-width: 2000px) {
  .Faq_faq__2geTk .Faq_content__3eUiU {
    width: 50%;
  }
}
@media screen and (max-width: 650px) {
  .Faq_faq__2geTk .Faq_content__3eUiU {
    width: 86.667%;
  }
}
.Faq_faq__2geTk .Faq_content__3eUiU .Faq_title__26xPt {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  text-align: center;
  margin-top: 9.445%;
  margin-bottom: 9.445%;
}
@media screen and (max-width: 1100px) {
  .Faq_faq__2geTk .Faq_content__3eUiU .Faq_title__26xPt {
    font-size: 30px;
    line-height: 36px;
  }
}
@media screen and (max-width: 650px) {
  .Faq_faq__2geTk .Faq_content__3eUiU .Faq_title__26xPt {
    font-size: 30px;
    line-height: 24px;
    margin-top: 64px;
    margin-bottom: 40px;
  }
}
.HowItWorksIcon_howItWorksIcon__2Vhqt {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HowItWorksIcon_howItWorksIcon__2Vhqt .HowItWorksIcon_iconWrapper__m8-no {
  width: 114px;
  height: 114px;
  background-color: #0078d2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}
@media screen and (max-width: 650px) {
  .HowItWorksIcon_howItWorksIcon__2Vhqt .HowItWorksIcon_iconWrapper__m8-no {
    width: 76px;
    height: 76px;
    margin-bottom: 32px;
  }
  .HowItWorksIcon_howItWorksIcon__2Vhqt .HowItWorksIcon_iconWrapper__m8-no .HowItWorksIcon_ticketsIcon__1TXIr {
    width: 42px;
    height: 42px;
  }
  .HowItWorksIcon_howItWorksIcon__2Vhqt .HowItWorksIcon_iconWrapper__m8-no .HowItWorksIcon_luggageIcon__1P8yR {
    width: 42px;
    height: 42px;
  }
  .HowItWorksIcon_howItWorksIcon__2Vhqt .HowItWorksIcon_iconWrapper__m8-no .HowItWorksIcon_seatWifiIcon__3qD7g {
    width: 44px;
    height: 44px;
  }
  .HowItWorksIcon_howItWorksIcon__2Vhqt .HowItWorksIcon_iconWrapper__m8-no .HowItWorksIcon_dollarIcon__1lvG- {
    width: 15px;
    height: 25px;
  }
}
.HowItWorksIcon_howItWorksIcon__2Vhqt .HowItWorksIcon_iconDescription__yhfe6 p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3c3835;
  text-align: center;
}
@media screen and (max-width: 650px) {
  .HowItWorksIcon_howItWorksIcon__2Vhqt .HowItWorksIcon_iconDescription__yhfe6 p {
    line-height: 25px;
  }
}
.HowItWorksIcon_howItWorksIcon__2Vhqt .HowItWorksIcon_iconDescription__yhfe6 a {
  color: #0078d2;
}
a {
  text-decoration: none;
}

.American_whereToFindUs__2FvQ5 img {
  width: 100%;
}

@media screen and (max-width: 650px) {
  .American_american__1RA6r {
    background-color: #fafafa;
  }
}
.American_american__1RA6r .American_hero__22ndf {
  display: flex;
  background-color: #0078d2;
}
@media screen and (max-width: 1100px) {
  .American_american__1RA6r .American_hero__22ndf {
    background-image: linear-gradient(180deg, rgba(44, 44, 44, 0.726) 32.81%, rgba(0, 0, 0, 0) 87.81%), url(${PUBLIC_URL}/static/media/american-airlines-bus.422e7781.png);
    background-size: cover;
    background-position: left center;
    height: 613px;
  }
}
@media screen and (max-width: 1100px) {
  .American_american__1RA6r .American_hero__22ndf {
    background-color: unset;
    flex-direction: column;
  }
}
.American_american__1RA6r .American_hero__22ndf .American_heroContent__19gcM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding-left: 5.556%;
  padding-right: 3.889%;
}
@media screen and (min-width: 2000px) {
  .American_american__1RA6r .American_hero__22ndf .American_heroContent__19gcM {
    padding-left: 12.476%;
  }
}
@media screen and (max-width: 1100px) {
  .American_american__1RA6r .American_hero__22ndf .American_heroContent__19gcM {
    width: 100%;
    padding: 24px 6.667% 0 6.667%;
  }
}
.American_american__1RA6r .American_hero__22ndf .American_heroTitle__rREUP {
  font-weight: 900;
  font-size: 64px;
  line-height: 96px;
  color: #fff;
  margin-bottom: 2rem;
}
@media screen and (max-width: 1100px) {
  .American_american__1RA6r .American_hero__22ndf .American_heroTitle__rREUP {
    font-size: 32px;
    line-height: 48px;
  }
}
@media screen and (max-width: 650px) {
  .American_american__1RA6r .American_hero__22ndf .American_heroTitle__rREUP {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 1rem;
  }
}
.American_american__1RA6r .American_hero__22ndf .American_heroText__1jYz_ {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 2rem;
}
@media screen and (max-width: 1100px) {
  .American_american__1RA6r .American_hero__22ndf .American_heroText__1jYz_ {
    font-size: 18px;
    line-height: 24px;
  }
}
@media screen and (max-width: 650px) {
  .American_american__1RA6r .American_hero__22ndf .American_heroText__1jYz_ {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 1rem;
  }
  .American_american__1RA6r .American_hero__22ndf .American_heroText__1jYz_:last-of-type {
    margin-bottom: 0;
  }
}
.American_american__1RA6r .American_hero__22ndf .American_bookNowBtn__ibBqE {
  display: flex;
  justify-content: center;
  max-width: 240px;
  min-width: 200px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  border-radius: 8px;
  background-color: #fff;
}
@media screen and (max-width: 1100px) {
  .American_american__1RA6r .American_hero__22ndf .American_bookNowBtn__ibBqE {
    max-width: 24%;
    min-width: 140px;
    border: 1px solid #0078d2;
    font-size: 14px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1100px) and (max-width: 650px) {
  .American_american__1RA6r .American_hero__22ndf .American_bookNowBtn__ibBqE {
    margin-top: 22px;
  }
}
.American_american__1RA6r .American_hero__22ndf .American_bookNowBtn__ibBqE .American_bookNowLink__Wc3VC {
  color: #3c3835;
  display: inline-block;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
}
.American_american__1RA6r .American_hero__22ndf .American_bookNowBtn__ibBqE .American_bookNowLink__Wc3VC:hover {
  color: #0078d2;
}
@media screen and (max-width: 1100px) {
  .American_american__1RA6r .American_hero__22ndf .American_bookNowBtn__ibBqE .American_bookNowLink__Wc3VC {
    padding: 6px;
    text-align: center;
  }
}
.American_american__1RA6r .American_hero__22ndf .American_heroImageWrapper__2p3Lq {
  width: 50%;
  background: url(${PUBLIC_URL}/static/media/american-airlines-bus.422e7781.png);
  background-color: transparent;
  background-size: cover;
  background-position: center center;
  height: 640px;
  border-top-left-radius: 500px;
  border-bottom-left-radius: 500px;
}
@media screen and (min-width: 2000px) {
  .American_american__1RA6r .American_hero__22ndf .American_heroImageWrapper__2p3Lq {
    height: 900px;
  }
}
@media screen and (max-width: 1100px) {
  .American_american__1RA6r .American_hero__22ndf .American_heroImageWrapper__2p3Lq {
    display: none;
  }
}
.American_american__1RA6r .American_howItWorks__1XXeh {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 136px auto;
}
@media screen and (max-width: 650px) {
  .American_american__1RA6r .American_howItWorks__1XXeh {
    margin-bottom: 0;
    padding: 64px 0;
    background-color: #fff;
    margin: 0;
  }
}
.American_american__1RA6r .American_howItWorks__1XXeh .American_howItWorksTitle__3jhJF {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 8.375rem;
}
@media screen and (max-width: 650px) {
  .American_american__1RA6r .American_howItWorks__1XXeh .American_howItWorksTitle__3jhJF {
    font-size: 30px;
    line-height: 24px;
    margin-bottom: 64px;
  }
}
.American_american__1RA6r .American_howItWorks__1XXeh .American_icons__FlY2L {
  width: 80.5556%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px;
}
@media screen and (min-width: 2000px) {
  .American_american__1RA6r .American_howItWorks__1XXeh .American_icons__FlY2L {
    max-width: 50%;
  }
}
@media screen and (max-width: 1100px) {
  .American_american__1RA6r .American_howItWorks__1XXeh .American_icons__FlY2L {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
@media screen and (max-width: 650px) {
  .American_american__1RA6r .American_howItWorks__1XXeh .American_icons__FlY2L {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    width: 86.667%;
    grid-gap: 64px;
    gap: 64px;
  }
}
.American_american__1RA6r .American_aaLogoWrapper__1fXHR {
  display: flex;
  justify-content: center;
  margin: 136px 0 136px 0;
}
@media screen and (max-width: 650px) {
  .American_american__1RA6r .American_aaLogoWrapper__1fXHR {
    margin: 64px 0 64px 0;
  }
}
@media screen and (max-width: 650px) {
  .American_american__1RA6r .American_aaLogoWrapper__1fXHR a {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 650px) {
  .American_american__1RA6r .American_aaLogoWrapper__1fXHR a .American_aaLogo__3jXun {
    width: 63.338%;
  }
}
.American_american__1RA6r .American_whereToFindUs__2FvQ5 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  width: 80.556%;
  margin: 136px auto 0 auto;
  text-align: center;
}
@media screen and (min-width: 2000px) {
  .American_american__1RA6r .American_whereToFindUs__2FvQ5 {
    max-width: 50%;
  }
}
@media screen and (max-width: 1100px) {
  .American_american__1RA6r .American_whereToFindUs__2FvQ5 {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-gap: 64.57px;
    gap: 64.57px;
  }
}
@media screen and (max-width: 650px) {
  .American_american__1RA6r .American_whereToFindUs__2FvQ5 {
    margin-top: 64px;
    width: 100%;
    padding: 0 24px;
    grid-gap: 64.57px;
    gap: 64.57px;
  }
}
.American_american__1RA6r .American_whereToFindUs__2FvQ5 .American_whereToFindUsContent__2QSYA .American_whereToFindUsTitle__1V5lK {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 650px) {
  .American_american__1RA6r .American_whereToFindUs__2FvQ5 .American_whereToFindUsContent__2QSYA .American_whereToFindUsTitle__1V5lK {
    text-align: center;
    font-size: 28px;
    line-height: 40px;
  }
}
.American_american__1RA6r .American_whereToFindUs__2FvQ5 .American_whereToFindUsContent__2QSYA .American_whereToFindUsText__3rTnb {
  font-size: 24px;
  line-height: 32px;
}
@media screen and (max-width: 650px) {
  .American_american__1RA6r .American_whereToFindUs__2FvQ5 .American_whereToFindUsContent__2QSYA .American_whereToFindUsText__3rTnb {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }
}
.American_american__1RA6r .American_whereToFindUs__2FvQ5 .American_whereToFindUsContent__2QSYA .American_whereToFindUsBold__2EN6n {
  font-weight: 600;
  margin-top: 32px;
}
@media screen and (max-width: 650px) {
  .American_american__1RA6r .American_whereToFindUs__2FvQ5 .American_whereToFindUsContent__2QSYA .American_whereToFindUsBold__2EN6n {
    margin-top: 26px;
  }
}
.American_american__1RA6r .American_whereToFindUs__2FvQ5 .American_whereToFindUsContent__2QSYA .American_whereToFindUsBold__2EN6n:first-of-type {
  margin-top: 0;
}
.wpWrapper .wpContent a {
  color: #fab500;
  text-decoration: none;
}
.wpWrapper .wpContent ul {
  margin-bottom: 10px;
  line-height: 1.45;
}
.wpWrapper .wpContent ul li {
  font-size: 16px;
}

.wpTop {
  padding-top: 125px;
}
.B2BHowItWorksIconAndDes_B2BHowItWorksIconAndDes__3_M33 {
  display: flex;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}
@media screen and (max-width: 650px) {
  .B2BHowItWorksIconAndDes_B2BHowItWorksIconAndDes__3_M33 {
    grid-gap: 16px;
    gap: 16px;
  }
}
@media screen and (max-width: 1100px) {
  .B2BHowItWorksIconAndDes_B2BHowItWorksIconAndDes__3_M33 .B2BHowItWorksIconAndDes_B2BHowItWorksIcon__2Uo10 {
    width: 7.143%;
  }
}
.B2BHowItWorksIconAndDes_B2BHowItWorksIconAndDes__3_M33 .B2BHowItWorksIconAndDes_B2BHowItWorksDescription__2eDXr {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 0;
}
@media screen and (max-width: 1100px) {
  .B2BHowItWorksIconAndDes_B2BHowItWorksIconAndDes__3_M33 .B2BHowItWorksIconAndDes_B2BHowItWorksDescription__2eDXr {
    font-size: 20px;
    line-height: 25px;
  }
}
@media screen and (max-width: 650px) {
  .B2BHowItWorksIconAndDes_B2BHowItWorksIconAndDes__3_M33 .B2BHowItWorksIconAndDes_B2BHowItWorksDescription__2eDXr {
    font-size: 16px;
    line-height: 16px;
  }
}
.B2BHowItWorks_B2BHowItWorks__8MBad {
  margin: 6.945% 3.334% 6.945% 5.556%;
}
@media screen and (max-width: 650px) {
  .B2BHowItWorks_B2BHowItWorks__8MBad {
    margin: 13.334% 0 13.334% 6.667%;
  }
}
.B2BHowItWorks_B2BHowItWorks__8MBad .B2BHowItWorks_B2BHowItWorksTitle__1335E {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 40px;
}
@media screen and (max-width: 1100px) {
  .B2BHowItWorks_B2BHowItWorks__8MBad .B2BHowItWorks_B2BHowItWorksTitle__1335E {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 650px) {
  .B2BHowItWorks_B2BHowItWorks__8MBad .B2BHowItWorks_B2BHowItWorksTitle__1335E {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 24px;
  }
}
.B2BHowItWorks_B2BHowItWorks__8MBad .B2BHowItWorks_B2BhowItWorksContent__11UoE {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 39px;
  row-gap: 39px;
}
@media screen and (max-width: 650px) {
  .B2BHowItWorks_B2BHowItWorks__8MBad .B2BHowItWorks_B2BhowItWorksContent__11UoE {
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    gap: 20px;
  }
}
.B2BWhereToFindUs_B2BWhereToFindUs__1YJuu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10% 0;
}
@media screen and (max-width: 650px) {
  .B2BWhereToFindUs_B2BWhereToFindUs__1YJuu {
    padding: 13.333% 0 64.167% 0;
    display: flex;
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 1100px) {
  .B2BWhereToFindUs_B2BWhereToFindUs__1YJuu .B2BWhereToFindUs_B2BWhereToFindUsImage__1SSZ9 {
    width: 100%;
  }
}
@media screen and (max-width: 475px) {
  .B2BWhereToFindUs_B2BWhereToFindUs__1YJuu .B2BWhereToFindUs_B2BWhereToFindUsImage__1SSZ9 {
    width: unset;
    height: 181px;
    position: absolute;
    right: 6.667%;
  }
}
.B2BWhereToFindUs_B2BWhereToFindUs__1YJuu .B2BWhereToFindUs_textContent__2wA_f {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
  text-align: center;
}
@media screen and (max-width: 650px) {
  .B2BWhereToFindUs_B2BWhereToFindUs__1YJuu .B2BWhereToFindUs_textContent__2wA_f {
    grid-gap: 24px;
    gap: 24px;
    margin-bottom: 56px;
  }
}
.B2BWhereToFindUs_B2BWhereToFindUs__1YJuu .B2BWhereToFindUs_textContent__2wA_f .B2BWhereToFindUs_B2BWhereToFindUsTitle__AYII0 {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 0;
}
@media screen and (max-width: 1100px) {
  .B2BWhereToFindUs_B2BWhereToFindUs__1YJuu .B2BWhereToFindUs_textContent__2wA_f .B2BWhereToFindUs_B2BWhereToFindUsTitle__AYII0 {
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (max-width: 650px) {
  .B2BWhereToFindUs_B2BWhereToFindUs__1YJuu .B2BWhereToFindUs_textContent__2wA_f .B2BWhereToFindUs_B2BWhereToFindUsTitle__AYII0 {
    font-size: 16px;
    line-height: 16px;
  }
}
.B2BWhereToFindUs_B2BWhereToFindUs__1YJuu .B2BWhereToFindUs_textContent__2wA_f .B2BWhereToFindUs_address__1M4AY {
  font-size: 24px;
  line-height: 32px;
}
@media screen and (max-width: 1100px) {
  .B2BWhereToFindUs_B2BWhereToFindUs__1YJuu .B2BWhereToFindUs_textContent__2wA_f .B2BWhereToFindUs_address__1M4AY {
    font-size: 20px;
    line-height: 25px;
  }
}
@media screen and (max-width: 650px) {
  .B2BWhereToFindUs_B2BWhereToFindUs__1YJuu .B2BWhereToFindUs_textContent__2wA_f .B2BWhereToFindUs_address__1M4AY {
    font-size: 16px;
    line-height: 16px;
  }
}
.B2BWhereToFindUs_B2BWhereToFindUs__1YJuu .B2BWhereToFindUs_textContent__2wA_f .B2BWhereToFindUs_freeParking__k1WjP {
  font-style: italic;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 0;
}
@media screen and (max-width: 1100px) {
  .B2BWhereToFindUs_B2BWhereToFindUs__1YJuu .B2BWhereToFindUs_textContent__2wA_f .B2BWhereToFindUs_freeParking__k1WjP {
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (max-width: 650px) {
  .B2BWhereToFindUs_B2BWhereToFindUs__1YJuu .B2BWhereToFindUs_textContent__2wA_f .B2BWhereToFindUs_freeParking__k1WjP {
    font-size: 16px;
    line-height: 38px;
  }
}
.B2BNavigation_B2BNavigation__3Lwzn {
  display: grid;
  border-top: 1px solid #e2e2e2;
  border-left: 1px solid #e2e2e2;
}
@media only screen and (orientation: landscape) {
  .B2BNavigation_B2BNavigation__3Lwzn {
    grid-template-columns: 25% 25% 25% 25%;
    height: 10dvh;
  }
}
@media only screen and (orientation: portrait) {
  .B2BNavigation_B2BNavigation__3Lwzn {
    grid-template-columns: 50% 50%;
    height: 10dvh;
  }
}

.B2BNavigation_B2BNavigationItem__1j3dz {
  border-right: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
@media only screen and (orientation: landscape) {
  .B2BNavigation_B2BNavigationItem__1j3dz {
    width: 25dvw;
  }
}
@media only screen and (orientation: portrait) {
  .B2BNavigation_B2BNavigationItem__1j3dz {
    width: 50dvw;
  }
}
.B2BNavigation_B2BNavigationItem__1j3dz a {
  color: black;
}
.B2BNavigation_B2BNavigationItem__1j3dz .B2BNavigation_B2BNavigationItem__1j3dz:hover {
  background-color: #d8caa6;
}
.B2BNavigation_B2BNavigationItem__1j3dz a:hover {
  color: #fab500;
  transition: all 0.5s;
}
.BookFNL_hero__1af7z {
  display: grid;
  grid-template-columns: auto 46.667%;
  background-color: #044377;
  max-height: 640px;
}
@media screen and (max-width: 650px) {
  .BookFNL_hero__1af7z {
    grid-template-columns: 1fr;
    background-image: url("https://content.landline.com/wp-content/uploads/2025/01/unitedBusHeroMobile.png");
    background-size: cover;
    background-position: left center;
    height: 613px;
  }
}
.BookFNL_hero__1af7z .BookFNL_heroContent__1jdYa {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 3.125%;
  padding-left: 10.417%;
}
@media screen and (max-width: 650px) {
  .BookFNL_hero__1af7z .BookFNL_heroContent__1jdYa {
    justify-content: flex-start;
    padding: 6.667%;
  }
}
.BookFNL_hero__1af7z .BookFNL_heroTitle__1oAKT {
  font-weight: 900;
  font-size: 64px;
  line-height: 96px;
  color: #fff;
  margin-bottom: 24px;
}
@media screen and (max-width: 1420px) {
  .BookFNL_hero__1af7z .BookFNL_heroTitle__1oAKT {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 700px) {
  .BookFNL_hero__1af7z .BookFNL_heroTitle__1oAKT {
    font-size: 20px;
    line-height: 20px;
  }
}
.BookFNL_hero__1af7z .BookFNL_heroText__2gAtS {
  font-weight: 200;
  font-size: 20px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 24px;
}
@media screen and (max-width: 1100px) {
  .BookFNL_hero__1af7z .BookFNL_heroText__2gAtS {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 650px) {
  .BookFNL_hero__1af7z .BookFNL_heroText__2gAtS {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
.BookFNL_hero__1af7z .BookFNL_emphasizedText__2YSwQ {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
}
@media screen and (max-width: 1100px) {
  .BookFNL_hero__1af7z .BookFNL_emphasizedText__2YSwQ {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 650px) {
  .BookFNL_hero__1af7z .BookFNL_emphasizedText__2YSwQ {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 24px;
  }
}
.BookFNL_hero__1af7z .BookFNL_bookNowBtn__2YDI9 {
  display: flex;
  justify-content: center;
  max-width: 240px;
  min-width: 200px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  border-radius: 8px;
  background-color: #fff;
}
@media screen and (max-width: 1100px) {
  .BookFNL_hero__1af7z .BookFNL_bookNowBtn__2YDI9 {
    max-width: 24%;
    min-width: 140px;
    border: 1px solid #1c94f7;
    font-size: 14px;
    line-height: 24px;
  }
}
.BookFNL_hero__1af7z .BookFNL_bookNowBtn__2YDI9 .BookFNL_bookNowLink__mk093 {
  color: #044377;
  display: inline-block;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
}
.BookFNL_hero__1af7z .BookFNL_bookNowBtn__2YDI9 .BookFNL_bookNowLink__mk093:hover {
  color: #1c94f7;
}
@media screen and (max-width: 1100px) {
  .BookFNL_hero__1af7z .BookFNL_bookNowBtn__2YDI9 .BookFNL_bookNowLink__mk093 {
    padding: 6px;
    text-align: center;
  }
}
.BookFNL_hero__1af7z .BookFNL_heroImageWrapper__1DdN7 {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 650px) {
  .BookFNL_hero__1af7z .BookFNL_heroImageWrapper__1DdN7 {
    display: none;
  }
}
.BookFNL_hero__1af7z .BookFNL_heroImageWrapper__1DdN7 .BookFNL_heroImage__35aL7 {
  max-width: 100%;
}
@media screen and (min-width: 2000px) {
  .BookFNL_hero__1af7z .BookFNL_heroImageWrapper__1DdN7 .BookFNL_heroImage__35aL7 {
    justify-self: end;
  }
}

.BookFNL_faqSection__3y4cL {
  padding-top: 180px;
  padding-bottom: 32px;
  background-color: #f5f5f5;
}
@media screen and (max-width: 650px) {
  .BookFNL_faqSection__3y4cL {
    padding-top: 8.889%;
    padding-bottom: 4.445%;
  }
}
.BookFNL_faqSection__3y4cL .BookFNL_faqContent__1bplO {
  width: 80.556%;
  margin: 0 auto;
}
@media screen and (min-width: 2000px) {
  .BookFNL_faqSection__3y4cL .BookFNL_faqContent__1bplO {
    width: 50%;
  }
}
@media screen and (max-width: 650px) {
  .BookFNL_faqSection__3y4cL .BookFNL_faqContent__1bplO {
    width: 86.667%;
  }
}
.BookFNL_faqSection__3y4cL .BookFNL_faqTitle__1M2AP {
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 32px;
}
@media screen and (max-width: 1100px) {
  .BookFNL_faqSection__3y4cL .BookFNL_faqTitle__1M2AP {
    font-size: 30px;
    line-height: 36px;
  }
}
@media screen and (max-width: 650px) {
  .BookFNL_faqSection__3y4cL .BookFNL_faqTitle__1M2AP {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 24px;
  }
}
.privacy-policy-title {
  margin-top: 10px;
}

.last-updated-text {
  margin-bottom: 10px;
  font-weight: 600;
}
.MotorcoachTable_motorcoachPage__content__table__3PZcC {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  color: #3c3835;
}
@media screen and (max-width: 768px) {
  .MotorcoachTable_motorcoachPage__content__table__3PZcC {
    width: 90%;
  }
}
.MotorcoachTable_motorcoachPage__content__table__3PZcC .MotorcoachTable_motorcoachPage__table__label__3afsL {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
}
@media screen and (max-width: 768px) {
  .MotorcoachTable_motorcoachPage__content__table__3PZcC .MotorcoachTable_motorcoachPage__table__label__3afsL {
    grid-template-columns: repeat(1, 1fr);
    text-align: left;
  }
}
.MotorcoachTable_motorcoachPage__content__table__3PZcC .MotorcoachTable_motorcoachPage__table__label__3afsL p {
  padding: 20px;
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .MotorcoachTable_motorcoachPage__content__table__3PZcC .MotorcoachTable_motorcoachPage__table__label__3afsL p {
    padding: 15px 0 15px 0;
  }
}
.MotorcoachTable_motorcoachPage__content__table__3PZcC .MotorcoachTable_motorcoachPage__table__label__3afsL span {
  font-weight: 600;
}

.MotorcoachTable_motorcoachPage__table__row__Sk1Zb {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (max-width: 768px) {
  .MotorcoachTable_motorcoachPage__table__row__Sk1Zb {
    grid-template-columns: repeat(1, 1fr);
    text-align: left;
  }
}
.MotorcoachTable_motorcoachPage__table__row__Sk1Zb:nth-child(odd) {
  background-color: #ddd;
}
.MotorcoachTable_motorcoachPage__table__row__Sk1Zb:nth-child(even) {
  background-color: #f5f5f5;
}
.MotorcoachTable_motorcoachPage__table__row__Sk1Zb .MotorcoachTable_motorcoachPage__table__cell__3i7eq {
  border: 2px solid #fff;
}
@media screen and (max-width: 768px) {
  .MotorcoachTable_motorcoachPage__table__row__Sk1Zb .MotorcoachTable_motorcoachPage__table__cell__3i7eq:nth-child(odd) {
    background-color: #f5f5f5;
  }
  .MotorcoachTable_motorcoachPage__table__row__Sk1Zb .MotorcoachTable_motorcoachPage__table__cell__3i7eq:nth-child(even) {
    background-color: #ddd;
  }
}
.MotorcoachTable_motorcoachPage__table__row__Sk1Zb .MotorcoachTable_motorcoachPage__table__cell__3i7eq .MotorcoachTable_motorcoachPage__table__cell__inner__38eC8 {
  padding: 17px 40px;
  display: flex;
  align-items: center;
}
.MotorcoachTable_motorcoachPage__table__row__Sk1Zb .MotorcoachTable_motorcoachPage__table__cell__3i7eq .MotorcoachTable_motorcoachPage__table__cell__inner__38eC8 p {
  padding-left: 20px;
  font-size: 18px;
}
.MotorcoachTable_motorcoachPage__table__row__Sk1Zb .MotorcoachTable_motorcoachPage__table__cell__3i7eq .MotorcoachTable_tableIcon__SvHPx {
  font-size: 24px;
}
.MotorcoachTable_motorcoachPage__table__row__Sk1Zb .MotorcoachTable_motorcoachPage__table__cell__3i7eq .MotorcoachTable_tableIcon-rotate__2srfy {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.Motorcoach_motorcoachPage__1sICP {
  width: 100%;
}
.Motorcoach_motorcoachPage__content__2zL7B {
  width: 100%;
}
.Motorcoach_motorcoachPage__content__2zL7B .Motorcoach_inner__pwdFJ {
  padding: 88px 0 100px 0;
  background-color: #fafafa;
}
@media screen and (max-width: 500px) {
  .Motorcoach_motorcoachPage__content__2zL7B .Motorcoach_inner__pwdFJ {
    padding: 32px 0 40px 0;
    background-color: #fff;
  }
}
.Motorcoach_motorcoachPage__content__2zL7B .Motorcoach_hero__3IPlP {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .Motorcoach_motorcoachPage__content__2zL7B .Motorcoach_hero__3IPlP {
    width: 90%;
  }
}
.Motorcoach_motorcoachPage__content__2zL7B .Motorcoach_hero__3IPlP .Motorcoach_motorcoachPage__pageTitle__3uNjT {
  font-weight: 900;
  font-size: 84px;
  color: #3c3835;
}
@media screen and (max-width: 768px) {
  .Motorcoach_motorcoachPage__content__2zL7B .Motorcoach_hero__3IPlP .Motorcoach_motorcoachPage__pageTitle__3uNjT {
    font-size: 64px;
  }
}
@media screen and (max-width: 500px) {
  .Motorcoach_motorcoachPage__content__2zL7B .Motorcoach_hero__3IPlP .Motorcoach_motorcoachPage__pageTitle__3uNjT {
    font-size: 48px;
  }
}
@media screen and (max-width: 425px) {
  .Motorcoach_motorcoachPage__content__2zL7B .Motorcoach_hero__3IPlP .Motorcoach_motorcoachPage__pageTitle__3uNjT {
    font-size: 40px;
  }
}
.Motorcoach_motorcoachPage__content__2zL7B .Motorcoach_hero__3IPlP .Motorcoach_motorcoachPage__heroImage__2RB4k {
  width: 80%;
  max-width: 937px;
  margin-top: 100px;
}
@media screen and (max-width: 768px) {
  .Motorcoach_motorcoachPage__content__2zL7B .Motorcoach_hero__3IPlP .Motorcoach_motorcoachPage__heroImage__2RB4k {
    width: 90%;
  }
}
@media screen and (max-width: 500px) {
  .Motorcoach_motorcoachPage__content__2zL7B .Motorcoach_hero__3IPlP .Motorcoach_motorcoachPage__heroImage__2RB4k {
    margin-top: 40px;
  }
}
.Motorcoach_motorcoachPage__content__texts__2CJE4 {
  width: 90%;
  margin: 188px auto;
  max-width: 1060px;
}
@media screen and (max-width: 768px) {
  .Motorcoach_motorcoachPage__content__texts__2CJE4 {
    margin: 0px auto;
  }
}
.Motorcoach_motorcoachPage__content__texts__2CJE4 .Motorcoach_motorcoachPage__text__1Obkj {
  font-weight: 400;
  font-size: 20px;
  -webkit-columns: #3c3835;
          columns: #3c3835;
  margin-top: 24px;
}
@media screen and (max-width: 768px) {
  .Motorcoach_motorcoachPage__content__texts__2CJE4 .Motorcoach_motorcoachPage__text__1Obkj {
    font-size: 16px;
  }
}
.Motorcoach_motorcoachPage__content__texts__2CJE4 .Motorcoach_motorcoachPage__text__1Obkj a {
  cursor: pointer;
  color: #fab500;
}

.Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP {
  margin: 80px 0 0 0;
  width: 100%;
  background-color: #f5f5f5;
  color: #3c3835;
  padding-bottom: 100px;
}
.Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__2iHZq {
  max-width: 1200px;
  width: 75%;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  .Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__2iHZq {
    width: 90%;
  }
}
.Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__sectionTitle__3w0Bz {
  text-align: center;
  padding: 100px 0;
  font-weight: 900;
  font-size: 64px;
}
@media screen and (max-width: 768px) {
  .Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__sectionTitle__3w0Bz {
    font-size: 36px;
  }
}
@media screen and (max-width: 500px) {
  .Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__sectionTitle__3w0Bz {
    font-size: 24px;
    font-weight: 600;
    padding: 40px 0 0 0;
    margin: 0;
  }
}
.Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
}
@media screen and (max-width: 768px) {
  .Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0 {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 500px) {
  .Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0 {
    margin-top: 40px;
  }
}
.Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0 img {
  width: 49%;
}
@media screen and (max-width: 768px) {
  .Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0 img {
    width: 100%;
  }
}
.Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0 .Motorcoach_yourLandLineExperience__texts__2Y7ur {
  width: 43%;
}
@media screen and (max-width: 768px) {
  .Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0 .Motorcoach_yourLandLineExperience__texts__2Y7ur {
    width: 90%;
    margin: 40px 0;
  }
}
.Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0:nth-child(odd) {
  flex-direction: row-reverse;
}
@media screen and (max-width: 768px) {
  .Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0:nth-child(odd) {
    flex-direction: column;
  }
}
.Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0:nth-child(odd) .Motorcoach_yourLandLineExperience__texts__2Y7ur {
  margin: 0;
}
@media screen and (max-width: 768px) {
  .Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0:nth-child(odd) .Motorcoach_yourLandLineExperience__texts__2Y7ur {
    width: 90%;
    margin: 40px 0;
  }
}
.Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0 .Motorcoach_yourLandLineExperience__texts__title__KvZdb {
  font-weight: bold;
  font-size: 40px;
  text-align: left;
}
@media screen and (max-width: 768px) {
  .Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0 .Motorcoach_yourLandLineExperience__texts__title__KvZdb {
    font-size: 30px;
    text-align: left;
  }
}
.Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0 .Motorcoach_yourLandLineExperience__text__1Oz8c {
  margin-top: 12px;
  font-weight: normal;
  font-size: 16px;
}
.Motorcoach_motorcoachPage__content__yourLandLineExperience__35-GP .Motorcoach_yourLandLineExperience__content__row__3nct0 .Motorcoach_bold__CBPFh {
  font-weight: 600;
}

.Motorcoach_motorcoachPage_airports_list__2ZF4u {
  margin-left: 2rem;
  line-height: 1.25;
}
.page-onboard {
  padding-bottom: 0.625rem;
}

.page-onboard-media-block {
  display: grid;
  grid-template-columns: 50% 1fr;
  margin-bottom: 0.625rem;
}
@media (max-width: 600px) {
  .page-onboard-media-block {
    grid-template-columns: 100%;
  }
}

.page-onboard-media-img {
  height: 100%;
  min-height: 250px;
  background-size: cover;
}

.page-onboard-media-text-content {
  padding: 1.875rem 1.25rem;
}
.page-onboard-media-text-content p {
  line-height: 1.5;
  margin: 0 0 1.125rem 0;
  font-size: 1.125rem;
}
.page-onboard-media-text-content ul {
  margin: 0 0 1.875rem 1rem;
}
@media (min-width: 600px) {
  .page-onboard-media-text-content ul {
    margin: 0 0 2.5rem 1rem;
  }
}
.page-onboard-media-text-content ul li {
  line-height: 1.7;
  margin: 0.313rem 0 0 1.25rem;
}
.page-onboard-media-text-content a {
  color: #fab500;
  text-decoration: underline;
}
@media (min-width: 700px) {
  .page-onboard-media-text-content {
    padding: 2.5rem;
  }
}

.page-onboard-landline-logo {
  width: 200px;
  height: 34px;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
}

.page-onboard-title {
  font-size: 2rem;
  margin: 2.188rem auto 1.25rem;
  text-align: left;
  line-height: 1;
}
@media (min-width: 600px) {
  .page-onboard-title {
    font-size: 3rem;
    margin: 3.75rem auto 1.875rem;
  }
}

.page-onboard-subtitle {
  font-size: 1.5rem;
  margin: 2.188rem 0 1.25rem;
}
@media (min-width: 600px) {
  .page-onboard-subtitle {
    font-size: 2rem;
    margin: 2.813rem auto 1.563rem;
  }
}

.page-onboard-empty-h4 {
  padding: 1.25rem;
  margin: 2.813rem auto 0rem;
  font-size: 1.5rem;
  line-height: 1.25;
  text-align: center;
  overflow-wrap: break-word;
}
@media (min-width: 600px) {
  .page-onboard-empty-h4 {
    font-size: 2rem;
  }
}

.page-onboard-social-media {
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;
  font-size: 1.5rem;
}
.page-onboard-social-media .social-container {
  width: auto;
  height: auto;
}
.page-onboard-social-media .social-container .social-icon a img {
  width: 36px !important;
  background-color: #1778f2;
  color: #fff;
  border-radius: 50%;
}

.page-onboard-social-media .social-container .social-icon:nth-child(2) a img {
  background-color: #1da1f2;
}

.page-onboard-social-media .social-container .social-icon:nth-child(3) a img {
  background-color: #f00075;
}

.page-onboard-bottom-p {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.5;
  margin: 0 0 1em 0;
}
.page-onboard-bottom-p a {
  color: #fab500;
  text-decoration: underline;
}
.full-width-heading {
  background: #1a1a1a;
  color: white;
  padding: 4rem 1rem;
  text-align: center;
  margin-bottom: 2rem;
}
.full-width-heading h1 {
  font-size: 2.5rem;
  max-width: 1200px;
  margin: 0 auto;
  line-height: 1.2;
}

.how-it-works {
  font-family: "Source Sans 3", sans-serif;
  line-height: 1.6;
}
.how-it-works .how-intro {
  text-align: center;
  padding: 4.5rem 1rem;
  max-width: 800px;
  margin: 0 auto;
}
.how-it-works .how-intro h1 {
  font-size: 2.25rem;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}
.how-it-works .how-intro p {
  font-size: 1.125rem;
  color: #676767;
}
.how-it-works .how-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 3rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}
.how-it-works .how-steps .step-card {
  padding: 1.5rem;
  text-align: center;
  background: #f8f9fa;
  border-radius: 8px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
.how-it-works .how-steps .step-card:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.how-it-works .how-steps .step-card img {
  height: 80px;
  margin-bottom: 1.5rem;
}
.how-it-works .how-steps .step-card h3 {
  font-size: 1.25rem;
  color: #1a1a1a;
  margin-bottom: 0.75rem;
}
.how-it-works .how-steps .step-card p {
  color: #676767;
  font-size: 0.95rem;
}
.how-it-works .how-routes {
  padding: 3rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}
.how-it-works .how-routes .tabs-wrapper {
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 20px;
}
.how-it-works .how-cta {
  background: #1a1a1a;
  color: white;
  padding: 6rem 1rem;
  margin: 3rem 0;
}
.how-it-works .how-cta .cta-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}
.how-it-works .how-cta .cta-content h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}
.how-it-works .how-cta .cta-content p {
  margin-bottom: 3rem;
  opacity: 0.9;
}
.how-it-works .how-cta .cta-content .cta-button {
  display: inline-block;
  padding: 1rem 2.5rem;
  background: #ffb300;
  color: white;
  text-decoration: none;
  border-radius: 50px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
.how-it-works .how-cta .cta-content .cta-button:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.how-it-works .how-faq {
  text-align: center;
  padding: 4.5rem 1rem;
  max-width: 600px;
  margin: 0 auto;
}
.how-it-works .how-faq h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
}
.how-it-works .how-faq a {
  color: #ffb300;
  font-weight: 600;
}
.how-it-works .how-faq a:hover {
  text-decoration: underline;
}

/* --- MAP CONTAINER FIXES --- */
/* Force the SVG inside to always be 1000x600 pixels */
.map-container svg {
  width: 1000px;
  height: 600px;
  display: block;
  margin: 0 auto; /* center the SVG horizontally within the container */
}

/* --- MAP CONTAINER --- */
.map-container {
  position: relative;
  width: 100%;
  overflow-x: auto; /* Allow horizontal scrolling if viewport is narrower than 1000px */
  margin: 2rem auto;
}

@media (max-width: 768px) {
  .map-container {
    width: 90vw;
    max-width: 90vw;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .fixed-map {
    width: 1000px;
    height: 600px;
    margin: 0;
    /* Shift map left by 100px to reveal right portion */
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
  }
}
/* Fixed-size map wrapper to ensure the SVG always stays 1000x600px */
.fixed-map {
  width: 1000px;
  height: 600px;
  margin: 0 auto;
}

/* Ensure the SVG inside .fixed-map retains its dimensions */
.fixed-map svg {
  width: 1000px;
  height: 600px;
  display: block;
}

/* Routes List Wrapper (overlay on desktop, below the map on mobile) */
.routes-list-wrapper {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 300px;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

/* On mobile, reposition the routes list below the map */
@media (max-width: 768px) {
  .routes-list-wrapper {
    position: relative;
    width: 100%;
    right: 0rem !important;
    margin-top: 1rem;
    box-shadow: none !important;
    padding: none !important;
  }
}
.routes-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
}

.route-item {
  display: block;
  background: #f8f9fa;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 8px;
  text-decoration: none;
  color: #1a1a1a;
  font-family: "Source Sans 3", sans-serif !important;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}
.route-item:hover {
  background: #ffb300;
  color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.tabs-wrapper {
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 20px;
}
.tabs-wrapper .tab-button {
  border: none;
  background-color: transparent;
  color: #000;
  text-decoration: none;
  border-bottom: 4px solid #ddd;
  padding: 10px 20px;
  font-family: "Source Sans 3", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 23px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.tabs-wrapper .tab-button:hover {
  background-color: #e0e0e0;
}
.tabs-wrapper .tab-button.active {
  border-bottom: 4px solid #FAB500;
  color: #FAB500;
  font-weight: 600;
}

.route-map-container {
  position: relative;
  max-width: 1200px;
  margin: 2rem auto;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* Remove extra overflow-x styling here since .map-container handles it */
}

@media (max-width: 768px) {
  .full-width-heading h1 {
    font-size: 2rem;
    padding: 0 1rem;
  }
  .how-it-works .how-intro {
    padding: 3rem 1rem;
  }
  .how-it-works .how-intro h1 {
    font-size: 1.75rem;
  }
  .how-it-works .how-intro p {
    font-size: 1rem;
  }
  .how-it-works .how-steps {
    grid-template-columns: 1fr;
    padding: 1.5rem 1rem;
  }
  .how-it-works .how-routes .tabs-wrapper {
    flex-direction: column;
  }
  .how-it-works .how-routes .tabs-wrapper .tab-button {
    width: 100%;
  }
  .how-it-works .how-cta {
    padding: 3rem 1rem;
  }
  .how-it-works .how-cta .cta-content h2 {
    font-size: 1.5rem;
  }
}
.amenities-section {
  text-align: center;
  max-width: 1100px;
  margin: 0 auto;
}

.intro-text {
  text-align: left;
  max-width: 1100px;
  margin: 0 auto 0 auto;
  font-size: 1.125rem;
  color: #333;
  line-height: 1.4;
  padding: 0 2rem;
}

.amenities-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  gap: 1.5rem;
  justify-content: center;
  padding: 1.5rem;
}

.amenity-card {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon1-wrapper {
  background: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.amenity-title {
  max-width: 180px;
  font-size: 1.125rem;
  line-height: 1.2;
  color: #1a1a1a;
}

@media (max-width: 768px) {
  .amenities-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .amenities-grid.two-columns {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 480px) {
  .amenities-grid {
    grid-template-columns: 1fr;
  }
  .amenities-grid.two-columns {
    grid-template-columns: 1fr;
  }
}
.bp p {
  text-align: left;
  max-width: 1100px;
  margin: 0 auto;
  font-size: 1.125rem;
  color: #333;
  line-height: 1.4;
  margin-bottom: 1rem;
  padding: 0 2rem;
}

.airline-logos {
  text-align: center;
  max-width: 1100px;
  margin: 0 auto;
}

.amenities-section {
  text-align: center;
  max-width: 1100px;
  margin: 0 auto;
}

.airline-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  gap: 1.5rem;
  justify-content: center;
  padding: 1.5rem;
}

@media (max-width: 768px) {
  .airline-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 480px) {
  .airline-grid {
    grid-template-columns: 1fr;
  }
}
.airline-card {
  background: #1a1a1a;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.airline-card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.airline-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 80px;
}

.airline-logo {
  max-width: 100%;
  max-height: 60px;
  object-fit: contain;
}

.external-link-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  color: white;
  font-size: 1rem;
  opacity: 0.8;
}

.amenities-grid.two-columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjust min size for better fit */
  grid-gap: 1.5rem;
  gap: 1.5rem;
  max-width: 1100px;
  margin: 0 auto;
  padding: 1.5rem;
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .amenities-grid.two-columns {
    grid-template-columns: 1fr; /* Stack items in a single column */
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1rem;
  }
}
.routes1-title {
  font-weight: 600;
  text-align: center;
  margin-bottom: 0rem !important;
}

/* Full-width Heading with Blurred Background */
.full-width-heading {
  position: relative;
  background: url(${PUBLIC_URL}/static/media/shuttleinterior.60bd7564.jpg) center/cover no-repeat; /* Use your imported image */
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  margin-bottom: 2rem;
  min-height: 200px; /* Ensure it has enough height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Darkened & Blurred Overlay */
.full-width-heading::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  -webkit-backdrop-filter: blur(0px);
          backdrop-filter: blur(0px); /* Blurred effect */
  z-index: 1;
}

/* Ensure content stays visible above the overlay */
.full-width-heading h1 {
  font-family: "Source Sans 3", sans-serif !important;
  font-size: 2.8rem;
  margin-bottom: 1rem;
  z-index: 2;
  position: relative;
}

.cta-button {
  font-family: "Source Sans 3", sans-serif !important;
  display: inline-block;
  background: #ffb300;
  color: black;
  padding: 0.75rem 2.5rem;
  border-radius: 100px;
  text-decoration: none;
  font-weight: 600;
  transition: background 0.2s ease, -webkit-transform 0.2s ease;
  transition: background 0.2s ease, transform 0.2s ease;
  transition: background 0.2s ease, transform 0.2s ease, -webkit-transform 0.2s ease;
  position: relative;
  z-index: 2;
}

.cta-button:hover {
  background: #e09c00;
  -webkit-transform: scale(1.05);
          transform: scale(1.05); /* Slight scale on hover for better UX */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .full-width-heading {
    padding: 3rem 1rem;
    min-height: 250px;
  }
  .full-width-heading h1 {
    font-size: 2rem;
  }
  .cta-button {
    padding: 0.6rem 2rem;
  }
}
/* Icon Section */
.icon1-section {
  text-align: center;
  padding: 1rem;
  background: white;
}

.icon1-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: center;
  padding: 0.5rem;
}

@media (max-width: 768px) {
  .icon1-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 480px) {
  .icon1-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
.icon1-card {
  background-color: white;
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  font-family: "Source Sans 3", sans-serif;
  height: 120px;
  text-decoration: none;
  color: #1a1a1a;
}

.icon1-card:hover {
  background-color: #e9ecef;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.icon1-wrapper {
  font-size: 2rem;
}

.icon1-title {
  font-size: 1rem;
  color: #1a1a1a;
  line-height: 1rem;
  font-weight: bold;
}

/* FAQ Section */
.faq1-section {
  background: #ffb300;
  color: black;
  padding: 2rem 1rem;
  text-align: center !important;
}

.faq1-content {
  max-width: 800px;
  margin: 0 auto;
}

.faq1-content h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

.faq1-content a {
  font-weight: bold;
  text-decoration: none;
  color: black;
}

.faq1-content p {
  font-size: 1rem;
  margin-top: 0.5rem;
  text-align: center !important;
}
.contact-page-container {
  font-family: "Source Sans 3";
}

.contact-page-card {
  display: flex;
  align-items: center;
  background: #f8f9fa;
  border-radius: 12px;
  padding: 1.2rem;
  flex-direction: column;
  text-align: center;
  grid-gap: 1rem;
  gap: 1rem;
  max-width: 15rem;
  min-width: 10rem;
}
@media screen and (max-width: 768px) {
  .contact-page-card svg {
    height: 2rem;
    width: 2rem;
  }
}

.contact-page-content {
  max-width: 1100px;
  margin: 0 auto;
}

.contact-page-cards-container {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  justify-content: center;
  padding: 1.5rem;
  padding-top: 0;
  margin-bottom: 2rem;
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (max-width: 768px) {
  .contact-page-cards-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

.contact-page-card-info {
  max-width: 180px;
  font-size: 0.8rem;
  line-height: 1.2;
  color: #1a1a1a;
}

.contact-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center !important;
  justify-content: center;
  background-color: white;
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  font-family: "Source Sans 3", sans-serif;
  height: 120px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease-in-out;
}
.contact-card:hover {
  background-color: #e9ecef;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.contact-card-icon {
  font-size: 0.8rem;
  color: #1a1a1a;
}

.contact-card-title {
  font-size: 1rem;
  text-align: center !important;
  color: #1a1a1a;
  line-height: 1rem;
  font-weight: bold;
  margin-top: 8px;
}
@charset "UTF-8";
.forms-page-bus-container {
  width: 100%;
  padding: 2.5rem 2.5rem 0;
}

.forms-page-centered-title {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.4;
  color: #3c3835;
}

.forms-page-image-block {
  text-align: center;
  position: relative;
}
.forms-page-image-block .centered-figure {
  margin: 0 auto;
  max-width: 760px;
  display: block;
}
.forms-page-image-block .centered-figure img {
  max-width: 100%;
  display: block;
  position: static;
}

.forms-page {
  background-color: #f5f7fa;
  max-width: 58.625rem;
  padding: 1rem 2.5rem 2.5rem;
  margin: 0 auto;
}

.forms-header {
  text-align: left;
  max-width: 1200px;
  margin: 0 auto 48px;
}

.forms-header h1 {
  font-size: 36px;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
}

.forms-header p {
  font-size: 18px;
  color: #666;
  margin-bottom: 8px;
}

.forms-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0;
}
.forms-section h3 {
  font-size: 27px;
  font-weight: 600;
  color: #3c3835;
  margin-bottom: 0.5rem;
}

.forms-section-subtitle {
  color: #000;
  font-weight: 400;
  line-height: 1.45;
  font-style: italic;
  margin: 1.5rem 0;
}

.section-divider {
  max-width: 1200px;
  margin: 0 auto;
  height: 1px;
  background-color: #e5e5e5;
}

.forms-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  gap: 24px;
  margin-bottom: 24px;
}

.group-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.3;
}

.group-item-header-title {
  margin-top: 1rem;
  color: #000;
}

.form-card-header-container {
  background: #fab500;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: box-shadow 0.2s ease, -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  transition: transform 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: 4.5rem;
  max-height: 4.5rem;
  width: 11.25rem;
  max-width: 11.25rem;
  justify-content: center;
  line-height: 1.25;
  color: #444;
}
.form-card-header-container .form-card-header-div {
  display: flex;
  flex-direction: column;
  grid-gap: 0.3rem;
  gap: 0.3rem;
}
.form-card-header-container:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.form-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 0.625rem 1.5rem 0;
}

.form-card-header {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0 1rem;
}

.form-card-title {
  font-size: 15px;
  font-weight: 700;
}

.form-card-subtitle {
  font-size: 11px;
  font-weight: 700;
  text-align: center;
}

.form-card-list {
  justify-items: flex-start;
  line-height: 1.25;
  width: 11.25rem;
  max-width: 11.25rem;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}

.form-card-list li {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.form-card-list li:before {
  content: "•";
  display: inline-block;
  margin-right: 8px;
  color: #333;
}
@media screen and (max-width: 768px) {
  .form-card-header-div {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .forms-page-centered-title {
    font-size: 30px;
  }
  .forms-page {
    padding: 24px 16px;
  }
  .forms-header h1 {
    font-size: 28px;
  }
  .forms-header p {
    font-size: 16px;
  }
  .forms-section {
    padding: 24px 0;
  }
  .forms-section h3 {
    font-size: 24px;
  }
  .forms-grid {
    grid-gap: 12px;
    gap: 12px;
    display: flex;
    flex-direction: column;
  }
  .group-item-header-title {
    font-size: 18px;
  }
  .form-card-title {
    font-size: 15px;
  }
  .form-card-subtitle {
    font-size: 12px;
  }
  .form-card-list li {
    font-size: 12px;
    line-height: 1.2; /* Tighter line spacing */
    margin-bottom: 4px; /* Smaller spacing between items */
  }
}
.corporate-travel-section {
  max-width: 1100px;
  margin: auto;
}

.corporate-travel-section-title {
  margin: 2rem 2rem 1.2rem;
  font-size: 28px;
  color: #111111;
  display: flex;
  justify-content: center;
}
@media (min-width: 600px) {
  .corporate-travel-section-title {
    font-size: 32px;
    margin: 4rem 2rem 1.2rem;
  }
}

.corporate-travel-description-with-cta {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 0rem 2rem;
}
.corporate-travel-description-with-cta p {
  color: #212121;
  line-height: 1.25;
  font-size: 16px;
}
@media (min-width: 600px) {
  .corporate-travel-description-with-cta p {
    font-size: 18px;
  }
}
.corporate-travel-description-with-cta a {
  background-color: #ffb404;
  color: #111111;
  font-weight: 600;
  padding: 0.3rem 2rem;
  border-radius: 1.1rem;
  margin: 1rem;
  font-size: 20px;
  align-self: center;
}
@media (min-width: 600px) {
  .corporate-travel-description-with-cta a {
    font-size: 24px;
  }
}

.corporate-travel-iconic-description-card {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}
@media (min-width: 600px) {
  .corporate-travel-iconic-description-card {
    flex-direction: row;
    margin: 2rem 5rem;
    grid-gap: 2rem;
    gap: 2rem;
  }
}

.corporate-travel-card-icon {
  background-color: #fab500;
  display: flex;
  align-items: center;
  min-width: 8rem;
  min-height: 7rem;
  justify-content: center;
  border-radius: 1rem;
  align-self: center;
}
.corporate-travel-card-icon svg {
  height: 5rem;
  width: 5rem;
  color: white;
}
@media (min-width: 600px) {
  .corporate-travel-card-icon svg {
    height: 7rem;
    width: 7rem;
  }
}
@media (min-width: 600px) {
  .corporate-travel-card-icon {
    min-width: 11rem;
    min-height: 9rem;
  }
}

.corporate-travel-card-info {
  display: flex;
  flex-direction: column;
  align-self: anchor-center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.corporate-travel-card-info h2 {
  font-size: 20px;
}
@media (min-width: 600px) {
  .corporate-travel-card-info h2 {
    font-size: 24px;
  }
}
.corporate-travel-card-info p {
  line-height: 1.25;
  font-size: 16px;
}
@media (min-width: 600px) {
  .corporate-travel-card-info p {
    font-size: 18px;
  }
}

.corporate-travel-service-benefits-container {
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  margin: 0 2rem 1rem;
}
.corporate-travel-service-benefits-container div {
  flex: 1 1;
}
.corporate-travel-service-benefits-container > div:first-child {
  flex: 1 1;
  display: flex;
  justify-content: center;
}
.corporate-travel-service-benefits-container img {
  width: 100%;
  max-height: 300px;
  border-radius: 1rem;
  height: auto;
  margin: 1rem 2rem;
}
@media (min-width: 768px) {
  .corporate-travel-service-benefits-container {
    flex-direction: row;
  }
}

.corporate-travel-service-benefits-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.corporate-travel-service-benefits-list li {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  line-height: 1.25;
}
.corporate-travel-service-benefits-list li p {
  font-size: 16px;
}
@media (min-width: 600px) {
  .corporate-travel-service-benefits-list li p {
    font-size: 18px;
  }
}
.corporate-travel-service-benefits-list li svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #13ab25;
}
@media (min-width: 600px) {
  .corporate-travel-service-benefits-list li svg {
    width: 2rem;
    height: 2rem;
  }
}
.corporate-travel-quote-container {
  margin-top: 2rem;
}
.referal-page-wrapper {
  padding: 10px 20px 0 20px;
  background-color: #fff;
  height: 100%;
}
.referal-page-wrapper .collapsiblebutton-toogle-wrapper {
  padding: 0;
  align-items: center;
  height: 50px;
  padding-bottom: 10px;
}
.referal-page-wrapper .referal-page-button {
  width: 50%;
  margin: 20px auto;
}
@media screen and (max-width: 750px) {
  .referal-page-wrapper .referal-page-button {
    width: 100%;
  }
}
.referal-page-wrapper .referal-page-succesfull-referals {
  margin-top: 20px;
}

@media (min-width: 1200px) {
  .show-desktop {
    display: block;
  }
  .show-mobile {
    display: none;
  }
  .referral-expl-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }
  .referral-expl-element h4 {
    padding-bottom: 20px;
    font-size: 22px;
    width: 65%;
    margin: auto;
    text-align: center;
  }
  .referral-expl-element ol {
    margin-left: 20px;
    width: 65%;
    margin: auto;
  }
  .referral-expl-element li {
    margin-top: 0px;
    line-height: 1.25rem;
  }
}
@media (max-width: 1200px) {
  .show-desktop {
    display: none;
  }
  .show-mobile {
    display: block;
  }
  .referral-expl-element {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .referral-expl-element h4 {
    padding-bottom: 20px;
    font-size: 22px;
    width: 65%;
    margin: auto;
    text-align: center;
  }
  .referral-expl-element ol {
    margin-left: 20px;
    width: 65%;
    margin: auto;
  }
  .referral-expl-element li {
    margin-top: 0px;
    line-height: 1.25rem;
  }
}
.referral-banner {
  width: 100%;
  cursor: pointer;
}
